<div class="authentication-bg">
  <div class="login-wrap">
    <div class="container">
      <div class="row justify-content-center ">
        <div class="col-xl-9">
          <div class="card overflow-hidden">
            <div class="login-box">
              <div class="row align-items-center">
                <div class="col-md-6 d-none d-md-block">
                  <div class="text-center">
                    <h2>Microsoft 365  <br /> Management Platform</h2>
                    <img src="assets/images/global/png/microsoft-365-login.png" class="img-fluid" alt="office-365">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-body bg-white py-5">
                    <div class=" text-center pt-5">
                      <img src="assets/images/global/svg/logo-light-login.svg" width="250" alt="officeboard logo">
                      <div class="mt-5 text-center">
                        <h4 >Verify Your Account</h4>
                        <p><i class="fa fa-info-circle text-primary"></i> {{messageConstantsService.InfoMsgConfirmUserPage}} <b>{{emailId}}</b></p>

                      </div>
                    </div>
                    <div class="p-2 pb-5">
                      <form [formGroup]="confirmUserForm" (ngSubmit)="confirmUser()" class="form-horizontal custom-validation" >
                        <div class="mb-3 position-relative">
                          <input type="text" class="form-control" id="username" formControlName="otp" minlength="1" placeholder="verification code here" required>
                        <span class="symbol-input"><i class="fa fa-key"></i></span>
                      </div>
                      <div class="mt-3 d-grid">
                        <button class="btn btn-primary waves-effect waves-light" type="submit" [disabled]="!confirmUserForm.valid">
                          <span *ngIf="showLoader" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                          Confirm Account</button>
                      </div>
                      <div class="mt-4 text-center">
                        <ul class="list-inline mb-0">
                          <li class="list-inline-item">
                            <a target="_blank" routerLink="/terms&conditions">Terms of use</a>
                          </li>
                          <li class="list-inline-item">|</li>
                          <li class="list-inline-item">
                            <a target="_blank" routerLink="/privacy-policy">
                              Privacy policy
                            </a>
                          </li>
                        </ul>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
