    <div class="vertical-menu sidebar sidebar-dark sidebar-main sidebar-expand-md" >

        <div data-simplebar class="h-100">

            <div id="sidebar-menu">
                <ul class="metismenu list-unstyled" id="side-menu">
                    <li routerLinkActive="mm-active">
                        <a routerLink="/dashboard" class="waves-effect">
                            <i class="bx bxs-dashboard"></i><span>Dashboard</span>
                        </a>
                    </li>
                    <li routerLinkActive="mm-active">
                        <a routerLink="/executiveSummary" class="waves-effect">
                            <i class="mdi mdi-chart-donut-variant"></i><span>Executive Summary</span>
                        </a>
                    </li>
                    <li routerLinkActive="mm-active">
                      <a routerLink="/licenses" class="waves-effect">
                          <i class="mdi mdi-license"></i><span>Licenses</span>
                          <span class="badge rounded-pill bg-danger float-end" key="t-new">New</span>
                      </a>
                  </li>
                    <li routerLinkActive="mm-active bg-transparent" (click)="security = !security" [ngClass]="security ? 'mm-active bg-transparent' : 'fff'">
                      <a href="javascript: void(0);" class="has-arrow waves-effect">
                        <i class="mdi mdi-shield-half-full"></i>
                          <span>Security</span>
                      </a>
                      <ul class="sub-menu mm-collapse" aria-expanded="false" routerLinkActive="mm-show" (click)="security = !security" [ngClass]="security ? 'mm-show' : 'ffff'">
                          <li routerLinkActive="mm-active">
                            <a routerLink="/security" class="waves-effect">
                              <i class="mdi mdi-shield-home-outline"></i><span>Security Dashboard</span>
                            </a>
                          </li>
                          <li routerLinkActive="mm-active">
                            <a routerLink="/security-alert-details" class="waves-effect">
                              <i class="mdi mdi-shield-alert-outline"></i><span>Security Alert Details</span>
                            </a>
                          </li>
                      </ul>
                    </li>
                    <li routerLinkActive="mm-active">
                        <a routerLink="/teams" class="waves-effect">
                            <i class="fas fa-users font-size-15"></i><span>Teams</span>
                        </a>
                    </li>
                    <li routerLinkActive="mm-active">
                        <a routerLink="/exchange"class="waves-effect">
                            <i class="bx bx-envelope"></i><span>Exchange</span>
                        </a>
                    </li>
                    <li routerLinkActive="mm-active bg-transparent" (click)="azuread = !azuread" [ngClass]="azuread ? 'mm-active bg-transparent' : 'fff'">
                        <a href="javascript: void(0);" class="has-arrow waves-effect">
                          <i class="bx bxs-cog"></i>
                            <span>Azure AD</span>
                        </a>
                        <ul class="sub-menu mm-collapse" aria-expanded="false" routerLinkActive="mm-show" (click)="azuread = !azuread" [ngClass]="azuread ? 'mm-show' : 'ffff'">
                            <li routerLinkActive="mm-active">
                              <a routerLink="/azureAD" class="waves-effect">
                                <i class="mdi mdi-account-tie font-size-16 mr-1"></i><span>Azure AD Users Details</span>
                              </a>
                            </li>
                            <li routerLinkActive="mm-active">
                              <a routerLink="/administrators" class="waves-effect">
                                <i class="mdi mdi mdi-account-cog font-size-16 mr-1"></i><span>Administrators</span>
                              </a>
                            </li>
                        </ul>
                      </li>
                    <li routerLinkActive="mm-active">
                        <a routerLink="/sharePoint" class="waves-effect">
                            <i class="bx bxs-share-alt"></i><span>SharePoint</span>
                        </a>
                    </li>
                    <li routerLinkActive="mm-active">
                        <a routerLink="/report" class="waves-effect">
                            <i class="bx bxs-report"></i>
                            <span key="t-blog">Reports</span>
                        </a>
                    </li>
                    <li routerLinkActive="mm-active">
                        <a href="#" class="waves-effect">
                            <span class="badge rounded-pill bg-success float-end" key="t-new">Coming Soon</span>
                            <i class="bx bxs-file-find"></i>
                            <span key="t-blog">Audit</span>
                        </a>
                    </li>
                    <li routerLinkActive="mm-active">
                        <a href="#" class="waves-effect">
                            <span class="badge rounded-pill bg-success float-end" key="t-new">Coming Soon</span>
                            <i class="bx bx-trending-up"></i>
                            <span key="t-blog">Trends</span>
                        </a>
                    </li>
                    <li routerLinkActive="mm-active">
                      <a routerLink="/subscription-list" class="waves-effect">
                          <i class="mdi mdi-microsoft-azure"></i><span>Tenants</span>
                      </a>
                  </li>
                </ul>
            </div>
        </div>
    </div>
