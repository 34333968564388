import { LicensesComponent } from './modules/features/components/licenses/licenses.component';
import { NgModule } from '@angular/core';
import {NgxPaginationModule} from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './modules/shared/components/layout/header/header.component';
import { FooterComponent } from './modules/shared/components/layout/footer/footer.component';
import { SideMenuComponent } from './modules/shared/components/layout/side-menu/side-menu.component';
import { LoginComponent } from './modules/core/components/login/login.component';
import { SignupComponent } from './modules/core/components/signup/signup.component';
import { SubscriptionListComponent } from './modules/core/components/subscription-list/subscription-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PrivacyPolicyComponent } from './modules/shared/components/privacy-policy/privacy-policy.component';
import { ErrorpageComponent } from './modules/shared/components/errorPage/errorPage.component';
import { OnboardNewSubscriptionComponent } from './modules/core/components/popups/onboard-new-subscription/onboard-new-subscription.component';
import { TermsConditionsComponent } from './modules/shared/components/terms&conditions/terms&conditions.component';
import { DashboardComponent } from './modules/features/components/dashboard/dashboard.component';
import { ExchangeComponent } from './modules/features/components/exchange/exchange.component';
import { TeamsComponent } from './modules/features/components/teams/teams.component';
import { SecurityComponent } from './modules/features/components/security/security.component';
import { ExecutiveSummaryComponent } from './modules/features/components/executive-summary/executive-summary.component';
import { AzureAdComponent } from './modules/features/components/azure-ad/azure-ad.component';
import { SharePointComponent } from './modules/features/components/sharepoint/sharepoint.component';
import { ProgressiveBarChartComponent } from './modules/shared/charts/progressive-bar-chart/progressive-bar-chart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { BrowserModule } from '@angular/platform-browser';
import { BarChartComponent } from './modules/shared/charts/bar-chart/bar-chart.component';
import { SearchPipe } from './modules/features/pipes/search.pipe';
import { RightNavBarComponent } from './modules/shared/components/layout/right-nav-bar/right-nav-bar.component';
import { PieChartComponent } from './modules/shared/charts/pie-chart/pie-chart.component';
import { CreateOrganizationComponent } from './modules/core/components/create-organization/create-organization.component';
import { ConfirmUserComponent } from './modules/core/components/confirm-user/confirm-user.component';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ToastrModule } from 'ngx-toastr';
import { SemiDonutChartComponent } from './modules/shared/charts/semi-donut-chart/semi-donut-chart.component';
import { ColumnChartComponent } from './modules/shared/charts/column-chart/column-chart.component';
import { AreaChartComponent } from './modules/shared/charts/area-chart/area-chart.component';
import { LoaderComponent } from './modules/shared/components/loader/loader.component';
import { ReportComponent } from './modules/features/components/report/report.component';
import { SecurityAlertDetailsComponent } from './modules/features/components/security-alert-details/security-alert-details.component';
import { SortableDirective } from './modules/shared/utilities/sortable.directive';
import { AdministratorsComponent } from './modules/features/components/administrators/administrators.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    HeaderComponent,
    FooterComponent,
    SideMenuComponent,
    SubscriptionListComponent,
    OnboardNewSubscriptionComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ErrorpageComponent,
    DashboardComponent,
    ExchangeComponent,
    SharePointComponent,
    TeamsComponent,
    SecurityComponent,
    ExecutiveSummaryComponent,
    AzureAdComponent,
    ProgressiveBarChartComponent,
    PieChartComponent,
    BarChartComponent,
    SemiDonutChartComponent,
    SearchPipe,
    RightNavBarComponent,
    ColumnChartComponent,
    AreaChartComponent,
    CreateOrganizationComponent,
    ConfirmUserComponent,
    LoaderComponent,
    ReportComponent ,
    SecurityAlertDetailsComponent,
    SortableDirective,
    LicensesComponent  ,
    AdministratorsComponent  ],
  imports: [
    BrowserModule,
    AppRoutingModule, ReactiveFormsModule, FormsModule, HttpClientModule, BrowserAnimationsModule,
    NgbModule,HighchartsChartModule, NgxPaginationModule,NgxIntlTelInputModule ,RecaptchaFormsModule,RecaptchaModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5000, // 15 seconds
      // progressBar: true,
      autoDismiss : true,
      preventDuplicates : true,
      disableTimeOut : false,
      maxOpened: 1
    }),
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: "6Lfe68IUAAAAACi3PhHvBxCifviHa8gnhzLyrGHF",
    } as RecaptchaSettings,
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }
