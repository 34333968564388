
    <footer class="footer organization">
      <div class="container-fluid">
          <div class="row">
              <div class="col-sm-6">
                OfficeBoard - ©{{currentYear}}. Design and Developed by <a href="https://loves.cloud/" target="_blank"> Loves Cloud. </a>
              </div>
              <div class="col-sm-6">
                  <div class="text-sm-end d-none d-sm-block">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item"><a target="_blank" routerLink="/terms&conditions" routerLinkActive="active">Terms & Conditions</a></li>
                      <li class="list-inline-item">|</li>
                      <li class="list-inline-item"><a target="_blank" routerLink="/privacy-policy" routerLinkActive="active">Privacy Policy</a></li>
                    </ul>
                  </div>
              </div>
          </div>
      </div>
  </footer>
