import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import { FunctionsService } from 'src/app/modules/shared/utilities/functions.service';
import { SharepointService } from '../../services/sharepoint.service';

@Component({
  selector: 'app-sharepoint',
  templateUrl: './sharepoint.component.html',
  styleUrls: ['./sharepoint.component.scss']
})
export class SharePointComponent {
  pagination1:number = 1;
  activeSharepoint:any;
  totalSharepoint:any;
  sharepointSitesinfo:any;
  eachSiteDetails:any;
  chartOptions: any;
  // Loader Variables starts
  showEachSiteApiLoader : boolean=true;
  showActiveSharePointApiLoader : boolean=true;
  showTotalSharePointApiLoader : boolean=true;
  showSharePointSitesInfoApiLoader : boolean=true;
  // Loader Variables Ends

  constructor(private sharepointService: SharepointService, private functionsService : FunctionsService) {
    this.sharepointService.getEachSiteDetails().subscribe((res: any) => {
      this.eachSiteDetails = JSON.parse(res?.message);
      if(this.eachSiteDetails){
        this.topFiveSitesChart();
      }
      this.showEachSiteApiLoader = false;
    },(err)=>{
      this.showEachSiteApiLoader = false;
    });
    this.sharepointService.getActiveSharepoint().subscribe((res: any) => {
      this.activeSharepoint = JSON.parse(res?.message)?.response;
      if(this.activeSharepoint){
        this.showActiveSharePointApiLoader = false;
      }
    },(err)=>{
      this.showActiveSharePointApiLoader = false;
    });
    this.sharepointService.getTotalSharepoint().subscribe((res: any) => {
      this.totalSharepoint = JSON.parse(res?.message);
      if(this.totalSharepoint){
        this.showTotalSharePointApiLoader = false;
      }
    },(err)=>{
      this.showTotalSharePointApiLoader = false;
    });
    this.sharepointService.getSharepointSitesinfo().subscribe((res: any) => {
      this.sharepointSitesinfo = JSON.parse(res?.message);
      if(this.sharepointSitesinfo){
        this.showSharePointSitesInfoApiLoader = false;
      }
    },(err)=>{
      this.showSharePointSitesInfoApiLoader = false;
    });
  }

  //Calling function to change decimal number to 2 decimal point
  callTwoDecimal(num: number) {
    return this.functionsService.setTwoDecimal(num);
  }

  //Calling function to change decimal number to int number
  CallDays(num: number) {
    if(num==null)
    return 0

    return FunctionsService.setDays(num);
  }

  topFiveSitesChart() {
    //need to alter the pie-chart component to accept any number of data(currently it accepts 2 data)
    this.chartOptions = {
      chart: {
        plotBorderWidth: null,
        plotShadow: false,
        style: {
          fontFamily: 'Roboto, sans-serif',
        },
        height: 220
      },
      title: {
        text: '',
        align: 'center',
        verticalAlign: 'middle',
        style: {
          fontSize: '16px',
          fontWeight: 'bold'
        },
        y: 20
      },
      credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '{point.y}'
      },
      plotOptions: {
        pie: {
          slicedOffset: 0,
          allowPointSelect: true,
          borderWidth: 5,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: false
        }
      },
      colors: ['#1F78B4', '#1FB1C8', '#78ACBF', '#42B18F', '#81898F',],
      series: [{
        type: 'pie',
        name: 'Total',
        innerSize: '60%',
        point: {
          events: {
            legendItemClick: function () {
              return false;
            }
          }
        },
        data: [
          [this.eachSiteDetails?.response?.top_five_storage_used[0]?.site_name, parseInt(this.eachSiteDetails?.response?.top_five_storage_used[0]?.Storage_Used_GB)],
          [this.eachSiteDetails?.response?.top_five_storage_used[1]?.site_name, parseInt(this.eachSiteDetails?.response?.top_five_storage_used[1]?.Storage_Used_GB)],
          [this.eachSiteDetails?.response?.top_five_storage_used[2]?.site_name, parseInt(this.eachSiteDetails?.response?.top_five_storage_used[2]?.Storage_Used_GB)],
          [this.eachSiteDetails?.response?.top_five_storage_used[3]?.site_name, parseInt(this.eachSiteDetails?.response?.top_five_storage_used[3]?.Storage_Used_GB)],
          [this.eachSiteDetails?.response?.top_five_storage_used[4]?.site_name, parseInt(this.eachSiteDetails?.response?.top_five_storage_used[4]?.Storage_Used_GB)]
        ]
      }]
    };
    var chart = Highcharts.chart('top_five_storage', this.chartOptions);
  }

  // pagination code
  onPageBoundsCorrection(pageNumber: number) {
    this.pagination1 = pageNumber;
  }
}
