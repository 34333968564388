import { MessageConstantsService } from './../../utilities/messageConstants.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() loadingMessage !:string;

  constructor(public messageConstantsService : MessageConstantsService) { }

  ngOnInit(): void {
  }

}
