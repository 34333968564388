<div id="layout-wrapper">
  <app-header pageName="Licenses"></app-header>
  <app-right-nav-bar></app-right-nav-bar>
  <app-side-menu></app-side-menu>
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-6 d-flex">
            <div class="card w-100">
              <app-loader *ngIf="showActiveInactiveUsersApiLoader" ></app-loader>
              <div class="card-body">
                <div class="d-sm-flex flex-wrap">
                  <h4 class="card-title officeboard_heading mb-3"><span>Monthly</span> Active Usage</h4>
                </div>
                <table class="table table-striped mb-0 table-md">
                  <thead class="table-light font-weight-black">
                    <tr>
                      <th class="w-40">Service Name</th>
                      <th>Active Users</th>
                      <th>Inactive Users</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Exchange</td>
                      <td class="text-primary fw-bold">{{servicesUserCountData?.Exchange_Active}}</td>
                      <td class="text-primary fw-bold">{{servicesUserCountData?.Exchange_Inactive}}</td>
                    </tr>
                    <tr>
                      <td>Office 365</td>
                      <td class="text-primary fw-bold">{{servicesUserCountData?.Office_365_Active}}</td>
                      <td class="text-primary fw-bold">{{servicesUserCountData?.Office_365_Inactive}}</td>
                    </tr>
                    <tr>
                      <td>OneDrive</td>
                      <td class="text-primary fw-bold">{{servicesUserCountData?.OneDrive_Active}}</td>
                      <td class="text-primary fw-bold">{{servicesUserCountData?.OneDrive_Inactive}}</td>
                    </tr>
                    <tr>
                      <td>SharePoint</td>
                      <td class="text-primary fw-bold">{{servicesUserCountData?.SharePoint_Active}}</td>
                      <td class="text-primary fw-bold">{{servicesUserCountData?.SharePoint_Inactive}}</td>
                    </tr>
                    <tr>
                      <td>Teams</td>
                      <td class="text-primary fw-bold">{{servicesUserCountData?.Teams_Active}}</td>
                      <td class="text-primary fw-bold">{{servicesUserCountData?.Teams_Inactive}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="col-xl-6">
            <div class="card">
              <app-loader *ngIf="showLicencesApiLoader" ></app-loader>
              <div class="card-body">
                <ng-container *ngIf="licencesData?.length>0 ; else noDataAvailable ">
                <h4 class="card-title mb-3 officeboard_heading"><span>Licenses</span></h4>
                <div class="table-responsive executive_licenses">
                  <table class="table table-striped mb-0 table-md truncate_table">
                    <thead class="table-light font-weight-black">
                      <tr>
                        <th class="w_9rem">License</th>
                        <th>Assigned</th>
                        <th>Activated</th>
                        <th>Shared</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let licences of licencesData | paginate: { id: 'licensesTable', itemsPerPage: 4, currentPage: pageNumber, totalItems : licencesData?.length }; let i = index">
                        <td class="long-td" placement="top" container="body" triggers="hover" [autoClose]="'outside'"  ngbTooltip="{{functionsService?.capitalizeEachWord(licences?.Product_Type ?? '')}}"><i class="mdi mdi-square font-size-18 me-1" [ngClass]="iconsColors[i]" ></i> {{functionsService?.capitalizeEachWord(licences?.Product_Type ?? '')}} </td>
                        <td class="text-primary fw-bold"><b>{{licences?.Assigned}}</b></td>
                        <td class="text-primary fw-bold"><b>{{licences?.Activated}}</b></td>
                        <td class="text-primary fw-bold"><b>{{licences?.Shared_Computer_Activation}}</b></td>
                      </tr></tbody>
                  </table>
                  <div class="d-flex">
                    <div class="pagination pagination-rounded mt-3 ms-auto">
                      <pagination-controls id="licensesTable" (pageChange)="pageNumber = $event"
                        (pageBoundsCorrection)="onPageBoundsCorrection($event)" [autoHide]="true">
                      </pagination-controls>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-template #noDataAvailable>
                <h4 class="card-title mb-3 fw-bold">Licenses</h4>
                <div class="h-100 d-flex">
                  <h5 class="top-heading-col align-self-center w-100 font-weight-semibold text-center">{{messageConstantsService?.noDataMessage}}</h5>
                </div>
            </ng-template>
              </div>

            </div>
          </div>

          <div class="col-12">
            <div class="card">
              <app-loader *ngIf="showMailBoxDetailsApiLoader" ></app-loader>
              <div class="card-body">
                <div class="d-sm-flex flex-wrap">
                  <h4 class="card-title officeboard_heading mb-3"><span>License</span> Assignment</h4>
                </div>
                <div class="row">
                  <div class="col-6">
                    <table class="table table-striped mb-0 table-md">
                      <thead class="table-light font-weight-black">
                        <tr>
                          <th width="25" class="text-center">#</th>
                          <th>Product Name</th>
                          <th>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let license of licensesAssignmentCountData | slice:0:(licensesAssignmentCountData.length%2==0?licensesAssignmentCountData.length/2:(licensesAssignmentCountData.length/2)+1) ; let i=index">
                          <td><span class="badge bg-secondary rounded-circle">{{i+1}}</span></td>
                          <td>{{functionsService?.capitalizeEachWord(license?.productName ?? '')}}</td>
                          <td class="text-primary fw-bold">{{license?.count}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-6">
                    <table class="table table-striped mb-0 table-md">
                      <thead class="table-light">
                        <tr>
                          <th width="15" class="text-center">#</th>
                          <th>Product Name</th>
                          <th>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let license of licensesAssignmentCountData | slice:(licensesAssignmentCountData.length/2)+1:licensesAssignmentCountData?.length ; let j=index">
                          <td><span class="badge bg-secondary rounded-circle">{{j+1+functionsService.getRoundOffData(licensesAssignmentCountData.length/2)}}</span></td>
                          <td>{{functionsService?.capitalizeEachWord(license?.productName ?? '')}}</td>
                          <td class="text-primary fw-bold">{{license?.count}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
