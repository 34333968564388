<div class="card-body form-group m-0">
    <div class="row"  *ngIf="showDiv.onBoardingForm">
      <div class="col-12">
        <h4 class="text-center font-weight-bold">Azure</h4>
      </div>
      <div class="col-12">
        <div class="alert alert-danger alert-dismissible fade show text-center" *ngIf="showDiv.warningDiv" role="alert">
          {{messageConstantsService.errorMessage}}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="col-sm-6">
        <figure class="py-5 px-3">
          <img src="../../../../../../assets/images/features/onboard-popup/azure-icon.png" class="img-fluid">
        </figure>
      </div>
      <div class="col-sm-6">
        <form class="px-4 py-4 azure_onboard_form " [formGroup]='formValue'>
          <div class="form-group position-relative mb-3">
            <label for="userpassword">Azure App <b>User Name</b></label>
            <div class="position-relative">
              <input type="text" class="form-control input_left_p" formControlName="userName" placeholder="Enter Username" required>
              <span class="symbol-input">
                <i class="fa fa-user" aria-hidden="true"></i>
              </span>
              <div class="text-danger" *ngIf="(formValue.controls['userName'].dirty ||  formValue.controls['userName'].touched )&& formValue.hasError('required','userName')" >
                Please enter a username.
              </div>
            </div>

          </div>
          <div class="form-group mb-3">
            <label for="userpassword">Azure App <b>Password</b></label>
            <div class="position-relative">
              <input [type]="password"  id="password" class="form-control input_left_p" formControlName="userPassword" placeholder="Enter Password" required>
              <span (click)="ShowAndHidePassword()" class=" input-right">
                <i class="fa fa-eye" aria-hidden="true" *ngIf="!showPassword"></i>
                <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="showPassword"></i>
              </span>

              <span class="symbol-input">
                <i class="fa fa-lock" aria-hidden="true"></i>
              </span>
              <div class="text-danger" *ngIf="(formValue.controls['userPassword'].dirty ||  formValue.controls['userPassword'].touched )&& formValue.hasError('required','userPassword')" >
                Please enter a valid Password.
              </div>

            </div>
          </div>

          <div class="form-group mb-3">
            <label for="cloudProviderlicensetype"><b>Cloud Provider License Type</b></label>
            <div class="position-relative">
              <select class="form-select input_left_p" formControlName='cloudProviderlicensetype' required>
                <option value="">--Select Cloud Provider License Type--</option>
                <option value="AZURE_EA">Enterprise Agreement (EA)</option>
                <option value="AZURE_CSP">Cloud Solution Provider (CSP)</option>
                <option value="AZURE_PAYG">Pay-As-You-Go (PAYG)</option>
                <option value="AZURE_SPONSOR">Sponsorship Account (SA)</option>
              </select>
              <div class="text-danger" *ngIf="(formValue.controls['cloudProviderlicensetype'].dirty ||  formValue.controls['cloudProviderlicensetype'].touched )&& formValue.hasError('required','cloudProviderlicensetype')" >
                Please choose a Cloud Provider License Type.
              </div>
              <span class="symbol-input">
                <i class="fa fa-cloud" aria-hidden="true"></i>
              </span>

            </div>

          </div>
          <div class="form-group mb-3">
            <label for="license"><b>Business Email ID</b></label>
            <div class="position-relative">
              <input type="email" class="form-control input_left_p" formControlName="businessEmail" placeholder="Enter your Business Email ID">
              <span class="focus-input100"></span>
              <span class="symbol-input">
                <i class="fas fa-at" aria-hidden="true"></i>
              </span>
              <div class="text-danger" *ngIf="(formValue.controls['businessEmail'].dirty ||  formValue.controls['businessEmail'].touched )&& formValue.hasError('pattern','businessEmail')">
                {{functionsService.errorMessages['email'][1].message}}
              </div>


            </div>
          </div>
          <button type="submit" class="btn btn-dark px-3" [disabled]="!formValue.valid" (click)="submitData()">
            <span *ngIf="showSubmitLoader" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
            Submit<i class="fa fa-paper-plane ps-2"></i></button>
        </form>
      </div>
    </div>
    <!-- thank you message  -->
    <div  *ngIf="showDiv.thankYouDiv" class="bg-white text-center py-3">
      <i class="fa fa-5x fa-check-circle text-success mb-2"></i>
      <h2>Thank You !</h2>
      <h6>{{messageConstantsService.thankYouDivMessage}}</h6>
    </div>
  </div>
