import { MessageConstantsService } from './../../../shared/utilities/messageConstants.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { userAuthService } from '../../../shared/services/user-auth.service';
import { FunctionsService } from '../../../shared/utilities/functions.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  showPassword: boolean = false;
  errorMessages = this.utilFuncService.errorMessages;
  showLoader:boolean=false;
  loginForm: any = new FormGroup({
    email_id: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(this.utilFuncService.emailValidator),
    ]),
    password: new FormControl('', [
      Validators.required
    ]),
  });

  constructor(
    private userAuthService: userAuthService,
    private router: Router,
    private utilFuncService: FunctionsService,
    private messageConstantsService: MessageConstantsService,
    private toastr: ToastrService  ) {
    this.userAuthService.logout();
    this.utilFuncService.setNewCookies([{name : '_env_ver',value: environment.environemntVersion}]);
    this.toastr.clear();
    // To check if the user is landing on this page after first time signup
    if (this.router.getCurrentNavigation()?.extras.state?.isVerified == true)
      this.toastr.success(this.messageConstantsService.userCompleteVerificationSuccessfulMessage);
  }

  onSubmit() {
    this.showLoader=true;
    const reqData =
      {
        'user_name':     this.loginForm.get('email_id').value,
        'user_password' : this.loginForm.get('password').value.trim()
      }
    this.userAuthService.login(reqData).subscribe(
      (res: any) => {
        const responseMsg = JSON.parse(res?.message)[0];
        if (responseMsg?.IsActive == this.messageConstantsService.true) {
          const cookiesData = [
            { name: 'full_name', value: responseMsg?.FullName },
            { name: '_u_name', value: responseMsg?.UserName },
            { name: 'email_id', value: responseMsg?.EmailId },
            { name: '_o_id', value: responseMsg?.OrganizationId },
            { name: '_o_name', value: responseMsg?.OrganizationName },
            { name: '_u_id', value: responseMsg?.UserId },
            {
              name: 'root_user',
              value: responseMsg?.IsRootUser == this.messageConstantsService.true ? true : false,
            },
            {
              name : 'otp_verified',
              value : responseMsg?.IsOTPVerified == this.messageConstantsService.true ?  true : false
            }
          ];
          this.utilFuncService.setNewCookies(cookiesData);
          if (responseMsg?.IsVerified == this.messageConstantsService.true) {
            this.router.navigate(['/subscription-list']);
          } else {
            if (responseMsg?.IsOTPVerified == this.messageConstantsService.true) {
              this.router.navigate(['/create-organization']);
            } else this.router.navigate(['/confirm-user']);
          }
        } else {
          this.showLoader=false;
          this.toastr.clear();
          this.toastr.error(this.messageConstantsService.errorMessage)
        }
      },
      (err) => {
        this.showLoader=false;
        this.toastr.clear();
        if(err.statusText == 'Unknown Error')
        this.toastr.error(this.messageConstantsService.errorMessage)
        else
        if (JSON.parse(err.error.response).error_message == 'Incorrect password')
        this.toastr.error(this.messageConstantsService.loginFailureMessage)
      }
    );
  }

  // click eye toggle function
  hideAndShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
