<div id="layout-wrapper">
  <app-header pageName="Report Dashboard"></app-header>
  <app-right-nav-bar></app-right-nav-bar>
  <app-side-menu></app-side-menu>
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-3 d-flex">
            <div class="card w-100">
              <div class="card-body">
                <h5 class="card-title2 officeboard_heading mb-4"><span>Security</span> Alerts</h5>
                <div class="display-6 mx-auto text-center mb-2">
                  <span class="align-self-center"><small><i class="bx bxs-bell-plus color-pink"></i></small></span>
                  <span class="ms-1"><small class="fw-medium">{{totalStatusCount || 0}}</small></span>
                </div>
                <div class="row">
                  <div class="col-lg-12 align-self-center">
                    <div class="text-lg-center my-3">
                      <div class="row">
                        <div class="col-6">
                          <div class="security-alert-box py-3 rounded_5">
                            <h3 class="mb-0 fw-bold">{{openStatusCount || 0}}</h3>
                            <h6 class="text-muted text-truncate mb-1">Open</h6>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="security-alert-box py-3 rounded_5">
                            <h3 class="mb-0 fw-bold">{{totalStatusCount-openStatusCount || 0}}</h3>
                            <h6 class="text-muted text-truncate mb-1">Closed</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="my-3">
                  <div class="progress security">
                    <div class="progress-bar" role="progressbar"  [ngStyle]="{'width': functionsService.setPercentage(openStatusCount,totalStatusCount) + '%'}" aria-valuenow="15" aria-valuemin="0"
                      aria-valuemax="100"></div>
                    <div class="progress-bar bg-success" role="progressbar" [ngStyle]="{'width': functionsService.setPercentage((totalStatusCount-openStatusCount),totalStatusCount) + '%'}" aria-valuenow="30"
                      aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
                <ul class="list-unstyled mx-auto w-75 mb-0 view_down_button text-center">
                  <li class="list-inline-item me-3"><a [routerLink]="'/security-alert-details'"><i class="fa fa-eye me-1"></i>View</a></li>
                  <li [ngStyle]="{'cursor' : 'pointer'}"  (click)="exportExcelSheet()" class="list-inline-item" ><a target="_blank" ><i class="fa fa-download me-1"></i>Download</a></li>
                </ul>
              </div>
              <app-loader *ngIf="showSecurityAlertsApiLoader || showSecurityReportLoader"></app-loader>
            </div>
          </div>

          <div class="col-sm-5 d-flex">
            <div class="card w-100">
              <app-loader *ngIf="showLicencesApiLoader"></app-loader>
              <div class="card-body">
                <div class="d-flex">
                  <h5 class="card-title2 officeboard_heading mb-4 d-flex"><span>Licenses</span></h5>
                  <ul class="list-unstyled ms-auto mb-0 view_down_button">
                    <li class="list-inline-item me-3"><a href="#"><i class="fa fa-eye me-1"></i>View</a></li>
                    <li class="list-inline-item"><a href="#"><i class="fa fa-download me-1"></i>Download</a></li>
                  </ul>
                </div>
                <div>
                  <div class="alert light-blue-bg border-0 p-2 px-3 mb-3" role="alert">
                    <div class="d-flex">
                      <div class="flex-shrink-0 me-3">
                        <i class="mdi mdi-cube font-size-20 text-blue"></i>
                      </div>
                      <div class="flex-grow-1 align-self-center">
                        <p class=" mb-0">Products <span
                            class="float-end fw-bold text-blue">{{licensesData?.licenses_assigned_data?.length ?? 'N/A'}}</span></p>
                      </div>
                    </div>
                  </div>

                  <div class="alert light-blue-bg border-0 p-2 px-3 mb-3" role="alert">
                    <div class="d-flex">
                      <div class="flex-shrink-0 me-3">
                        <i class="mdi mdi-clipboard-check font-size-20 text-blue"></i>
                      </div>
                      <div class="flex-grow-1 align-self-center">
                        <p class=" mb-0">Assigned Licenses <span
                            class="float-end fw-bold text-blue">{{licensesData?.total_assigned_activations ?? "N/A"}}</span></p>
                      </div>
                    </div>
                  </div>

                  <div class="alert light-blue-bg border-0 p-2 px-3 mb-3" role="alert">
                    <div class="d-flex">
                      <div class="flex-shrink-0 me-3">
                        <i class="mdi mdi-check-circle font-size-20 text-blue"></i>
                      </div>
                      <div class="flex-grow-1 align-self-center">
                        <p class=" mb-0">Activated Licenses <span
                            class="float-end fw-bold text-blue">{{licensesData?.total_activated_users ?? "N/A"}}</span></p>
                      </div>
                    </div>
                  </div>

                  <div class="alert light-blue-bg border-0 p-2 px-3 mb-0" role="alert">
                    <div class="d-flex">
                      <div class="flex-shrink-0 me-3 py-1">
                        <i class="fas fa-share-alt-square font-size-20 text-blue py-1"></i>
                      </div>
                      <div class="flex-grow-1 align-self-center">
                        <p class=" mb-0">Shared Licenses <span
                            class="float-end fw-bold text-blue">{{licensesData?.total_shared_computer_activation ?? "N/A"}}</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 d-flex">
            <div class="card w-100">
              <app-loader *ngIf="showMailBoxQuotaStatusApiLoader ||  showmailBoxDataApiLoader ||  showMailBoxUsageApiLoader"></app-loader>
              <div class="card-body">
                <div class="d-flex">
                  <h5 class="card-title2 officeboard_heading mb-4 d-flex"><span>Exchange</span></h5>
                  <ul class="list-unstyled ms-auto mb-0 view_down_button">
                    <li class="list-inline-item me-3"><a href="#"><i class="fa fa-eye me-1"></i>View</a></li>
                    <li class="list-inline-item"><a href="#"><i class="fa fa-download me-1"></i>Download</a></li>
                  </ul>
                </div>
                <div class="row">
                  <div class="col-lg-12 align-self-center">
                    <div class="mt-4 mt-lg-0">
                      <div class="row">
                        <div class="col-6">
                          <div class="security-alert-box py-2 border text-center rounded_5">
                            <h6 class="mb-0">Inactive Mailboxes</h6>
                            <h3 class="mb-0 text-center"><b>{{this.mailBoxData?.total_inactive_mailboxes_thirty_days ?? "N/A"}}</b>
                              <!-- <span
                                class="text-success font-size-13 fw-bold align-self-center"><i
                                  class="mdi mdi-arrow-top-right ms-1"></i> 0.2 % </span> -->
                                </h3>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="security-alert-box py-2 border text-center rounded_5">
                            <h6 class="mb-0">Never Logged In</h6>
                            <h3 class="mb-0 text-center"><b>{{mailBoxUsageDetails?.Never_login_user_count ?? "N/A"}}</b>
                              <!-- <span
                                class="text-danger font-size-13 fw-bold align-self-center"><i
                                  class="mdi mdi-arrow-bottom-right ms-1"></i> 0.2 % </span> -->
                                </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mailbox_quota mt-3">
                      <div class="col-12">
                        <div class="d-flex flex-wrap mb-2">
                          <div class="d-flex">
                            <div class="ms-1 me-2"><img width="20"
                                src="assets/images/features/report/limit.svg" alt=""></div>
                            <p class="mb-0 align-self-center">Under Limit</p>
                          </div>
                          <div class="ms-auto align-self-center">
                            <p class="mb-0 me-2 text-blue fw-bold">{{mailBoxUsageQuota?.Under_Limit_Count ?? "N/A"}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex flex-wrap my-2">
                          <div class="d-flex">
                            <div class="ms-1 me-2"><img width="20"
                                src="assets/images/features/report/warning.svg" alt=""></div>
                            <p class="mb-0 align-self-center">Warning Issued</p>
                          </div>
                          <div class="ms-auto align-self-center">
                            <p class="mb-0 me-2 text-blue fw-bold">{{mailBoxUsageQuota?.Warning_Issued_Count ?? "N/A"}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex flex-wrap my-2">
                          <div class="d-flex">
                            <div class="ms-1 me-2"><img width="20"
                                src="assets/images/features/report/not-sent.svg" alt=""></div>
                            <p class="mb-0 align-self-center">Send Prohibited</p>
                          </div>
                          <div class="ms-auto align-self-center">
                            <p class="mb-0 me-2 text-blue fw-bold">{{mailBoxUsageQuota?.Send_Prohibited ?? "N/A"}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex flex-wrap my-2">
                          <div class="d-flex">
                            <div class="ms-1 me-2"><img width="20"
                                src="assets/images/features/report/bx-notification-off.svg" alt=""></div>
                            <p class="mb-0 align-self-center">Send/Receive Prohibited</p>
                          </div>
                          <div class="ms-auto align-self-center">
                            <p class="mb-0 me-2 text-blue fw-bold">{{mailBoxUsageQuota?.Send_Receive_Prohibited_Count ?? "N/A"}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex flex-wrap mt-2">
                          <div class="d-flex">
                            <div class="ms-1 me-2"><img width="20"
                                src="assets/images/features/report/checkbox-indeterminate.svg" alt=""></div>
                            <p class="mb-0 align-self-center font">Indeterminate</p>
                          </div>
                          <div class="ms-auto align-self-center">
                            <p class="mb-0 me-2 text-blue fw-bold">{{mailBoxUsageQuota?.Indeterminate ?? "N/A"}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-8">
            <div class="card">
              <div class="card-body">
                <div class="d-flex">
                  <h5 class="card-title2 officeboard_heading mb-4"><span>Azure</span> Ad Users</h5>
                  <ul class="list-unstyled ms-auto mb-0 view_down_button">
                    <li class="list-inline-item me-3"><a href="#"><i class="fa fa-eye me-1"></i>View</a></li>
                    <li class="list-inline-item"><a href="#"><i class="fa fa-download me-1"></i>Download</a></li>
                  </ul>
                </div>
                <div class="row">
                  <div class="col-sm-6 vertical-line_2">
                    <app-loader *ngIf="showDeletedUsersApiLoader || showGlobalAdminsApiLoader ||  showGuestUsersApiLoader ||  showAdUsersApiLoader "></app-loader>
                    <div class="row mailbox_quota">
                      <div class="col-12">
                        <div class="d-flex flex-wrap pb-2">
                          <div class="d-flex">
                            <div class="ms-1 me-2"><img width="20"
                                src="assets/images/features/report/deleted-users.svg" alt=""></div>
                            <p class="mb-0 align-self-center">Deleted Users</p>
                          </div>
                          <div class="ms-auto align-self-center">
                            <p class="mb-0 me-2 text-blue fw-bold">{{deletedUsers ?? "N/A"}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex flex-wrap py-2">
                          <div class="d-flex">
                            <div class="ms-1 me-2"><img width="20"
                                src="assets/images/features/report/global-admins.svg" alt=""></div>
                            <p class="mb-0 align-self-center">Global Admins</p>
                          </div>
                          <div class="ms-auto align-self-center">
                            <p class="mb-0 me-2 text-blue fw-bold">{{globalAdminsData ?? "N/A"}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex flex-wrap py-2">
                          <div class="d-flex">
                            <div class="ms-1 me-2"><img width="20"
                                src="assets/images/features/report/guest-users.svg" alt=""></div>
                            <p class="mb-0 align-self-center">Guest Users</p>
                          </div>
                          <div class="ms-auto align-self-center">
                            <p class="mb-0 me-2 text-blue fw-bold">{{guestUsersData ?? "N/A"}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex flex-wrap pt-2">
                          <div class="d-flex">
                            <div class="ms-1 me-2"><img width="20"
                                src="assets/images/features/report/unlicensed-users.svg" alt=""></div>
                            <p class="mb-0 align-self-center">Unlicensed Users</p>
                          </div>
                          <div class="ms-auto align-self-center">
                            <p class="mb-0 me-2 text-blue fw-bold">{{adUsersDetails?.total_unlicensed_users ?? "N/A"}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <app-loader *ngIf="showPasswordExpiresApiLoader ||  showAdUsersApiLoader ||  showMfaApiLoader"></app-loader>
                    <div class="row mailbox_quota">
                      <div class="col-12">
                        <div class="d-flex flex-wrap pb-2">
                          <div class="d-flex">
                            <div class="ms-1 me-2"><img width="20"
                                src="assets/images/features/report/expired-passwords.svg" alt=""></div>
                            <p class="mb-0 align-self-center">Expired Passwords</p>
                          </div>
                          <div class="ms-auto align-self-center">
                            <p class="mb-0 me-2 text-blue fw-bold">{{expiredUsers ?? "N/A"}}</p>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="d-flex flex-wrap py-2">
                          <div class="d-flex">
                            <div class="ms-1 me-2"><img width="20"
                                src="assets/images/features/report/sign-in-denied.svg" alt=""></div>
                            <p class="mb-0 align-self-center">Sign-in Denied</p>
                          </div>
                          <div class="ms-auto align-self-center">
                            <p class="mb-0 me-2 text-blue fw-bold">{{adUsersDetails?.total_sign_in_denied_users   ?? "N/A"}}</p>
                          </div>
                        </div>
                      </div>  <div class="col-12">
                        <div class="d-flex flex-wrap py-2">
                          <div class="d-flex">
                            <div class="ms-1 me-2"><img width="20"
                                src="assets/images/features/report/mfa-registered.svg" alt=""></div>
                            <p class="mb-0 align-self-center">MFA Registered</p>
                          </div>
                          <div class="ms-auto align-self-center">
                            <p class="mb-0 me-2 text-blue fw-bold">{{MFAEnabledUsers?.total_mfa_registered_users ?? "N/A"}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex flex-wrap pt-2">
                          <div class="d-flex">
                            <div class="ms-1 me-2"><img width="20"
                                src="assets/images/features/report/mfa-not-configured.svg" alt=""></div>
                            <p class="mb-0 align-self-center">MFA Not Configured</p>
                          </div>
                          <div class="ms-auto align-self-center">
                            <p class="mb-0 me-2 text-blue fw-bold">{{MFAEnabledUsers?.total_not_mfa_registered_users ?? "N/A"}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-4 d-flex">
            <div class="card w-100">
              <app-loader *ngIf="showTeamsChannelApiLoader ||  showTeamsCallsApiLoader"></app-loader>
              <div class="card-body">
                <div class="d-flex">
                  <h5 class="card-title2 officeboard_heading mb-4 d-flex"><span>Teams</span></h5>
                  <ul class="list-unstyled ms-auto mb-0 view_down_button">
                    <li class="list-inline-item me-3"><a href="#"><i class="fa fa-eye me-1"></i>View</a></li>
                    <li class="list-inline-item"><a href="#"><i class="fa fa-download me-1"></i>Download</a></li>
                  </ul>
                </div>
                <div class="row">
                  <div class="col-sm-6 pe-0">
                    <div class="card info-teams-bg mb-0">
                      <div class="card-body p-2 text-black">
                          <p class="mt-2 mb-3"><img class="me-1" width="18" src="assets/images/features/report/private-channels.svg"> Private Channel</p>
                          <h3 class="my-2 text-center"><b>{{teamsChannelData?.length ?? "N/A"}}</b></h3>
                          <!-- <p class="text-success mb-0"><i class="fas fa-arrow-circle-up me-1"></i> <b>{{teamsChannelData?.length < lastMonthPrivateCallsData?.length ? "-" : "+"}}{{privateChannelPercentage}}%</b></p> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="card mb-0">
                      <div class="card-body p-2 text-black info-teams-bg h-100 d-inline-block">
                          <p class="mt-2 mb-3"><img class="me-1" width="18" src="assets/images/features/report/pstn-calls.svg"> PSTN Calls</p>
                          <h3 class="my-2 text-center"><b>{{teamsCallsData?.total_pstn_calls_thirty_days ?? "N/A"}} </b></h3>
                          <!-- <p class="text-danger mb-0"><i class="fas fa-arrow-circle-down me-1"></i> <b>-%</b></p> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</div>
