import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  constructor() { }
  @Input() alertsData: Array<any> = [];
  highcharts = Highcharts;
  chartOptions: any;
  ngOnInit() {
   this.chartOptions = {
      chart: {
         type: 'bar'
      },
      title: {
         text: ''
      },
      legend : {
         enabled: false,
         layout: 'vertical',
         align: 'left',
         verticalAlign: 'top',
         x: 250,
         y: 100,
         floating: true,
         borderWidth: 1
         },
         xAxis:{
            categories: [this.alertsData[0]?.name, this.alertsData[1]?.name, this.alertsData[2]?.name, this.alertsData[3]?.name, this.alertsData[4]?.name], title: {
            text: null
         }
      },
      yAxis : {
         min: 0, title: {
            text: '', align: 'high'
         },
         labels: {
            overflow: 'justify'
         }
      },
      tooltip : {
         valueSuffix: ''
      },
      plotOptions : {
         bar: {
            dataLabels: {
               enabled: true
            },
            enableMouseTracking : true
         }
      },
      credits:{
         enabled: false
      },
      series: [
         {
            data: [{y:parseInt(this.alertsData[0]?.value), color:'#55A8E9'}, {y:parseInt(this.alertsData[1]?.value), color:'#38A0BB'}, {y:parseInt(this.alertsData[2]?.value), color:'#42B190'},
            {y:parseInt(this.alertsData[3]?.value), color:'#11ACA6'}, {y: parseInt(this.alertsData[4]?.value),color:'#6C9BA5'}]
         }
      ]
   };
  }
}
