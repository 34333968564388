import { FunctionsService } from 'src/app/modules/shared/utilities/functions.service';
import { TeamsService } from './../../services/teams.service';
import { Component, OnInit } from '@angular/core';
import { AzureAdService } from '../../services/azureAd.service';
import { ExchangeService } from '../../services/exchange.service';
import { ExecutiveSummaryService } from '../../services/executive-summary.service';
import { SecurityService } from '../../services/security.service';
import { CookieService } from 'ngx-cookie-service';
import moment from 'moment';
import { SecurityAlertReportService } from '../../services/security-alert-report.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent {
  securityAlerts;
  mailBoxUsageQuota;
  mailBoxData;
  mailBoxUsageDetails;
  deletedUsers;
  MFAEnabledUsers;
  guestUsersData;
  expiredUsers;
  adUsersDetails;
  globalAdminsData;
  teamsChannelData;
  teamsCallsData;
  licensesData;
  securityAlertsReportData:any=[];
  // lastMonthPrivateCallsData:any = [];
  openStatusCount = 0;
  totalStatusCount = 0;
  privateChannelPercentage = 0;

  // Loader Variables starts
  showMfaApiLoader: boolean = true;
  showDeletedUsersApiLoader: boolean = true;
  showTeamsCallsApiLoader: boolean = true;
  showGlobalAdminsApiLoader: boolean = true;
  showAdUsersApiLoader: boolean = true;
  showPasswordExpiresApiLoader: boolean = true;
  showGuestUsersApiLoader: boolean = true;
  showMailBoxUsageApiLoader: boolean = true;
  showmailBoxDataApiLoader: boolean = true;
  showMailBoxQuotaStatusApiLoader: boolean = true;
  showSecurityAlertsApiLoader: boolean = true;
  showTeamsChannelApiLoader: boolean = true;
  showLicencesApiLoader : boolean = true;
  showSecurityReportLoader : boolean = true;
  // Loader Variables Ends

  constructor(private securityService: SecurityService, private exchangeService: ExchangeService, private azureAdService: AzureAdService, private executiveSummaryService: ExecutiveSummaryService, private teamsService: TeamsService, public functionsService: FunctionsService, private securityReportService : SecurityAlertReportService, private cookie : CookieService
  ) {
    this.securityService.getSecurityAlerts().subscribe((res: any) => {
      this.securityAlerts = JSON.parse(res?.message)?.response;
      if (this.securityAlerts) {
        for (let key in this?.securityAlerts?.status_count) {
          this.totalStatusCount += this?.securityAlerts?.status_count[key];
        }
        this.openStatusCount = this.securityAlerts?.status_count?.newAlert || 0 + this.securityAlerts?.status_count?.inProgress || 0 ; //Calculating Open Alerts
        this.showSecurityAlertsApiLoader = false;
        this.securityReportService.setSecurityAlertDetailsData(this.securityAlerts?.status_details);
        this.getSecurityAlertDetailsReportData();

      }
    }, (err) => {
      this.showSecurityAlertsApiLoader = false;
    });

    this.exchangeService.getMailBoxQuotaStatus().subscribe(
      (res: any) => {
        this.mailBoxUsageQuota = JSON.parse(res?.message)?.response;
        if (this.mailBoxUsageQuota) {
          this.showMailBoxQuotaStatusApiLoader = false;
        }
      },
      (err) => {
        this.showMailBoxQuotaStatusApiLoader = false;
      }
    );

    this.exchangeService.getMailBoxData().subscribe(
      (res: any) => {
        this.mailBoxData = JSON.parse(res?.message)?.response;
        if (this.mailBoxData) {
          this.showmailBoxDataApiLoader = false;
        }
      },
      (err) => {
        this.showmailBoxDataApiLoader = false;
      }
    );

    this.exchangeService.getMailBoxUsageDetails().subscribe(
      (res: any) => {
        //For Never Logged In User Data
        this.mailBoxUsageDetails = JSON.parse(res?.message)?.response;
        if (this.mailBoxUsageDetails) {
          this.showMailBoxUsageApiLoader = false;
        }
      },
      (err) => {
        this.showMailBoxUsageApiLoader = false;
      }
    );

    this.azureAdService.getDeletedUsers().subscribe((res: any) => {
      const response = JSON.parse(res?.message)?.response;
      if (response) {
        this.deletedUsers = response?.total_users_deleted;
        this.showDeletedUsersApiLoader = false;
      }
    }, (err) => {
      this.showDeletedUsersApiLoader = false;
    });

    this.azureAdService.getMFAEnabledUsers().subscribe((res: any) => {
      const response = JSON.parse(res?.message)?.response;
      if (response) {
        this.MFAEnabledUsers = response;
        this.showMfaApiLoader = false;
      }
    }, (err) => {
      this.showMfaApiLoader = false;
    });

    this.executiveSummaryService.getGuestUsersData().subscribe((res: any) => {
      const response = JSON.parse(res?.message)?.response;
      if (response) {
        this.guestUsersData = response?.total_users
        this.showGuestUsersApiLoader = false;
      }
    }, (err) => {
      this.showGuestUsersApiLoader = false;
    });

    this.executiveSummaryService.getProductsData().subscribe((res: any) => {
      this.licensesData = JSON.parse(res?.message)?.response;
      if (this.licensesData) {
        this.showLicencesApiLoader = false;
      }
    },(err) => {
      this.showLicencesApiLoader = false;
    });

    this.azureAdService.getPasswordExpires().subscribe((res: any) => {
      const response = JSON.parse(res?.message)?.response;
      if (response) {
        this.expiredUsers = response?.password_expired_users_count;
        this.showPasswordExpiresApiLoader = false;
      }
    }, (err) => {
      this.showPasswordExpiresApiLoader = false;
    });

    this.azureAdService.getTotalAdUsers().subscribe((res: any) => {
      const response = JSON.parse(res?.message)?.response;
      if (response) {
        this.adUsersDetails = response;
        this.showAdUsersApiLoader = false;
      }
    }, (err) => {
      this.showAdUsersApiLoader = false;
    });

    this.executiveSummaryService.getGlobalAdminsData().subscribe((res: any) => {
      const response = JSON.parse(res?.message)?.response;
      if (response) {
        this.globalAdminsData = response?.total_users;
        this.showGlobalAdminsApiLoader = false;
      }
    }, (err) => {
      this.showGlobalAdminsApiLoader = false;
    });

    this.teamsService.getTeamsChannel().subscribe(
      (res: any) => {
        const response = JSON.parse(res?.message)?.response;
        if (response) {
          this.teamsChannelData = response?.total_channels_in_last_thirty_days_data.filter((item) => item?.membershipType === "private"); //Storing all the private channel data in teamsChannelData variable.
          // this.getLastMonthPrivateChannelData(response?.private_channels_data);
          this.showTeamsChannelApiLoader = false;
        }
      },
      (err) => {
        this.showTeamsChannelApiLoader = false;
      }
    );

    this.teamsService.getTeamsCallsData().subscribe(
      (res: any) => {
        this.teamsCallsData = JSON.parse(res?.message)?.response?.calls_details;
        if (this.teamsCallsData) {
          this.showTeamsCallsApiLoader = false;
        }
      },
      (err) => {
        this.showTeamsCallsApiLoader = false;
      }
    );
  }

  getSecurityAlertDetailsReportData(){
    this.securityReportService.sharedData.subscribe((res:any)=>{
      this.securityAlertsReportData = res;
      this.showSecurityReportLoader = false;
    });
  }

  exportExcelSheet(){
    let tableData = JSON.parse(JSON.stringify(this.securityAlertsReportData));
    tableData.forEach(element => {
     delete(element.severityColor);
     delete(element.statusIcon);
     element.vendorInfo = JSON.stringify(element.vendorInfo);
     element.fileStatesInfo = JSON.stringify(element.fileStatesInfo);
     element.hostStatesInfo = JSON.stringify(element.hostStatesInfo);
     element.securityResources = JSON.stringify(element.securityResources);
    });
    const estimatedMonth = moment().format('DD-MMMM-YYYY'); //Getting current Date
    let pdfName = 'Security-Details-Report '+this.cookie.get('_tnt_name')+`(${estimatedMonth})`;
    this.functionsService.exportExcel(tableData, pdfName)
  }
  //Funtion to Calculate percentage change in private channel calls
  // getLastMonthPrivateChannelData(data: Array<any>) {
  //   const estimatedMonth = moment().format('YYYY-MM-DD');
  //   const dateBefore30Days = this.calculateMonth(estimatedMonth, 30);
  //   const dateBefore60Days = this.calculateMonth(estimatedMonth, 60);
  //   this.lastMonthPrivateCallsData = data.filter((item) => item?.createdDateTime > dateBefore60Days && item?.createdDateTime < dateBefore30Days);
  //   this.privateChannelPercentage = this.functionsService.setPercentage((this.teamsChannelData.length - this.lastMonthPrivateCallsData.length), this.lastMonthPrivateCallsData.length) || 0;
  // }

  // calculateMonth(month, diff) {
  //   return moment(month, 'YYYY-MM-DD').subtract(diff, 'days').format('YYYY MM DD');
  // }

}
