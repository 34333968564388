import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-semi-donut-chart',
  templateUrl: './semi-donut-chart.component.html',
  styleUrls: ['./semi-donut-chart.component.scss']
})
export class SemiDonutChartComponent implements OnInit {
  constructor() { }
  @Input() sixty_days_max_Score!: number;
  @Input() ninety_days_maximum_Score!: number;
  highcharts = Highcharts;
  chartOptions: any;
  ngOnInit() {
    this.chartOptions = {
       chart : {
          plotBorderWidth: null,
          plotShadow: false
       },
       title: {
        text: this.sixty_days_max_Score+ this.ninety_days_maximum_Score,
        align: 'center',
        verticalAlign: 'middle',
        y: 10,
        style: {
          fontSize: '18px'
        },
        },
        yAxis: {
          min: 0,
          max: 1000,
          lineWidth: 2,
          minorTicks: false,
          tickWidth: 2,
          tickAmount: 2,
          labels: {
              distance: '75%',
              align: 'auto',
              style: {
                  fontSize: "20px"
              }
          },
          plotLines: [{
              value: 35,
              zIndex: 5,
              width: 2,
              color: '#ff0000'
          }]
      },
       credits: {
        enabled: false
      },
       tooltip : {
        pointFormat: '{point.y}'
       },
       plotOptions : {
        pie: {
          slicedOffset: 0,
          enableMouseTracking : true,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,

          },
          showInLegend: false,
          startAngle: -90,
            endAngle: 90,
            center: ['50%', '50%'],
        },
       },
       series: [{
        type: 'pie',
        name: 'Total',
        innerSize: '70%',
        point: {
          events: {
            legendItemClick: function () {
              return false;
            }
          }
        },
        colors: ['#E5A53C', '#EEE'],
        data: [
          ['60 Days', this.sixty_days_max_Score],
          ['90 Days', this.ninety_days_maximum_Score]
        ],
        dataLabels: {
          format:
              '<div style="text-align:center">' +
              '<span style="font-size:25px">477</span><br/>' +
              '</div>'
      },
      }]
    };
 }
}
