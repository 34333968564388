import { Injectable } from "@angular/core";
@Injectable({
  providedIn: 'root'
})
export class MessageConstantsService{
  constructor(){}

  loginFailureMessage = "Either username or password is incorrect.";
  errorMessage = "Something went wrong!. Please try again.";
  otpFailureMessage = "Invalid OTP";
  otpSucessMessage = 'OTP is successfully Verified.'
  InfoMsgOtpVerificationPage = "You need to verify you account first";
  userCompleteVerificationSuccessfulMessage = 'You have successfully created your account. You can login now.';
  createOrganizationMessage = 'You need to create an organization first.'
  signUpSuccessMessage = 'You have successfully completed Step 1 of Signing Up';
  InfoMsgCreateOrgPage = 'Please provide your organization name below. The Organization name will act as a logical container, for all of your activities (for all of yours onboarded subscriptions too) inside OfficeBoard.';
  InfoMsgConfirmUserPage:string = 'Please enter your OTP below, sent to you on' ;
  thankYouDivMessage = 'Your submission has been received!';
  loadingMsgTenantListPage = 'Please wait while we fetch Tenants Details for you.';
  true:number = 1;
  false : number = 0;
  noDataMessage : string = 'No Data Available.';

}
