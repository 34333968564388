import { MessageConstantsService } from './../../../shared/utilities/messageConstants.service';
import { FunctionsService } from 'src/app/modules/shared/utilities/functions.service';
import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { userAuthService } from '../../../shared/services/user-auth.service';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent {
  showPassword!: boolean;
  SearchCountryField = SearchCountryField;
  countryCode = CountryISO;
  submitted:boolean = false;
  countryName: string = 'India';
  showLoader:boolean=false;
  errorMessages = this.functionService.errorMessages;

  userData!: {
      email_id: string;
      user_password: string;
      full_name: string;
      phone_number: string;
      country_code: string;
      country_name: string;
      root_user: boolean;
      user_name: string;
  };

  registerForm: any = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(this.functionService.emailValidator),
    ]),
    fullname: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
    password: new FormControl('', [
      Validators.required,
      this.functionService.patternValidator(/\d/, { hasNumber: true }),
      this.functionService.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
      this.functionService.patternValidator(
        /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
        {
          hasSpecialCharacters: true,
        }
      ),
      Validators.minLength(5),
      Validators.maxLength(100)
        ]),
    phonenumber: new FormControl('', [Validators.required]),
    recaptchaReactive: new FormControl(null, Validators.required),
  });

  constructor(
    private userAuthService: userAuthService,
    private router: Router,
    private functionService: FunctionsService ,
    private toastr: ToastrService ,
    private messageConstantsService : MessageConstantsService) {
      this.toastr.clear();
    }

  selectedCountry(event) {
    this.countryName = event.name.split(' ')[0];
  }

  onSignUp() {
    this.showLoader=true;
    this.submitted = true;
    if (this.registerForm.valid) {
      this.userData = {
          email_id: this.registerForm.controls.email.value,
          user_password: this.registerForm.controls.password.value.trim(),
          full_name : this.registerForm.controls.fullname.value.trim(),
          phone_number: this.registerForm.controls.phonenumber.value.number.replace(/\s/g, ""),
          country_code: this.registerForm.controls.phonenumber.value.dialCode,
          country_name: this.countryName,
          root_user: true,
          user_name: this.registerForm.controls.email.value,
      };
      this.userAuthService.signup(this.userData).subscribe(
        (res: any) => {
          var userId = JSON.parse(res?.response).user_id;
          this.functionService.setNewCookies([{ name: '_u_id', value : userId}]);
         this.router.navigate(['/confirm-user'], {
          state: {
            emailID: this.registerForm.controls.email.value,
          },
        });
        },
        (err) => {
          this.showLoader=false;
          this.toastr.clear();
          this.toastr.error(this.messageConstantsService.errorMessage);
        }
      );
    }
  }

  hideAndShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
