import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ConstantsService } from '../../shared/services/config/constants.service';
import { FunctionsService } from '../../shared/utilities/functions.service';
@Injectable({
  providedIn: 'root'
})

export class SecurityService {
  constructor(private http: HttpClient, private cookie: CookieService, private constantsService : ConstantsService, private functionsService : FunctionsService) { }

  private org_id = `${this.cookie.get('_tnt_org_id')}`;
  private tnt_id = `${this.cookie.get('_tnt_id')}`;

  private baseUrlSecurity = `${this.constantsService.securityPageApiUrl}`;

  private apiUrl_securityAlerts = this.baseUrlSecurity + "securityalerts?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_securityScores = this.baseUrlSecurity + "securescores?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_authMethods = this.baseUrlSecurity + "authmethods?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;

  getSecurityAlerts() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_securityAlerts,options);
  }

  getSecurityScores() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_securityScores,options)
  }

  getAuthMethods(){
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_authMethods,options)
  }
}
