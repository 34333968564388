<app-header pageName="Security Alert Details"></app-header>
<app-side-menu></app-side-menu>
<app-right-nav-bar></app-right-nav-bar>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-3 d-flex">
          <div class="card shadow-sm w-100">
            <app-loader *ngIf="showSecurityScoreApiLoader"></app-loader>
            <div class="card-body text_grey">
              <div class="d-flex align-items-center mb-2">
                <span class="me-2">
                  <i class="mdi mdi-shield-half-full font-size-20"></i>
                </span>
                <h5 class="mb-0 fw-semibold">Secure Score</h5>
              </div>
              <div class="">
                <h3 class="mb-3 fw-bold">{{secureScorePercentage??"N/A"}} <span class="font-size-13" *ngIf="secureScorePercentage">({{secureScores?.thirty_days_current_score}} out of {{secureScores?.thirty_days_max_Score}})</span>
                </h3>
                <div class="my-2">
                  <div class="custom-progess security position-relative">
                    <div class="progress">
                      <div class="progress-bar green_bg" role="progressbar" [ngStyle]="{'width':  functionsService.setPercentage(secureScores?.thirty_days_current_score,secureScores?.thirty_days_max_Score) + '%'}" aria-valuenow="75"
                        aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="avatar-xs progress-icon">
                      <span class="avatar-title rounded-circle">
                        <i class="fas fa-star text-white"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3 d-flex">
          <div class="card shadow-sm w-100">
            <app-loader *ngIf="showSecurityAlertsApiLoader"></app-loader>
            <div class="card-body text_grey">
              <div class="d-flex align-items-center mb-2">
                <span class="me-2">
                  <i class="mdi mdi-alert-circle font-size-20"></i>
                </span>
                <h5 class="mb-0 fw-semibold">Security Alerts</h5>
              </div>
              <div class="">
                <div class="text-lg-center">
                  <div class="row">
                    <div class="col-6">
                      <div class="text-start">
                        <h3 class="mb-0 fw-bold text-blue">{{securityAlerts?.thirty_days_alert_count ?? "N/A"}}</h3>
                        <h6 class="text-truncate mb-0 fw-normal">Alerts</h6>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="text-end">
                        <h3 class="mb-0 fw-bold">{{highSeverity ??"N/A"}} <span class="font-size-13" *ngIf="highSeverity">({{highSeverityPercentage}})</span></h3>
                        <h6 class="text-truncate mb-0 fw-normal">High Alerts</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <div class="custom-progess security row">
                    <div class="col-6">
                      <div class="progress">
                        <div class="progress-bar green_bg" role="progressbar" [ngStyle]="{'width':  functionsService.setPercentage(securityAlerts?.thirty_days_alert_count,securityAlerts?.total_alerts)+ '%'}" aria-valuenow="75"
                          aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="progress">
                        <div class="progress-bar high_bg" role="progressbar" [ngStyle]="{'width': functionsService.setPercentage(highSeverity,totalSeverity) + '%'}" aria-valuenow="50"
                         aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 d-flex">
          <div class="card shadow-sm w-100">
            <app-loader *ngIf="showSecurityAlertsApiLoader"></app-loader>
            <div class="card-body text_grey">
              <div class="d-flex align-items-center mb-2">
                <span class="me-2">
                  <i class="mdi mdi-security font-size-20"></i>
                </span>
                <h5 class="mb-0 fw-semibold">Security Alerts Status</h5>
              </div>

              <div class="text-lg-center mt-2">
                <div class="row">
                  <div *ngFor="let item of statusArray" ngClass="col-{{(12/statusArray?.length)}}">
                    <div class="text-start">
                      <h3 class="mb-0 fw-bold" [ngClass]="item?.textColor">{{item?.value}}</h3>
                      <h6 class="text-truncate mb-0 fw-normal">{{this.functionsService.makeFirstLetterCapital(item?.status)}}</h6>
                    </div>
                  </div>
              </div>
              </div>
              <div class="mt-2 security_alerts_status">
                <div class="progress">
                  <div *ngFor="let item of statusArray"  [ngClass]="item?.barColor" class="progress-bar" role="progressbar" [ngStyle]="{'width': functionsService.setPercentage(item?.value,totalStatusCount) + '%'}" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="card shadow-sm">
        <app-loader *ngIf="showSecurityScoreApiLoader || showSecurityAlertsDetailsTableLoader"></app-loader>
        <div class="card-body" >
          <ng-container *ngIf="securityAlertsDetailsTable.length>0 ; else noDataAvailable " >
            <div class="d-flex mb-2">
              <h5 class="card-title2 officeboard_heading font-weight-bold my-auto">
                <span>Security Alerts</span> Details</h5>
            <form class="app-search d-none d-lg-block ms-auto pt-0 pb-0">
              <div class="position-relative">
                <input type="text" class="form-control"  #filter placeholder="Search User ..." />
                <span class="fa fa-search"></span>
              </div>
            </form>
            <div class="my-auto ms-3">
              <a target="_blank" ><i class="fas fa-cloud-download-alt font-size-26 text_blue" [ngStyle]="{'cursor' : 'pointer'}"  (click)="exportExcelSheet()"></i></a>
            </div>
          </div>
          <div class="tab_w_auto">
            <table class="table table-striped table_even mb-0 table-borderless border table_collapsible">
              <thead class="table-light">
                <tr>
                  <th class="width-225 sort" sortable="category" (sort)="onSort($event, securityAlertsDetailsTable)">Category</th>
                  <th class="width-225 sort" sortable="severity" (sort)="onSort($event, securityAlertsDetailsTable)">Severity</th>
                  <th class="width-225 sort" sortable="status" (sort)="onSort($event, securityAlertsDetailsTable)">Status</th>
                  <th class="width-225 sort" sortable="userName" (sort)="onSort($event, securityAlertsDetailsTable)">User</th>
                  <th class="width-225 sort" sortable="domainName" (sort)="onSort($event, securityAlertsDetailsTable)">Domain Name</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of securityAlertsDetailsTable | search: 'userName': filter.value  | paginate: { id: 'securityDetailsTable', itemsPerPage: 10, currentPage: pageNumber }; let i=index">
                  <tr>
                  <td>{{functionsService.makeFirstLetterCapital(item?.category) ?? "N/A"}}</td>
                  <td><span class="badge" [ngClass]="item?.severityColor" >{{this.functionsService.makeFirstLetterCapital(item?.severity) ?? "N/A"}}</span></td>
                  <td>
                    <div class="d-flex"><i
                        class="mdi font-size-18 me-1" [ngClass]="item?.statusIcon"></i> <span
                        class=" align-self-center">{{functionsService.makeFirstLetterCapital(item?.status)?? "N/A"}}</span></div>
                  </td>
                  <td>{{item?.userName ?? "N/A"}}</td>
                  <td>{{item?.domainName ?? "N/A"}}</td>
                  <td>{{item?.date ?? "N/A"}}</td>
                  <td>{{item?.time ?? "N/A"}}</td>
                  <td>
                    <a href="javascript:void(0)" (click)="openCollapse(i)">
                      <span *ngIf="selectedTag !== i; else close"><i
                          class="fas fa-plus-circle font-size-18 text-green"></i></span>
                      <ng-template #close>
                        <span><i class="fas fa-minus-circle font-size-18 text-green"></i></span>
                      </ng-template>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="8" class="p-2" *ngIf="selectedTag === i">
                    <div class="custom_tabs">
                        <!-- Nav tabs -->
                      <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active" data-bs-toggle="tab" href="#details" role="tab">
                            <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                            <span class="d-none d-sm-block">Details</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" data-bs-toggle="tab" href="#description" role="tab">
                            <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                            <span class="d-none d-sm-block">Description</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" data-bs-toggle="tab" href="#vendor-information" role="tab">
                            <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                            <span class="d-none d-sm-block">Vendor Information</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" data-bs-toggle="tab" href="#file" role="tab">
                            <span class="d-block d-sm-none"><i class="fas fa-cog"></i></span>
                            <span class="d-none d-sm-block">File</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" data-bs-toggle="tab" href="#host" role="tab">
                            <span class="d-block d-sm-none"><i class="fas fa-cog"></i></span>
                            <span class="d-none d-sm-block">Host</span>
                          </a>
                        </li>
                        <li class="nav-item" >
                          <a class="nav-link" data-bs-toggle="tab" href="#security-resources" role="tab">
                            <span class="d-block d-sm-none"><i class="fas fa-cog"></i></span>
                            <span class="d-none d-sm-block">Security Resources</span>
                          </a>
                        </li>
                      </ul>
                      <!-- Tab panes -->
                      <div class="tab-content p-3 text-muted bg-white">
                        <div class="tab-pane active" id="details" role="tabpanel">
                          <table class="table mb-0 bg-transparent">
                            <thead class="table-light">
                              <tr>
                                <th width="500">Title</th>
                                <th width="500">Recommended Actions</th>
                                <th width="500">Source Materials</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{{item?.detailsTitle ?? "No Title Found"}}</td>
                                <td>
                                  <p class="mb-0" *ngFor="let actions of item?.recommendendActions">{{actions}}</p>
                                  <p *ngIf="item?.recommendendActions.length<1" class="mb-0" > No Recommended Actions Found</p></td>
                                <td>
                                 <a target="_blank" [href]="i??'#'" *ngFor="let i of item?.sourceMaterials"><p style="width: 500px;" class="mb-0 d-flex source_material_url"  placement="top" container="body" triggers="hover" [autoClose]="'outside'"  ngbTooltip='{{i ?? "N/A"}}'><span class="text-truncate w-100">{{i ?? "N/A"}}</span></p></a>
                                 <p *ngIf="item?.sourceMaterials.length<1" class="mb-0" > No Source Materials Found</p>
                                 </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="tab-pane" id="description" role="tabpanel">
                          <p class="mb-0">{{item?.description ?? "No Description Found"}}</p>
                        </div>
                        <div class="tab-pane" id="vendor-information" role="tabpanel">
                          <table class="table mb-0 bg-transparent">
                            <thead class="table-light">
                              <tr>
                                <th>Provider</th>
                                <th>Sub Provider</th>
                                <th>Vendor</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{{item?.vendorInfo?.provider ?? "No Provider Found"}}</td>
                                <td>{{item?.vendorInfo?.subProvider ?? "No Sub Provider Found"}}</td>
                                <td>{{item?.vendorInfo?.vendor ?? "No Vendor Found"}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="tab-pane" id="file" role="tabpanel">
                          <table class="table mb-0 bg-transparent">
                            <thead class="table-light">
                              <tr>
                                <th>Name</th>
                                <th>Path</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let fileItem of item?.fileStatesInfo">
                                <td>{{fileItem?.name ?? "No Name Found"}}</td>
                                <td>{{fileItem?.path ?? "No Path Found"}}</td>
                              </tr>
                              <tr *ngIf="item?.fileStatesInfo?.length==0">
                                <td>No Name Found</td>
                                <td>No Path Found</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="tab-pane" id="host" role="tabpanel">
                          <table class="table mb-0 bg-transparent">
                            <thead class="table-light">
                              <tr>
                                <th>FQDN</th>
                                <th>isAzureADJoined</th>
                                <th>isAzureADRegistered</th>
                                <th>PrivateIPAddress</th>
                                <th>PublicIPAddress</th>
                                <th>Host</th>
                                <th>NetBiosName</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let hostStateItem of item?.hostStatesInfo">
                                <td>{{hostStateItem?.fqdn ?? "No FQDN Found"}}</td>
                                <td>{{hostStateItem?.isAzureAdJoined ?? "No Data Found"}}</td>
                                <td>{{hostStateItem?.isAzureAdRegistered ?? "No Data Found"}}</td>
                                <td>{{hostStateItem?.privateIpAddress ?? "No PrivateIPAddress Found"}}</td>
                                <td>{{hostStateItem?.publicIpAddress ?? "No PublicIPAddress Found"}}</td>
                                <td>{{hostStateItem?.os ?? "No Host Found"}}</td>
                                <td>{{hostStateItem?.netBiosName ?? "No NetBiosName Found"}}</td>
                              </tr>
                              <tr *ngIf="item?.hostStatesInfo?.length==0">
                                <td>{{"No FQDN Found"}}</td>
                                <td>{{"No Data Found"}}</td>
                                <td>{{ "No Data Found"}}</td>
                                <td>{{"No PrivateIPAddress Found"}}</td>
                                <td>{{"No PublicIPAddress Found"}}</td>
                                <td>{{"No Host Found"}}</td>
                                <td>{{"No Net Bios Name Found"}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="tab-pane" id="security-resources" role="tabpanel" >
                          <table class="table mb-0 bg-transparent">
                            <thead class="table-light">
                              <tr>
                                <th>Resource</th>
                                <th>Resource Type</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let resourceItem of item?.securityResources">
                                <td class="w-75">{{resourceItem?.resource ?? "No Resource Found"}}</td>
                                <td>{{resourceItem?.resourceType ?? "No Resource Type Found"}}</td>
                              </tr>
                              <tr *ngIf="item?.securityResources?.length==0">
                                <td>No Resource Found</td>
                                <td>No Resource Type Found</td></tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr></ng-container>
              </tbody>
            </table>

          </div>
          <div class="d-flex">
            <div class="pagination pagination-rounded mt-3 ms-auto">
              <pagination-controls id="securityDetailsTable" (pageChange)='onPageChange($event)' (pageBoundsCorrection)="onPageBoundsCorrection($event)"
                class="">
              </pagination-controls>
            </div>
          </div>
        </ng-container>

        </div>
      <ng-template #noDataAvailable>
        <div class="card-body">
          <h5 class="mt-2 top-heading-col font-weight-semibold text-center">No Data Available</h5>
        </div>
      </ng-template>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
