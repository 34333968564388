import { FunctionsService } from './../../../shared/utilities/functions.service';
import { ExchangeService } from './../../services/exchange.service';
import { ExecutiveSummaryService } from './../../services/executive-summary.service';
import { Component } from '@angular/core';
import { MessageConstantsService } from 'src/app/modules/shared/utilities/messageConstants.service';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss']
})
export class LicensesComponent{

  iconsColors = ['dark_blue', 'light_green', 'light_grey', 'green', 'light_blue']

  servicesUserCountData:any = {};
  licencesData;
  licensesAssignmentCountData !: Array<{ productName:string, count:number }>;
  pageNumber: number = 1;

  // Loader Variables
  showLicencesApiLoader : boolean = true;
  showMailBoxDetailsApiLoader : boolean = true;
  showActiveInactiveUsersApiLoader : boolean = true;
  constructor(private executiveSummaryService : ExecutiveSummaryService, private exchangeService : ExchangeService, public functionsService : FunctionsService, public messageConstantsService : MessageConstantsService) {

    //For Licenses Table
    this.executiveSummaryService.getProductsData().subscribe((res: any) => {
      this.licencesData = JSON.parse(res?.message)?.response?.licenses_assigned_data;
        this.showLicencesApiLoader = false;
    }, (err) => {
      this.showLicencesApiLoader = false;
    });

    //For MAU Section
    this.executiveSummaryService.getActiveInactiveUsersData().subscribe((res:any)=>{
    this.servicesUserCountData = JSON.parse(res?.message)?.response?.service_user_count_data[0];
    this.showActiveInactiveUsersApiLoader = false;
    }), (err)=>{
      this.showActiveInactiveUsersApiLoader = false;
    };

    //For License Assignment Section
    this.exchangeService.getMailBoxDetails().subscribe(
      (res: any) => {
        //For License count
      const response = JSON.parse(res?.message)?.response?.license_count;
      if(response){
        let tempArray:any = [];
       Object.entries(response).forEach((item)=>{ //Converting Object into array of key-value pairs in the form of small arrays. i.e, arr = [['abc','20'],['def','10']]. And then iterating over it to create a custom array of objects
        const temp = {
          productName : item[0],   //Product Name
          count : item[1]   //Count
        }
        tempArray.push(temp);
       });
       this.licensesAssignmentCountData = tempArray.sort((a,b)=>b.count-a.count); //Sorting the array in descending order by count.
       console.log(this.licensesAssignmentCountData)
      }
        this.showMailBoxDetailsApiLoader = false;
      },
      (err) => {
        this.showMailBoxDetailsApiLoader = false;
      }
    );
  }

  onPageBoundsCorrection(newPageNumber: number) {
    this.pageNumber = newPageNumber;
  }

}
