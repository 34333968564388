import { Component } from '@angular/core';
import { AzureAdService } from '../../services/azureAd.service';
@Component({
  selector: 'app-azure-ad',
  templateUrl: './azure-ad.component.html',
  styleUrls: ['./azure-ad.component.scss']
})
export class AzureAdComponent {
  totalAdUser;
  totalGroups;
  passwordExpires;
  totalMembers;
  MFAEnabledUsers;
  deletedUsers;
  adminUsers;
  members!:number;
  pageNumber : number= 1;

  // Loader Variables starts
  showTotalMembersApiLoader : boolean = true;
  showTotalGroupsApiLoader : boolean = true;
  showTotalAdUsersApiLoader : boolean = true;
  showPasswordExpiresApiLoader : boolean = true;
  showMfaEnabledUsersApiLoader : boolean = true;
  showAdminUsersApiLoader : boolean = true;
  showDeletedUsersApiLoader : boolean = true;
  // Loader Variables Ends

  constructor(private azureAdService: AzureAdService) {
    this.azureAdService.getTotalMembers().subscribe((res:any) => {
      this.totalMembers = JSON.parse(res?.message).response;
      if(this.totalMembers){
        this.members = this.totalMembers?.total_members_count -  this.totalMembers?.total_guests;
        this.showTotalMembersApiLoader = false;
      }
    },(err)=>{
      this.showTotalMembersApiLoader = false;
    });
    this.azureAdService.getTotalAdUsers().subscribe((res:any) => {
      this.totalAdUser = JSON.parse(res?.message);
      if(this.totalAdUser){
        this.showTotalAdUsersApiLoader = false;
      }
    },(err)=>{
      this.showTotalAdUsersApiLoader = false;
    });
    this.azureAdService.getTotalGroups().subscribe((res:any) => {
      this.totalGroups = JSON.parse(res?.message);
      if(this.totalGroups){
        this.showTotalGroupsApiLoader = false;
      }
    },(err)=>{
      this.showTotalGroupsApiLoader = false;
    });
    this.azureAdService.getPasswordExpires().subscribe((res:any) => {
      this.passwordExpires = JSON.parse(res?.message);
      if(this.passwordExpires){
        this.showPasswordExpiresApiLoader = false;
      }
    },(err)=>{
      this.showPasswordExpiresApiLoader = false;
    });
    this.azureAdService.getMFAEnabledUsers().subscribe((res:any) => {
      this.MFAEnabledUsers = JSON.parse(res?.message);
      if(this.MFAEnabledUsers){
        this.showMfaEnabledUsersApiLoader = false;
      }
    },(err)=>{
      this.showMfaEnabledUsersApiLoader = false;
    });
    this.azureAdService.getDeletedUsers().subscribe((res:any) => {
      this.deletedUsers = JSON.parse(res?.message);
      if(this.deletedUsers){
        this.showDeletedUsersApiLoader = false;
      }
    },(err)=>{
      this.showDeletedUsersApiLoader = false;
    });
    this.azureAdService.getAdminUsers().subscribe((res:any) => {
      this.adminUsers = JSON.parse(res?.message);
      if(this.adminUsers){
        this.showAdminUsersApiLoader = false;
      }
    },(err)=>{
      this.showAdminUsersApiLoader = false;
    });
  }

  onPageBoundsCorrection(newPageNumber: number) {
    this.pageNumber = newPageNumber;
  }
}
