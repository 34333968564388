import { FunctionsService } from 'src/app/modules/shared/utilities/functions.service';
import { CookieService } from 'ngx-cookie-service';
import { userAuthService } from 'src/app/modules/shared/services/user-auth.service';
import { Component } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-right-nav-bar',
  templateUrl: './right-nav-bar.component.html',
  styleUrls: ['./right-nav-bar.component.scss'],
})

export class RightNavBarComponent {
  li_user_name : string = this.cookie.get('full_name')?this.cookie.get('full_name'):this.cookie.get('_u_name');
  org_name : string = this.cookie.get('_o_name');

  constructor(private userAuthService : userAuthService, private cookie : CookieService, public commonFunctionService : FunctionsService) {
   }

  toggleRightNavBar(event) {
    $('body').toggleClass('right-bar-enabled');
    $('.right_icon_toggle_btn').toggleClass('radius');
  }

  logout(){
    this.userAuthService.logout();
    window.location.replace("/");
  }
}
