<app-header pageName="Security"></app-header>
<app-side-menu></app-side-menu>
<app-right-nav-bar></app-right-nav-bar>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <div class="row ">
        <div class="col-md-12 col-xl-3 d-flex">
          <div class="card border-0 w-100">
            <div class="card-body">
              <h5 class="card-title2 officeboard_heading mb-3"><span>Secure</span> Score</h5>
                <div id="secure_score_chart"></div>
            </div>
            <app-loader *ngIf="showSecurityScoreApiLoader"></app-loader>
          </div>
        </div>
        <div class="col-md-12 col-xl-6">
          <div class="row">
            <div class="col-md-6 col-xl-4 d-flex">
              <div class="card border-0 w-100">
                <div class="card-body security_alerts">
                  <div class="text-truncate"><h5 class="card-title2 officeboard_heading mb-4"><span>Security</span>
                    Alerts</h5></div>
                  <div class="flex-wrap">
                    <div class=" d-flex pb-2 mb-0">
                      <div class="avatar-xs me-3">
                        <span class="avatar-title rounded-circle bg-soft text-primary font-size-18">
                          <i class="mdi mdi-bell text-white"></i>
                        </span>
                      </div>
                      <h3 class="mb-0 align-self-center fw-semibold">
                        {{securityAlerts?.total_alerts ?? 'N/A'}}</h3>
                    </div>

                    <div class="row border-top">
                      <div class="col-6 mt-2 text-center p-0">
                        <h4 class="mb-0 fw-semibold">
                          {{securityAlerts?.sixty_days_alert_count ?? 'N/A'}}</h4>
                        <p class="text-muted mb-0">60 Days</p>
                      </div>
                      <div class="col-6 mt-2 text-center p-0">
                        <h4 class="mb-0 fw-semibold">
                          {{securityAlerts?.ninety_days_alert_count ?? 'N/A'}}</h4>
                        <p class="text-muted mb-0">90 Days</p>
                      </div>
                    </div>

                    <div class="border-top mt-2 pt-2">
                      <div class="d-flex flex-wrap">
                        <div class="align-self-center d-flex">
                          <p class=" mb-0">Open</p>
                        </div>
                        <div class="ms-auto">
                          <p class="mb-0 fw-bold text-primary">
                            {{(securityAlerts?.status_count?.newAlert ?? 0) +
                            ( securityAlerts?.status_count?.inProgress ?? 0)}}</p>
                        </div>
                      </div>
                      <div class="d-flex flex-wrap pt-1 pb-0">
                        <div class="align-self-center d-flex">
                          <p class="mb-0">Closed</p>
                        </div>
                        <div class="ms-auto">
                          <p class="mb-0 fw-bold text-primary">
                            {{securityAlerts?.status_count?.resolved ?? 'N/A'}}</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <app-loader *ngIf="showSecurityAlertsApiLoader"></app-loader>
              </div>
            </div>
            <div class="col-md-6 col-xl-8 d-flex">
              <div class="card border-0 w-100">
                <app-loader *ngIf="showSecurityAlertsApiLoader"></app-loader>
                <div class="card-body security_alerts alerts_severity">
                  <h5 class="card-title2 officeboard_heading mb-4"><span>Alerts</span>
                    Severity</h5>
                  <div class="row pb-2">
                    <div class="col-4">High</div>
                    <div class="col-6 align-self-center">
                      <div class="progress">
                        <div class="progress-bar bg-high" role="progressbar"
                          [ngStyle]="{'width': highSecurityPercentage + '%'}" aria-valuenow="25" aria-valuemin="0"
                          aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="col-2">
                      <p class="text-primary mb-0 fw-bold">{{securityAlerts?.severity_count?.high ?? 'N/A'}}</p>
                    </div>
                  </div>
                  <div class="row my-2 py-2">
                    <div class="col-4">Medium</div>
                    <div class="col-6 align-self-center">
                      <div class="progress">
                        <div class="progress-bar bg-medium" role="progressbar"
                          [ngStyle]="{'width': mediumSecurityPercentage + '%'}" aria-valuenow="25" aria-valuemin="0"
                          aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="col-2">
                      <p class="text-primary mb-0 fw-bold">{{securityAlerts?.severity_count?.medium ?? 'N/A'}}</p>
                    </div>
                  </div>
                  <div class="row my-2 py-2">
                    <div class="col-4">Low</div>
                    <div class="col-6 align-self-center">
                      <div class="progress">
                        <div class="progress-bar bg-low" role="progressbar"
                          [ngStyle]="{'width': lowSecurityPercentage + '%'}" aria-valuenow="25" aria-valuemin="0"
                          aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="col-2">
                      <p class="text-primary mb-0 fw-bold">{{securityAlerts?.severity_count?.low ?? 'N/A'}}</p>
                    </div>
                  </div>
                  <div class="row my-1 pt-2">
                    <div class="col-4"><div class="text-truncate">Informational</div></div>
                    <div class="col-6 align-self-center">
                      <div class="progress">
                        <div class="progress-bar bg-informational" role="progressbar"
                          [ngStyle]="{'width': informationalSecurityPercentage + '%'}" aria-valuenow="25"
                          aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="col-2">
                      <p class="text-primary mb-0 fw-bold">{{securityAlerts?.severity_count?.informational ?? 'N/A'}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-12 d-flex">
          <div class="card border-0 w-100">
            <app-loader *ngIf="showPasswordExpiresApiLoader || showTotalAdUsersApiLoader || showMailBoxUsageApiLoader"></app-loader>
              <div class="card-body insights">
                <h5 class="card-title2 officeboard_heading mb-4"><span>Insights</span></h5>
                <div class="border-bottom pb-2">
                  <div class="d-flex flex-wrap">
                    <div class="d-flex">
                      <div class="me-2">
                        <i class="fas font-size-18 text-danger fa-sign-in-alt"></i>
                      </div>
                      <p class=" mb-0 text-muted align-self-center">Never Logged In</p>
                    </div>
                    <div class="ms-auto align-self-center">
                      <p class=" mb-0 fw-bold text-primary">{{mailBoxUsageDetails?.Never_login_user_count ?? 'N/A'}}</p>
                    </div>
                  </div>
                </div>
                <div class="border-bottom py-2">
                  <div class="d-flex flex-wrap py-1">
                    <div class=" d-flex">
                      <div class="me-2">
                        <img width="18" alt="" src="../../../../../assets/images/features/security/key-solid-alerted.svg">
                      </div>
                      <p class="mb-0 align-self-center ">Sign-In Denied</p>
                    </div>
                    <div class="ms-auto align-self-center ">
                      <p class=" mb-0 fw-bold text-primary">{{totalADUsers?.total_sign_in_denied_users ?? 'N/A'}}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="border-bottom py-2">
                  <div class="d-flex flex-wrap py-1">
                    <div class="d-flex">
                      <div class="me-2">
                        <i class="fas font-size-18 fa-unlock expired_password"></i>
                      </div>
                      <p class="mb-0 align-self-center ">Expired Password</p>
                    </div>
                    <div class="ms-auto align-self-center">
                      <p class="mb-0 fw-bold text-primary">
                        {{passwordExpires?.response?.password_expired_users_count ?? 'N/A'}}</p>
                    </div>
                  </div>
                </div>
                <div class="pt-2">
                  <div class="d-flex flex-wrap">
                    <div class="d-flex">
                      <div class="me-2">
                        <img width="17" alt=""  src="../../../../../assets/images/features/security/emailforward.svg">
                      </div>
                      <div class="align-self-center">
                        <p class=" mb-0">Email Forwarding</p>
                      </div>
                    </div>
                    <div class="ms-auto align-self-center">
                      <p class=" mb-0 fw-bold text-primary">N/A</p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="col-xl-5 d-flex">
          <app-loader *ngIf="showSecurityAlertsApiLoader"></app-loader>
          <div class="card border-0 w-100">
            <div *ngIf="alertTypesArray.length>0" class="card-body">
              <h5 class="card-title2 officeboard_heading mb-2 pb-1"><span>Alerts</span> Type</h5>
              <app-bar-chart  [alertsData]="alertTypesArray"></app-bar-chart>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-4 d-flex">
          <div class="card border-0 w-100">
            <app-loader *ngIf="showSecurityAlertsApiLoader"></app-loader>
            <div class="card-body ">
              <h5 class="card-title2 officeboard_heading mb-4"><span>Users</span> - Most Alerts
              </h5>
              <div class="mt-3 users_most_alerts">
                <div *ngFor="let alert of mostAlertsArray | slice:0:5"
                  class="d-flex flex-wrap pb-2 mb-2 align-self-center">
                  <p class="fw-bold mb-0 me-1 w_1_7 text-primary">{{alert?.value}}</p>
                  <i class="mdi mdi-minus font-size-lg my-auto text-muted"></i>
                  <div class="me-auto d-flex w-80">
                    <p class="mb-0 ms-1 text-truncate" placement="top" container="body" triggers="hover" [autoClose]="'outside'"  ngbTooltip='{{alert?.name}}'>{{alert?.name}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="card border-0">
            <app-loader *ngIf="showSecurityAlertsApiLoader"></app-loader>
            <div class="card-body ">
              <h5 class="card-title2 officeboard_heading mb-4"><span>Alerts</span> Category</h5>
              <div class="mt-3 list-bg">
                <div *ngFor="let category of alertTypesCategory | slice:0:5"
                  class="d-flex flex-wrap mb-2 align-self-center">
                  <p class="mb-0 me-3 text-truncate w-70 text-truncate w-70" placement="top" container="body" triggers="hover" [autoClose]="'outside'"  ngbTooltip='{{functionsService?.formatTitle(category?.name??" ")}}'>{{functionsService?.formatTitle(category?.name??" ")}}</p>
                  <div class="ms-auto ">
                    <p class="mb-0 ms-3 fw-bold text-primary">{{category?.value}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
