<div class="authentication-bg">
  <div class="login-wrap">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-9">
          <div class="card overflow-hidden">
            <div class="login-box">
              <div class="row align-items-center">
                <div class="col-md-6 d-none d-md-block">
                  <div class="text-center">
                    <h2>Microsoft 365  <br />Management Platform</h2>
                    <img
                      src="assets/images/global/png/microsoft-365-login.png"
                      class="img-fluid"
                      alt="office-365"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-body bg-white">
                    <div class="pt-3 text-center">
                      <img
                        src="assets/images/global/svg/logo-light-login.svg"
                        width="250"
                        alt="officeboard logo"
                      />
                      <div class="mt-4 text-center">
                        <h2 class="h2">Sign Up</h2>
                      </div>
                    </div>
                    <div class="p-2">
                      <form
                        [formGroup]="registerForm"
                        (ngSubmit)="onSignUp()"
                        class="form-horizontal custom-validation"
                      >
                        <div class="mb-3 position-relative">
                          <input
                            type="text"
                            class="form-control"
                            formControlName="fullname"
                            id="full-name"
                            required
                            placeholder="Full Name"
                          />
                          <span class="symbol-input"
                            ><i class="mdi mdi-account"></i
                          ></span>
                          <ng-container
                            *ngFor="let error of errorMessages.fullname"
                          >
                            <p
                              class="form-text text-danger"
                              *ngIf="
                                registerForm
                                  .get('fullname')
                                  .hasError(error.type) &&
                                (registerForm.get('fullname').dirty ||
                                  registerForm.get('fullname').touched)
                              "
                            >
                              {{ error.message }}
                            </p>
                          </ng-container>
                          <ng-container>
                            <p
                              class="form-text text-danger"
                              *ngIf="
                                submitted &&
                                registerForm.get('fullname').errors &&
                                registerForm.get('fullname').errors.serverError
                              "
                            >
                              {{
                                registerForm.get("fullname").errors.serverError
                              }}
                            </p>
                          </ng-container>
                        </div>

                        <div class="mb-3 position-relative">
                          <ngx-intl-tel-input
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [searchCountryField]="[
                              SearchCountryField.Iso2,
                              SearchCountryField.Name
                            ]"
                            [selectFirstCountry]="false"
                            [selectedCountryISO]="countryCode.India"
                            [separateDialCode]="true"
                            [phoneValidation]="true"
                            name="phone"
                            formControlName="phonenumber"
                            [customPlaceholder]="'Mobile No'"
                            class="form-control py-0 ps-2"
                            id="phone-number"
                            (countryChange)="selectedCountry($event)"
                          ></ngx-intl-tel-input>
                          <ng-container  *ngFor="let error of errorMessages.phonenumber">
                            <p
                              class="form-text text-danger"
                              *ngIf=" (registerForm.get('phonenumber').touched) && registerForm.get('phonenumber').hasError(error.type)"
                            >
                              {{error.message}}
                            </p>
                          </ng-container>
                          <ng-container>
                            <p
                              class="form-text text-danger"
                              *ngIf="
                                submitted &&
                                registerForm.get('phonenumber').errors &&
                                registerForm.get('phonenumber').errors.serverError
                              "
                            >
                              {{
                                registerForm.get("phonenumber").errors.serverError
                              }}
                            </p>
                          </ng-container>
                        </div>

                        <div class="mb-3 position-relative">
                          <input
                            type="text"
                            class="form-control"
                            id="email-id"
                            formControlName="email"
                            required
                            placeholder="E-mail ID"
                          />
                          <span class="symbol-input"
                            ><i class="fa fa-at"></i
                          ></span>
                          <ng-container *ngFor="let error of errorMessages.email">
                            <p
                              class="form-text text-danger"
                              *ngIf="
                                registerForm.get('email').hasError(error.type) &&
                                (registerForm.get('email').dirty ||
                                  registerForm.get('email').touched)
                              "
                            >
                              {{ error.message }}
                            </p>
                          </ng-container>
                          <ng-container>
                            <p
                              class="form-text text-danger"
                              *ngIf="
                                submitted &&
                                registerForm.get('email').errors &&
                                registerForm.get('email').errors.serverError
                              "
                            >
                              {{ registerForm.get("email").errors.serverError }}
                            </p>
                          </ng-container>
                        </div>

                        <div class="mb-3">
                          <div class="input-group auth-pass-inputgroup">
                            <input
                              type="password"
                              class="form-control"
                              placeholder="Enter password"
                              formControlName="password"
                              [type]="showPassword ? 'text' : 'password'"
                              aria-label="Password"
                              aria-describedby="password-addon"
                            />
                            <span class="symbol-input"
                              ><i class="mdi mdi-lock"></i
                            ></span>
                            <button
                              class="btn btn-light"
                              type="button"
                              id="password-addon"
                              (click)="hideAndShowPassword()"
                            >
                              <i class="mdi mdi-eye-outline"></i>
                            </button>
                          </div>
                          <input type="password" required hidden />
                          <ng-container
                            *ngFor="let error of errorMessages.password"
                          >
                            <div
                              class="form-text text-danger"
                              *ngIf="
                                registerForm
                                  .get('password')
                                  .hasError(error.type) &&
                                (registerForm.get('password').dirty ||
                                  registerForm.get('password').touched)
                              "
                            >
                              {{ error.message }}
                            </div>
                          </ng-container>
                          <ng-container>
                            <p
                              class="form-text text-danger"
                              *ngIf="
                                submitted &&
                                registerForm.get('password').errors &&
                                registerForm.get('password').errors.serverError
                              "
                            >
                              {{
                                registerForm.get("password").errors.serverError
                              }}
                            </p>
                          </ng-container>
                        </div>
                        <div
                          style="transform: scale(0.8); transform-origin: 0"
                          class="pb-2"
                        >
                          <re-captcha
                            formControlName="recaptchaReactive"
                          ></re-captcha>
                        </div>

                        <div class="mt-0 d-grid">
                          <button
                            class="btn btn-info waves-effect waves-light"
                            type="submit"
                            [disabled]="!registerForm.valid"
                          >
                          <span *ngIf="showLoader" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                          Sign Up
                          </button>
                        </div>
                        <div class="mt-4 text-center">
                          <p>
                            Already an user?
                            <a routerLink="/login" class="fw-medium text-primary">
                              Sign In
                            </a>
                          </p>
                          <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                              <a target="_blank" routerLink="/terms&conditions">
                                Terms of use
                              </a>
                            </li>
                            <li class="list-inline-item">|</li>
                            <li class="list-inline-item">
                              <a target="_blank" routerLink="/privacy-policy">
                                Privacy policy
                              </a>
                            </li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
