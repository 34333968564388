import { FunctionsService } from 'src/app/modules/shared/utilities/functions.service';
import { Component, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
declare var $: any;

declare var $: any
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent{
  @Input() pageName !: string;
  tenantName : string = `${this.cookie.get('_tnt_name')}`;

  constructor(public cookie: CookieService, public commonFunctionService : FunctionsService) {
    this.tenantName = this.commonFunctionService.makeFirstLetterCapital(this.tenantName);
   }

  toggleLeftNavBar(event) {
      event.preventDefault();
      $('body').toggleClass('sidebar-enable');
      if ($(window).width() >= 992) {
        $('body').toggleClass('vertical-collpsed');
      } else {
        $('body').removeClass('vertical-collpsed');
      }
  }

}

