import { FunctionsService } from 'src/app/modules/shared/utilities/functions.service';
import { ConstantsService } from './../../shared/services/config/constants.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AzureAdService {

  constructor(private http: HttpClient, private cookie: CookieService, private constantsService : ConstantsService, private functionsService : FunctionsService) { }

  private org_id = `${this.cookie.get('_tnt_org_id')}`;
  private tnt_id = `${this.cookie.get('_tnt_id')}`;

  private baseUrlAzureAd = `${this.constantsService.azureAdApiUrl}`;
  private baseUrlTeams = `${this.constantsService.teamsPageApiUrl}`;

  private apiUrl_totalAdUsers = this.baseUrlAzureAd + "users?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_MFAEnabledUsers = this.baseUrlAzureAd + "mfastatus?org_id="+this.org_id  + "&az_tenant_id="+this.tnt_id;
  private apiUrl_totalMembers = this.baseUrlTeams + "members?org_id="+this.org_id  + "&az_tenant_id="+this.tnt_id;
  private apiUrl_totalgroups = this.baseUrlAzureAd + "groups?org_id="+this.org_id  + "&az_tenant_id="+this.tnt_id;
  private apiUrl_passwordExpires = this.baseUrlAzureAd + "passwordprofile?org_id="+this.org_id  + "&az_tenant_id="+this.tnt_id;
  private apiUrl_deletedUsers = this.baseUrlAzureAd + "deletedusers?org_id="+this.org_id  + "&az_tenant_id="+this.tnt_id;
  private apiUrl_adminUsers = this.baseUrlAzureAd + "adminusers?org_id="+this.org_id  + "&az_tenant_id="+this.tnt_id;

  // Methods
  getTotalAdUsers() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_totalAdUsers,options)
  };

  getMFAEnabledUsers() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_MFAEnabledUsers,options)
  };

  getTotalMembers() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_totalMembers,options)
  };

  getTotalGroups() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_totalgroups,options)
  };

  getPasswordExpires() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_passwordExpires,options)
  };

  getDeletedUsers() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_deletedUsers,options)
  };

  getAdminUsers() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_adminUsers,options)
  };

}
