<app-header pageName="Dashboard"></app-header>
<app-side-menu></app-side-menu>
<app-right-nav-bar></app-right-nav-bar>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4 col-xl-2 d-flex">
          <div class="card w-100">
            <div class="card-body p-3 d-flex flex-sm-column">
              <div class="d-flex align-items-center mb-0 mb-sm-2 me-auto">
                <div class="avatar-xs me-2">
                  <span class="avatar-title rounded-circle bg-light text-primary font-size-18">
                    <i class="mdi mdi-shield-check blue"></i>
                  </span>
                </div>
                <p class="mb-0 fw-bold lh-sm fs-6">Secure <br class="d-none d-sm-block" /> Score</p>
              </div>
              <div class="text-muted d-flex">
                <!-- If thirty_days_current_score or thirty_days_max_Score is null(or API response unsuccessful) we will display NA -->
                <h2 class="mb-0 fw-bold blue ">{{secureScorePercentage??"NA"}} <small class="text-secondary"
                    *ngIf="secureScorePercentage">({{securityScores?.thirty_days_current_score}} /
                    {{securityScores?.thirty_days_max_Score}})</small></h2>
                <!-- <div class="align-self-center ms-2">
                                    <span class="badge badge-soft-success font-size-13 rounded-3 fw-bold"> -- <i
                                            class="mdi mdi-arrow-up"></i> </span>
                                </div> -->
              </div>
            </div>
            <app-loader *ngIf="showSecurityScoreApiLoader"></app-loader>
          </div>
        </div>
        <div class="col-md-4 col-xl-2 d-flex">
          <div class="card w-100">
            <div class="card-body p-3 d-flex flex-sm-column">
              <div class="d-flex align-items-center mb-0 mb-sm-2 me-auto">
                <div class="avatar-xs me-2">
                  <span class="avatar-title rounded-circle bg-light text-primary font-size-18">
                    <i class="mdi mdi-information red"></i>
                  </span>
                </div>
                <p class="mb-0 fw-bold lh-sm fs-6">High Severity <br class="d-none d-sm-block" /> Alerts</p>
              </div>
              <div class="text-muted d-flex">
                <!-- If highSeverity or totalSeverity is null(or API response unsuccessful) we will display NA -->
                <h2 class="mb-0 fw-bold red">{{highSeverityPercentage??"NA"}}<small class="text-secondary"
                    *ngIf="highSeverityPercentage"> ({{highSeverity}} / {{totalSeverity}}) </small></h2>
                <div class="align-self-center ms-2">
                  <!-- <span class="badge badge-soft-danger font-size-13 rounded-3 fw-bold"> -- <i
                                            class="mdi mdi-arrow-down"></i> </span> -->
                </div>
              </div>
            </div>
            <app-loader *ngIf="showSecurityAlertsApiLoader"></app-loader>
          </div>
        </div>
        <div class="col-md-4 col-xl-2 d-flex">
          <div class="card w-100">
            <div class="card-body p-3 d-flex flex-sm-column">
              <div class="d-flex align-items-center mb-0 mb-sm-2 me-auto">
                <div class="avatar-xs me-2">
                  <span class="avatar-title rounded-circle bg-light text-primary font-size-18">
                    <i class="mdi mdi-account-group text-primary"></i>
                  </span>
                </div>
                <p class="mb-0 fw-bold lh-sm fs-6">Licensed Users</p>
              </div>
              <div class="text-muted d-flex">
                <h2 class="mb-0 fw-bold text-primary align-self-center">{{totalAdUser?.total_licensed_users??"NA"}}
                </h2>
                <div class="align-self-center ms-2">
                  <!-- <span class="badge badge-soft-danger font-size-13 rounded-3 fw-bold"> -- <i
                                            class="mdi mdi-arrow-down"></i> </span> -->
                </div>
              </div>
            </div>
            <app-loader *ngIf="showTotalAdUsersApiLoader"></app-loader>
          </div>
        </div>
        <div class="col-md-4 col-xl-2 d-flex">
          <div class="card w-100">
            <div class="card-body p-3 d-flex flex-sm-column">
              <div class="d-flex align-items-center mb-0 mb-sm-2 me-auto">
                <div class="avatar-xs me-2">
                  <span class="avatar-title rounded-circle bg-light text-primary font-size-18">
                    <i class="mdi mdi-microsoft-teams text_blue_teams"></i>
                  </span>
                </div>
                <p class="mb-0 fw-bold lh-sm fs-6">Teams</p>
              </div>
              <div class="text-muted d-flex">
                <h2 class="mb-0 fw-bold text_blue_teams align-self-center">{{teamsData?.total_teams_count}}</h2>
                <div class="align-self-center ms-2">
                  <!-- <span class="badge badge-soft-danger font-size-13 rounded-3 fw-bold"> -- <i
                                            class="mdi mdi-arrow-down"></i> </span> -->
                </div>
              </div>
            </div>
            <app-loader *ngIf="showTeamsDataApiLoader"></app-loader>
          </div>
        </div>
        <div class="col-md-4 col-xl-2 d-flex">
          <div class="card w-100">
            <div class="card-body p-3 d-flex flex-sm-column">
              <div class="d-flex align-items-center mb-0 mb-sm-2 me-auto">
                <div class="avatar-xs me-2">
                  <span class="avatar-title rounded-circle bg-light text-primary font-size-18">
                    <i class="mdi mdi-email-open text_yellow"></i>
                  </span>
                </div>
                <p class="mb-0 fw-bold lh-sm fs-6">Active <br class="d-none d-sm-block" /> Mailboxes</p>
              </div>
              <div class="text-muted d-flex">
                <!-- If Active_Mailboxes or Mailboxes is null(or API response unsuccessful) we will display NA -->
                <h2 class="mb-0 fw-bold text_yellow">{{activeMailboxPercentage??"NA"}} <small class="text-secondary"
                    *ngIf="activeMailboxPercentage">({{exchangeActiveInactive?.total_active_mailboxes_thirty_days}} /
                    {{exchangeActiveInactive?.total_mailboxes_thirty_days}}) </small></h2>
                <div class="align-self-center ms-2">
                  <!-- <span class="badge badge-soft-success font-size-13 rounded-3 fw-bold"> -- <i
                                            class="mdi mdi-arrow-up"></i> </span> -->
                </div>
              </div>
            </div>
            <app-loader *ngIf="showMailBoxDataApiLoader"></app-loader>
          </div>
        </div>
        <div class="col-md-4 col-xl-2 d-flex">
          <div class="card w-100">
            <div class="card-body p-3 d-flex flex-sm-column">
              <div class="d-flex align-items-center mb-0 mb-sm-2 me-auto">
                <div class="avatar-xs me-2">
                  <span class="avatar-title rounded-circle bg-light text-primary font-size-18">
                    <i class="mdi mdi-earth text_green"></i>
                  </span>
                </div>
                <p class="mb-0 fw-bold lh-sm fs-6">Site <br class="d-none d-sm-block" /> Collection</p>
              </div>
              <div class="text-muted d-flex">
                <h2 class="mb-0 fw-bold text_green align-self-center">
                  <!-- If total_sites_thirty_days is null(or API response unsuccessful) we will display NA -->
                  {{activeSharepoint?.total_sites_thirty_days??"NA"}}
                </h2>
                <div class="align-self-center ms-2">
                  <!-- <span class="badge badge-soft-success font-size-13 rounded-3 fw-bold"> -- <i
                                            class="mdi mdi-arrow-up"></i> </span> -->
                </div>
              </div>
            </div>
            <app-loader *ngIf="showActiveSharePointApiLoader"></app-loader>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-xl-4 d-flex">
          <div class="card w-100">
            <div class="card-body">
              <div class="d-sm-flex flex-wrap">
                <h4 class="card-title officeboard_heading mb-4">
                  <span>Security</span>
                </h4>
                <!-- <div class="ms-auto">
                                    <a
                                        class="btn-primary btn-sm fw-bold font-size-12">Max
                                        Secure Score = -- </a>
                                </div> -->
              </div>
              <app-area-chart *ngIf="securityScores && securityAlerts" [data1]="securityScores" [data2]="securityAlerts"
                data1Title="Secure Score" data2Title="Security Alerts" [colors]="['#30D780', '#38A0BB']">
              </app-area-chart>
            </div>
            <app-loader *ngIf="showSecurityScoreApiLoader || showSecurityAlertsApiLoader"></app-loader>
          </div>
        </div>
        <div class="col-md-6 col-xl-4 d-flex">
          <div class="card w-100">
            <div class="card-body">
              <div class="d-sm-flex flex-wrap">
                <h4 class="card-title officeboard_heading mb-4">
                  <span>Teams</span>
                </h4>
              </div>
              <div class="row">
                <div class="col-6">
                  <app-pie-chart *ngIf="teamsData" [data1]="teamsData?.total_public_teams"
                    [data2]="teamsData?.total_private_teams" data1Title="Public" data2Title="Private"
                    [titleValue]="teamsData?.total_public_teams+teamsData?.total_private_teams"
                    [colors]="['#1f78b4','#78CDF9']">
                  </app-pie-chart>
                </div>
                <div class="col-6 d-flex">
                  <div class="text-left text-muted team_graph align-self-center">
                    <div class="row">
                      <div class="col-12">
                        <div class="mt-0">
                          <p class="mb-0 text-truncate"><i class="mdi mdi-circle dot_1 me-2"></i> Public</p>
                          <h5 class="ms-4 mb-0">{{teamsData?.total_public_teams}}</h5>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="mt-2">
                          <p class="mb-0 text-truncate"><i class="mdi mdi-circle dot_2 me-2"></i> Private</p>
                          <h5 class="ms-4 mb-0">{{teamsData?.total_private_teams}}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="border-bottom" />
                <div class="teams_insights mb-1">
                  <p class="fw-bold mb-2">Channels - {{teamsChannelData?.total_channels}}</p>
                  <div class="position-relative mb-2 d-flex">
                    <div class="position-absolute z_index w-100 d-flex">
                      <h6 class=" mb-0 font-weight-semibold">Standard -
                        <b>{{teamsChannelData?.total_public_channels}}</b>
                      </h6>
                      <h6 class=" mb-0 font-weight-semibold ms-auto">Private -
                        <b>{{teamsChannelData?.total_private_channels}}</b>
                      </h6>
                    </div>
                  </div>
                  <app-progressive-bar-chart *ngIf="teamsChannelData" class="display-inh mx-n2"
                    [data1]="teamsChannelData?.total_public_channels" [data2]="teamsChannelData?.total_private_channels"
                    [data1Title]="'Standard Channels'" [data2Title]="'Private'"></app-progressive-bar-chart>
                </div>
                <div class="teams_insights">
                  <p class="fw-bold mb-2">Calls -
                    {{teamsCallsData?.calls_details?.total_pstn_calls_thirty_days+teamsCallsData?.calls_details?.total_direct_routing_calls_thirty_days}}
                  </p>
                  <div class="position-relative mb-2 d-flex ">
                    <div class="position-absolute z_index w-100 d-flex">
                      <h6 class="mb-0 font-weight-semibold">PSTN -
                        <b>{{teamsCallsData?.calls_details?.total_pstn_calls_thirty_days}}</b>
                      </h6>
                      <h6 class="mb-0 font-weight-semibold ms-auto">Direct Routing -
                        <b>{{teamsCallsData?.calls_details?.total_direct_routing_calls_thirty_days}}</b>
                      </h6>
                    </div>
                  </div>
                  <app-progressive-bar-chart *ngIf="teamsCallsData?.calls_details" class="mx-n2 display-inh"
                    [data1]="teamsCallsData?.calls_details.total_pstn_calls_thirty_days"
                    [data2]="teamsCallsData?.calls_details.total_direct_routing_calls_thirty_days" [data1Title]="'PSTN'"
                    [data2Title]="'Direct Routing'">
                  </app-progressive-bar-chart>
                </div>
              </div>
            </div>
            <app-loader *ngIf="showTeamsDataApiLoader || showTeamsChannelApiLoader ||showTeamsCallsApiLoader">
            </app-loader>
          </div>
        </div>
        <div class="col-md-6 col-xl-4 d-flex">
          <div class="card w-100">
            <div class="card-body">
              <div class="d-sm-flex flex-wrap">
                <h4 class="card-title officeboard_heading mb-4">
                  <span>Azure AD</span> Authentication Methods
                </h4>
              </div>
              <div>
                <ul class="list-group list-group-flush authentication_methods">
                    <li class="list-group-item p-0" *ngFor="let authMethods of authMethodArray | paginate: { id: 'authMethods', itemsPerPage: 8, currentPage: pageNumber }; let i=index">
                      <div class="px-2 py-1">
                        <div class="d-flex flex-wrap">
                          <span class="w-70 text-truncate ellipsis" [attr.data-text]="authMethods?.methodName">
                            <i class="mdi text_dark_green me-1" [ngClass]="authMethods?.icon"></i>{{authMethods?.methodName}}
                          </span>
                          <i class="mdi mdi-information-outline me-1 text-blue" placement="top" container="body" triggers="hover" [autoClose]="'outside'"  ngbTooltip={{authMethods?.description}}></i>
                          <span class="float-end ms-auto text_dark_green fw-bold">{{authMethods?.userCount}}</span>
                        </div>
                      </div>
                    </li>
                </ul>
              </div>
            </div>
            <div class="d-flex">
              <div class="pagination pagination-rounded mt-3 ms-auto">
                <pagination-controls id="authMethods" (pageChange)='onPageChange($event)' (pageBoundsCorrection)="onPageBoundsCorrection($event)" [autoHide]="true"
                  class="">
                </pagination-controls>
              </div>
            </div>
            <app-loader
              *ngIf="showAuthMethodApiLoader">
            </app-loader>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-xl-7 d-flex">
          <div class="card w-100">
            <div class="card-body">
              <h4 class="card-title officeboard_heading mb-4">
                <span>Azure</span> AD
              </h4>
              <div class="row">
                <div class="col-sm-5">
                  <div class="table-responsive azure_ad_table">
                    <table class="table table-striped mb-0">
                      <tbody>
                        <tr>
                          <td>AD Users</td>
                          <td><span class="text_dark_green fw-bold">{{totalAdUser?.total_users??"NA"}}</span></td>
                        </tr>
                        <tr>
                          <td>Guest Users</td>
                          <td><span class="text_dark_green fw-bold">{{totalMembers?.total_guests??"NA"}}</span></td>
                        </tr>
                        <tr>
                          <td>AD Groups</td>
                          <td><span class="text_dark_green fw-bold">{{totalGroups?.total_groups??"NA"}}</span></td>
                        </tr>
                        <tr>
                          <td>Global Admin(s)</td>
                          <td><span class="text_dark_green fw-bold">{{globalAdminsData?.total_users??"NA"}}</span></td>
                        </tr>
                        <tr>
                          <td>MFA</td>
                          <td><span
                              class="text_dark_green fw-bold">{{MFAEnabledUsers?.total_mfa_registered_users??"NA"}}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr class="border-bottom my-3 d-sm-none" />
                <div class="col-sm-7">
                  <div class="row">
                    <div class="col-6 mb-3">
                      <div class="d-flex align-items-center light_grey_bg py-3 text-center rounded_10 w-100 h-100">
                        <div class="align-self-center w-100">
                          <h4 class="mb-0 blue">
                            {{totalAdUser?.total_licensed_users??"NA"}}</h4>
                          <p class="mb-0 mt-2">Licensed Users</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 mb-3">
                      <div class="d-flex align-items-center light_grey_bg py-3 text-center rounded_10 w-100 h-100">
                        <div class="align-self-center w-100">
                          <h4 class="mb-0 blue">
                            {{deletedUsers?.total_users_deleted??"NA"}}</h4>
                          <p class="mb-0 mt-2">Deleted Users</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="d-flex align-items-center light_grey_bg py-3 text-center rounded_10 w-100 h-100">
                        <div class="align-self-center w-100">
                          <h4 class="mb-0 blue">{{totalGroups?.total_groups??"NA"}}</h4>
                          <p class="mb-0 mt-2">Groups </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="d-flex align-items-center light_grey_bg py-3 text-center rounded_10 w-100 h-100">
                        <div class="align-self-center w-100">
                          <h4 class="mb-0 blue">
                            {{MFAEnabledUsers?.total_mfa_registered_users??"NA"}}</h4>
                          <p class="mb-0 mt-2">MFA Active Users</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <app-loader
              *ngIf="showTotalAdUsersApiLoader || showTotalMembersApiLoader || showMfaEnabledUsersApiLoader || showTotalGroupsApiLoader || showDeletedUsersApiLoader">
            </app-loader>
          </div>
        </div>
        <div class="col-md-12 col-xl-5 d-flex">
          <div class="card w-100">
            <div class="card-body">
              <h4 class="card-title officeboard_heading mb-4">
                <span>Share</span>Point
              </h4>
              <div *ngIf="activeSharepoint">
                <app-column-chart [data]="activeSharepoint"></app-column-chart>
              </div>
            </div>
            <app-loader *ngIf="showActiveSharePointApiLoader"></app-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
