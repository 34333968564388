<app-header pageName="SharePoint"></app-header>
<app-right-nav-bar></app-right-nav-bar>
<app-side-menu></app-side-menu>
<div class="main-content">
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-7">
                    <div class="row">
                        <div class="col-sm-4 col-xl-4">
                            <div class="card">
                              <app-loader *ngIf="showActiveSharePointApiLoader"></app-loader>
                                <div class="card-body">
                                    <h6 class="card-title2 officeboard_heading mb-3"><span>Site</span> Collections</h6>
                                    <h3 class="text-center text-primary">
                                        {{activeSharepoint?.total_sites_thirty_days}}</h3>
                                    <div *ngIf="activeSharepoint">
                                        <app-pie-chart [titleValue]="activeSharepoint?.total_sites_thirty_days" [data1]="activeSharepoint?.total_active_sites_thirty_days" [data2]="activeSharepoint?.total_inactive_sites_thirty_days"
                                        data1Title="Active" data2Title="Inactive" [colors]="['#4abf57','#bbe8c1']"></app-pie-chart>
                                    </div>
                                    <div class="text-left text-muted managers_graph mt-2">
                                        <div class="row">
                                            <div class="col">
                                                <div class="mt-0">
                                                    <p class="mb-1 text-truncate"><i
                                                            class="mdi mdi-circle dot_1 me-1"></i> Active</p>
                                                    <h5 class="ms-4 mb-0">
                                                        {{activeSharepoint?.total_active_sites_thirty_days}}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div class="mt-2">
                                                    <p class="mb-1 text-truncate"><i
                                                            class="mdi mdi-circle dot_2 me-1"></i> Inactive</p>
                                                    <h5 class="ms-4 mb-0">
                                                        {{activeSharepoint?.total_inactive_sites_thirty_days}}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-xl-4">
                            <div class="card">
                              <app-loader *ngIf="showActiveSharePointApiLoader"></app-loader>
                                <div class="card-body">
                                    <h6 class="card-title2 officeboard_heading mb-3"><span>Inactive</span> Sites</h6>
                                    <h3 class="text-center text-primary">{{this.activeSharepoint?.total_inactive_sites_thirty_days}}</h3>
                                    <div *ngIf ="activeSharepoint">
                                        <app-pie-chart [titleValue]="activeSharepoint?.total_inactive_sites_thirty_days" [data1]="activeSharepoint?.total_inactive_sites_seven_days" [data2]="activeSharepoint?.total_inactive_sites_thirty_days"
                                        data1Title="7 Days" data2Title="30 Days" [colors]="['#1f78b4','#50a5f1']"></app-pie-chart>
                                    </div>
                                    <div class="text-left text-muted total_user_graph mt-2">
                                        <div class="row">
                                            <div class="col">
                                                <div class="mt-0">
                                                    <p class="mb-1 text-truncate"><i
                                                            class="mdi mdi-circle dot_1 me-1"></i> 7 Days</p>
                                                    <h5 class="ms-4 mb-0">
                                                        {{activeSharepoint?.total_inactive_sites_seven_days}}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div class="mt-2">
                                                    <p class="mb-1 text-truncate"><i
                                                            class="mdi mdi-circle dot_2 me-1"></i> 30 Days</p>
                                                    <h5 class="ms-4 mb-0">
                                                        {{activeSharepoint?.total_inactive_sites_thirty_days}}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-xl-4">
                            <div class="card">
                              <app-loader *ngIf="showTotalSharePointApiLoader"></app-loader>
                                <div class="card-body">
                                    <h6 class="card-title2 officeboard_heading mb-3"><span>Lists</span></h6>
                                    <h3 class="text-center text-primary">{{totalSharepoint?.response?.total_lists}}</h3>
                                    <div *ngIf ="totalSharepoint">
                                        <app-pie-chart [titleValue]="totalSharepoint?.response?.total_lists" [data1]="0" [data2]="0"
                                        data1Title="Empty" data2Title="Not Empty" [colors]="['#d2a92c', '#fee8a6']"></app-pie-chart>
                                    </div>
                                    <div class="text-left text-muted licenced_users_graph mt-2">
                                        <div class="row">
                                            <div class="col">
                                                <div class="mt-0">
                                                    <p class="mb-1 text-truncate"><i
                                                            class="mdi mdi-circle dot_1 me-1"></i> Empty</p>
                                                    <h5 class="ms-4 mb-0">N/A</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div class="mt-2">
                                                    <p class="mb-1 text-truncate"><i
                                                            class="mdi mdi-circle dot_2 me-1"></i> Not Empty
                                                    </p>
                                                    <h5 class="ms-4 mb-0">N/A</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-sm-12 col-xl-5">
                    <div class="card border-0">
                      <app-loader *ngIf="showEachSiteApiLoader"></app-loader>
                        <div class="card-body">
                            <h6 class="card-title2 officeboard_heading mb-3"><span>Top 5 Sites </span> by Storage - 30
                                Days</h6>
                            <div class="row">
                                <div class="col-sm-6 col-xl-6">
                                    <div id="top_five_storage" class="pt-4"></div>
                                </div>
                                <div class="col-sm-6 col-lg-6 col-sm-6 align-self-center recipient_mailbox mt-4">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <p class="mb-0 text-muted text-truncate">
                                                <i
                                                    class="mdi mdi-circle align-middle font-size-10 me-1 group_mailbox"></i>
                                                {{eachSiteDetails?.response?.top_five_storage_used[0]?.site_name}}
                                            </p>
                                            <h5 class="ms-3 mb-2 font-weight-bold">
                                                {{CallDays(eachSiteDetails?.response?.top_five_storage_used[0]?.Storage_Used_GB)}}
                                                GB</h5>
                                        </div>

                                        <div class="col-sm-12">
                                            <p class="mb-0 text-muted text-truncate">
                                                <i
                                                    class="mdi mdi-circle align-middle font-size-10 me-1 shared_mailbox"></i>
                                                {{eachSiteDetails?.response?.top_five_storage_used[1]?.site_name}}
                                            </p>
                                            <h5 class="ms-3 mb-2 font-weight-bold">
                                                {{CallDays(eachSiteDetails?.response?.top_five_storage_used[1]?.Storage_Used_GB)}}
                                                GB</h5>
                                        </div>

                                        <div class="col-sm-12">
                                            <p class="mb-0 text-muted text-truncate">
                                                <i
                                                    class="mdi mdi-circle align-middle font-size-10 me-1 user_mailbox"></i>
                                                {{eachSiteDetails?.response?.top_five_storage_used[2]?.site_name}}
                                            </p>
                                            <h5 class="ms-3 mb-2 font-weight-bold">
                                                {{CallDays(eachSiteDetails?.response?.top_five_storage_used[2]?.Storage_Used_GB)}}
                                                GB</h5>
                                        </div>

                                        <div class="col-sm-12">
                                            <p class="mb-0 text-muted text-truncate">
                                                <i
                                                    class="mdi mdi-circle align-middle font-size-10 me-1 room_mailbox"></i>
                                                {{eachSiteDetails?.response?.top_five_storage_used[3]?.site_name}}
                                            </p>
                                            <h5 class="ms-3 mb-2 font-weight-bold">
                                                {{CallDays(eachSiteDetails?.response?.top_five_storage_used[3]?.Storage_Used_GB)}}
                                                GB</h5>
                                        </div>

                                        <div class="col-sm-12">
                                            <p class="mb-0 text-muted text-truncate">
                                                <i
                                                    class="mdi mdi-circle align-middle font-size-10 me-1 equipment_mailbox"></i>
                                                {{eachSiteDetails?.response?.top_five_storage_used[4]?.site_name}}
                                            </p>
                                            <h5 class="ms-3 mb-2 font-weight-bold">
                                                {{CallDays(eachSiteDetails?.response?.top_five_storage_used[4]?.Storage_Used_GB)}}
                                                GB</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row azure_ad_page">
                <div class="col-12">
                    <div class="card border-0">
                      <app-loader *ngIf="showSharePointSitesInfoApiLoader"></app-loader>
                        <div class="card-body">
                            <div class="d-flex mb-2">
                                <h5 class="card-title2 officeboard_heading text-blue font-weight-bold my-auto">
                                    <span>Inactive</span> Sites</h5>
                                <form class="table-search d-none d-lg-block ms-auto pt-0 pb-0">
                                    <div class="position-relative">
                                        <input type="text" #filter class="form-control" placeholder="Search..." />
                                        <span class="fa fa-search"></span>
                                    </div>
                                </form>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped table_even mb-0 table-borderless border">
                                    <thead class="table-light">
                                        <tr>
                                            <th>#</th>
                                            <th>Display Name</th>
                                            <th>Created Date</th>
                                            <th>Modified Date</th>
                                            <th>Web URL</th>
                                            <th>Days Inactive</th>
                                            <th class="text-center">
                                                Site Users
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container
                                            *ngFor="let share of sharepointSitesinfo?.response?.inactive_sites_more_than_seven_days_data | search: 'name':filter.value | paginate: { id: 'teamsTable', itemsPerPage: 10, currentPage: pagination1 }; let i = index">
                                            <tr>
                                                <td>{{((pagination1 - 1)*10)+i+1}}</td>
                                                <td>{{share?.displayName}}</td>
                                                <td>{{share?.createdDateTime | date:'d-MMM-y'}}</td>
                                                <td>{{share?.lastModifiedDateTime | date:'d-MMM-y'}}</td>
                                                <td><a target="_blank" [href]="share?.webUrl??'#'"><p class="mb-0 d-flex w-250" placement="top" triggers="hover" [autoClose]="'outside'"  ngbTooltip='{{share?.webUrl}}'><span class="text-truncate w-100">{{share?.webUrl}}</span></p></a></td>
                                                <td>{{share?.days_inactive_since}}</td>
                                                <td></td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-flex">
                                <div class="pagination pagination-rounded mt-3 ms-auto">
                                    <pagination-controls id="teamsTable" (pageChange)="pagination1 = $event"
                                        (pageBoundsCorrection)="onPageBoundsCorrection($event)">
                                    </pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-footer></app-footer>
          </div>
    </div>
</div>
