import { FunctionsService } from 'src/app/modules/shared/utilities/functions.service';
import { userAuthService } from 'src/app/modules/shared/services/user-auth.service';
import { MessageConstantsService } from './../../../shared/utilities/messageConstants.service';
import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-create-organization',
  templateUrl: './create-organization.component.html',
  styleUrls: ['./create-organization.component.scss'],
})
export class CreateOrganizationComponent {

  showLoader:boolean = false;

  createOrgForm = new FormGroup({
    orgName: new FormControl(null, Validators.required),
  });

  constructor(
    private router: Router,
    private userAuthService: userAuthService,
    private cookie: CookieService,
    public messageConstantsService: MessageConstantsService,
    private functionsService: FunctionsService,
    private toastr: ToastrService
  ) {
    this.toastr.clear();
    if (this.router.getCurrentNavigation()?.extras.state?.confirmUserRoute == true ) {          // To check if the user is landing on this page after confirming OTP or direct from Login Page
      this.toastr.success(this.messageConstantsService.otpSucessMessage);
    } else
      this.toastr.success(this.messageConstantsService.createOrganizationMessage);
  }

  onSubmit() {
    this.showLoader = true;
    const reqData = {
      org_name: this.createOrgForm.controls.orgName.value.trim(),
      user_id: this.cookie.get('_u_id'), // _u_id is User ID
    };
    this.userAuthService.createOrganization(reqData).subscribe(
      (res: any) => {
        const apiRes = JSON.parse(res?.response);
        if (apiRes.response == true) {
          if (this.cookie.get('otp_verified')) {
            this.router.navigate(['/subscription-list']);
          } else {

            this.userAuthService.logout();
            this.router.navigate(['/login'], {
              state: {
                isVerified: true,
              },
            });
          }
          this.functionsService.setNewCookies([                 // _o_name is Organization Name and _o_id is Organization ID
            {
              name: '_o_name',
              value: this.createOrgForm.controls.orgName?.value,
            },
            { name: '_o_id', value: apiRes?.org_id},
          ]);

        } else {
          this.showLoader=false;
          this.toastr.clear();
          this.toastr.error(this.messageConstantsService.errorMessage);
          this.createOrgForm.reset();
        }
      },
      (err) => {
        this.showLoader=false;
        this.toastr.clear();
        this.toastr.error(this.messageConstantsService.errorMessage);
        this.createOrgForm.reset();
      }
    );
  }
}