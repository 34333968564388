
   <div class="right-bar right-bar-enabled">
    <div data-simplebar class="h-100">
        <div class="rightbar-title d-flex align-items-center justify-content-center py-3">
          <div class="avatar-xs" container="body" arrow="left" placement="left" ngbTooltip="{{li_user_name}} ( {{org_name}} )">
            <span class="avatar-title rounded-circle bg-light text-dark fw-bold font-size-16">
             {{ this.commonFunctionService.makeFirstLetterCapital(this.li_user_name.charAt(0))}}
            </span>
          </div>
        </div>

        <hr class="my-0" />
        <div class="px-1 py-2 text-center">
          <div class="avatar-xs mx-auto">
            <a container="body" arrow="left" placement="left" ngbTooltip="Logout" class="avatar-title rounded-circle text-white logout" (click)="logout()">
              <i class="bx bx-power-off font-size-16 align-middle text-white p-2"></i>
            </a>
          </div>
        </div>

    </div>
</div>

<button container="body" arrow="left" placement="left" ngbTooltip="Collapse Sidebar" type="button" (click)="toggleRightNavBar($event)" class="btn btn-primary btn-icon float-right right_icon_toggle_btn d-block position-fixed right-bar-toggle waves-effect">
  <i class="mdi mdi-arrow-right"></i>
</button>
