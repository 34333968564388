import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-column-chart',
  templateUrl: './column-chart.component.html',
  styleUrls: ['./column-chart.component.scss']
})
export class ColumnChartComponent implements OnInit {
  highcharts = Highcharts;
  @Input() data: any;

  chartOptions: any;
  constructor() { }

  ngOnInit(): void {
    this.chartOptions = {
      chart: {
         type: 'column',
         height:190
      },
      title: {
         text: ''
      },
      xAxis:{
         categories: ['30 Days','90 Days', '180 Days'],
         crosshair: true
      },
      yAxis : {
         min: 0,
         title: {
          text: ''
       }
      },
      credits: {
        enabled: false
      },
      plotOptions : {
         column: {
            pointPadding: 0.2,
            borderWidth: 0,
            enableMouseTracking : true,
            dataLabels: {
              enabled: true,
              y: -10,
              shape : 'square',
          }
         },

      },
      point: {
        events: {
          legendItemClick: function () {
            return false;
          }
        }
      },
      series: [{
         name: 'Active Sites',
         data: [this.data?.total_active_sites_thirty_days, this.data.total_active_sites_ninety_days, this.data?.total_active_sites_one_hundred_eighty_days],
         color:'#70AD47',
         dataLabels : {
          backgroundColor: '#E2F0D9',

         }
      },
      {
         name: 'Inactive Sites',
         data: [this.data?.total_inactive_sites_thirty_days, this.data?.total_inactive_sites_ninety_days, this.data?.total_inactive_sites_one_hundred_eighty_days],
         color:'#5B9BD5',
         dataLabels : {
          backgroundColor: '#DEEBF7',

         }
      }]
   };
  }
}
