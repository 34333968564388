import { FunctionsService } from 'src/app/modules/shared/utilities/functions.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

enum statusIcons {
  resolved = 'mdi-checkbox-marked-circle-outline text-green',
  newAlert = 'mdi-alert text-danger',
  dismissed = 'mdi-close-circle-outline'
} //Status Icons Classes


@Injectable({
  providedIn: 'root'
})
export class SecurityAlertReportService {

  constructor(private functionsService: FunctionsService) { }

  private sharingObserver = new BehaviorSubject(null);
  sharedData = this.sharingObserver.asObservable();

  //Function to set securityAlertDetail Table Data
  setSecurityAlertDetailsData(data: any[]) {
    let tableData: any = [];
    for (const item in data) {
      if (item === 'newAlert') {  //Storing New Alert data first in array to show it before any other status.
        data[item].forEach(element => {
          const temp = {
            category: this.functionsService.formatTitle(element?.category),
            severity: element?.severity,
            severityColor: element?.severity === 'low' ? 'bg_low' : element?.severity === 'medium' ? 'bg_medium' : element?.severity === 'high' ? 'bg_high' : 'bg-blue',
            status: this.functionsService.formatTitle(element?.status),
            statusIcon: element?.status === 'resolved' ? statusIcons.resolved : element?.status === 'newAlert' ? statusIcons.newAlert : statusIcons.dismissed,
            userName: element?.userStates[0]?.userPrincipalName,
            domainName: element?.userStates[0]?.domainName,
            date: this.functionsService.getFormattedDate(element?.createdDateTime, 'YYYY-MM-DD HH:mm:ss', 'DD MMM YYYY'),
            time: this.functionsService.getFormattedTime(element?.createdDateTime, 'YYYY-MM-DD HH:mm:ss', 'HH:mm:ss'),
            detailsTitle: element?.title,
            recommendendActions: element?.recommendedActions,
            sourceMaterials: element?.sourceMaterials,
            description: element?.description,
            vendorInfo: element?.vendorInformation,
            fileStatesInfo: element?.fileStates,
            hostStatesInfo: element?.hostStates,
            securityResources: element?.securityResources
          }
          tableData.push(temp)
        });
      }
    }

    for (const item in data) {
      if (item != 'newAlert') {  //Storing other status data in Array in order to show after newAlert.
        data[item].forEach(element => {
          const temp = {
            category: this.functionsService.formatTitle(element?.category),
            severity: element?.severity,
            severityColor: element?.severity === 'low' ? 'bg_low' : element?.severity === 'medium' ? 'bg_medium' : element?.severity === 'high' ? 'bg_high' : 'bg-blue',
            status: this.functionsService.formatTitle(element?.status),
            statusIcon: element?.status === 'resolved' ? statusIcons.resolved : element?.status === 'newAlert' ? statusIcons.newAlert : statusIcons.dismissed,
            userName: element?.userStates[0]?.userPrincipalName,
            domainName: element?.userStates[0]?.domainName,
            date: this.functionsService.getFormattedDate(element?.createdDateTime, 'YYYY-MM-DD HH:mm:ss', 'DD MMM YYYY'),
            time: this.functionsService.getFormattedTime(element?.createdDateTime, 'YYYY-MM-DD HH:mm:ss', 'HH:mm:ss'),
            detailsTitle: element?.title,
            recommendendActions: element?.recommendedActions,
            sourceMaterials: element?.sourceMaterials,
            description: element?.description,
            vendorInfo: element?.vendorInformation,
            fileStatesInfo: element?.fileStates,
            hostStatesInfo: element?.hostStates,
            securityResources: element?.securityResources
          }
          tableData.push(temp)
        });
      }
    }
    this.sharingObserver.next(tableData);
  }

}
