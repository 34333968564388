<app-header pageName="AzureAD"></app-header>
<div id="layout-wrapper">
  <app-side-menu></app-side-menu>
  <app-right-nav-bar></app-right-nav-bar>
<div class="main-content">
  <div class="page-content">
      <div class="container-fluid">
          <div class="row">
              <div class="col-xl-8">
                  <div class="row">
                      <div class="col-sm-6 col-xl-3">
                           <!-- Total Subscriptions -->
                          <div class="card">
                            <app-loader *ngIf="showTotalMembersApiLoader"></app-loader>
                              <div class="card-body">
                                  <h6 class="card-title2 officeboard_heading mb-3"><span>Users</span></h6>
                                  <h3 class="text-center text-primary">{{totalMembers?.total_members_count}}</h3>
                                  <div *ngIf="totalMembers">
                                      <app-pie-chart [titleValue]="totalMembers?.total_members_count" [data1]="members" [data2]="totalMembers?.total_guests"
                                      [colors]="['#1f78b4', '#A6CEE3'] " data1Title="Members" data2Title="Guests"></app-pie-chart>
                                  </div>
                                  <div class="text-left text-muted total_user_graph mt-2">
                                      <div class="row">
                                          <div class="col">
                                              <div class="mt-0">
                                                  <p class="mb-1 text-truncate"><i class="mdi mdi-circle dot_1 me-1"></i> Members</p>
                                                  <h5 class="ms-4">{{members}}</h5>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="row">
                                          <div class="col">
                                              <div class="mt-2">
                                                  <p class="mb-1 text-truncate"><i class="mdi mdi-circle dot_2 me-1"></i> Guests</p>
                                                  <h5 class="ms-4 mb-0">{{totalMembers?.total_guests}}</h5>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-sm-6 col-xl-3">
                          <!-- Monthly -->
                         <div class="card">
                          <app-loader *ngIf="showTotalAdUsersApiLoader"></app-loader>
                             <div class="card-body">
                                 <div class="text-truncate">
                                  <h6 class="card-title2 officeboard_heading mb-3"><span>Sign-in</span> Status</h6>
                                 </div>
                                 <h3 class="text-center text-primary">{{totalAdUser?.response?.total_users}}</h3>
                                 <div *ngIf="totalAdUser">
                                  <app-pie-chart [titleValue]="totalAdUser?.response?.total_users" [data1]="totalAdUser?.response?.total_sign_in_allowed_users" [data2]="totalAdUser?.response?.total_sign_in_denied_users"
                                   [colors]="['#12ACA7', '#C2F2F0']" data1Title="Allowed" data2Title="Denied"></app-pie-chart>
                                                          </div>

                              <div class="text-left text-muted sign_allowed_graph mt-2">
                                  <div class="row">
                                      <div class="col">
                                          <div class="mt-0">
                                              <p class="mb-1 text-truncate"><i class="mdi mdi-circle dot_1 me-1"></i> Allowed</p>
                                              <h5 class="ms-4">{{totalAdUser?.response?.total_sign_in_allowed_users}}</h5>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col">
                                          <div class="mt-2">
                                              <p class="mb-1 text-truncate"><i class="mdi mdi-circle dot_2 me-1"></i> Denied</p>
                                              <h5 class="ms-4 mb-0">{{totalAdUser?.response?.total_sign_in_denied_users}}</h5>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                             </div>
                         </div>
                     </div>
                      <div class="col-sm-6 col-xl-3">
                          <!-- Annual -->
                          <div class="card">
                            <app-loader *ngIf="showTotalAdUsersApiLoader"></app-loader>
                              <div class="card-body">
                                  <h6 class="card-title2 officeboard_heading mb-3"><span>Users</span> Status</h6>
                                  <h3 class="text-center text-primary">{{totalAdUser?.response?.total_users}}</h3>
                                  <div *ngIf="totalAdUser">
                                    <app-pie-chart [titleValue]="totalAdUser?.response?.total_users" [data1]="totalAdUser?.response?.total_licensed_users" [data2]="totalAdUser?.response?.total_unlicensed_users"
                                    [colors]=" ['#d2a92c', '#fee8a6']" data1Title="Licensed" data2Title="Unlicensed"></app-pie-chart>
                                                </div>
                                  <div class="text-left text-muted licenced_users_graph mt-2">
                                      <div class="row">
                                          <div class="col">
                                              <div class="mt-0">
                                                  <p class="mb-1 text-truncate"><i class="mdi mdi-circle dot_1 me-1"></i> Licensed</p>
                                                  <h5 class="ms-4">{{totalAdUser?.response?.total_licensed_users}}</h5>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="row">
                                          <div class="col">
                                              <div class="mt-2">
                                                  <p class="mb-1 text-truncate"><i class="mdi mdi-circle dot_2 me-1"></i> Unlicensed</p>
                                                  <h5 class="ms-4 mb-0">{{totalAdUser?.response?.total_unlicensed_users}}</h5>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-sm-6 col-xl-3">
                          <!-- Annual -->
                          <div class="card">
                            <app-loader *ngIf="showTotalMembersApiLoader || showMfaEnabledUsersApiLoader"></app-loader>
                              <div class="card-body">
                                  <h6 class="card-title2 officeboard_heading mb-3"><span>Status</span></h6>
                                  <h3 class="text-center text-primary">{{MFAEnabledUsers?.response?.total_users}}</h3>
                                  <div *ngIf="MFAEnabledUsers && members">
                                    <app-pie-chart [titleValue]="MFAEnabledUsers?.response?.total_users" [data1]="MFAEnabledUsers?.response?.total_mfa_registered_users" [data2]="MFAEnabledUsers?.response?.total_not_mfa_registered_users"
                                    [colors]="['#54A45E', '#B5EEBC']" data1Title="Enabled" data2Title="Disabled"></app-pie-chart>
                                  </div>
                                  <div class="text-left text-muted managers_graph mt-2">
                                      <div class="row">
                                          <div class="col">
                                              <div class="mt-0">
                                                  <p class="mb-1 text-truncate"><i class="mdi mdi-circle dot_1 me-1"></i> Enabled</p>
                                                  <h5 class="ms-4">{{MFAEnabledUsers?.response?.total_mfa_registered_users}}</h5>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="row">
                                          <div class="col">
                                              <div class="mt-2">
                                                  <p class="mb-1 text-truncate"><i class="mdi mdi-circle dot_2 me-1"></i> Disabled</p>
                                                  <h5 class="ms-4 mb-0">{{MFAEnabledUsers?.response?.total_not_mfa_registered_users}}</h5>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-4">
                  <div class="card border-0">
                    <app-loader *ngIf="showPasswordExpiresApiLoader"></app-loader>
                    <div class="card-body d-flex py-3">
                      <div class="ms-2 align-self-center width-40">
                        <i class="fas fa-times-circle fs-2 red"></i>
                      </div>
                      <div class="ms-2 align-self-center"><h5 class="card-title mb-0 "> Expired Password</h5></div>
                      <p class="ms-auto font-size-24 mb-0 fw-bold">{{passwordExpires?.response?.password_expired_users_count}}</p>
                    </div>
                  </div>
                  <div class="card border-0">
                    <app-loader *ngIf="showDeletedUsersApiLoader"></app-loader>
                    <div class="card-body d-flex py-3">
                      <div class="ms-2 align-self-center width-40">
                        <i class="fas fa-user-times fs-2 red"></i>
                      </div>
                      <div class="ms-2 align-self-center"><h5 class="card-title mb-0 "> Deleted Users</h5></div>
                      <p class="ms-auto font-size-24 mb-0 fw-bold">{{deletedUsers?.response?.total_users_deleted}}</p>
                    </div>
                  </div>
                  <div class="card border-0">
                    <app-loader *ngIf="showAdminUsersApiLoader"></app-loader>
                    <div class="card-body d-flex py-3">
                      <div class="ms-2 align-self-center width-40">
                        <i class="fas fa-lock fs-2 blue"></i>
                      </div>
                      <div class="ms-2 align-self-center"><h5 class="card-title mb-0 "> Administrators</h5></div>
                      <p class="ms-auto font-size-24 mb-0 fw-bold">{{adminUsers?.response?.total_users_with_admin_roles}}</p>
                    </div>
                  </div>
                  <div class="card border-0 mb-0">
                    <app-loader *ngIf="showTotalGroupsApiLoader"></app-loader>
                    <div class="card-body d-flex py-3">
                      <div class="ms-2 align-self-center width-40">
                        <i class="fas fa-users fs-3 group_user"></i>
                      </div>
                      <div class="ms-2 align-self-center"><h5 class="card-title mb-0 "> Groups</h5></div>
                      <p class="ms-auto font-size-24 mb-0 fw-bold">{{totalGroups?.response?.total_groups}}</p>
                    </div>
                  </div>
                </div>
          </div>
          <div class="row azure_ad_page">
              <div class="col-12">
                  <div class="card border-0">
                    <app-loader *ngIf="showTotalAdUsersApiLoader"></app-loader>
                      <div class="card-body">
                          <div class="d-flex mb-2">
                              <h5 class="card-title2 officeboard_heading mb-3"><span>Licensed</span> Users</h5>
                              <form class="table-search d-none d-lg-block ms-auto pt-0 pb-0">
                                  <div class="position-relative">
                                      <input type="text" #filter class="form-control" placeholder="Search..." />
                                      <span class="fa fa-search"></span>
                                  </div>
                              </form>
                              <!-- <div class="my-auto ms-3">
                                  <div class="btn-group" data-bs-toggle="tooltip" data-bs-placement="right" title="Download data in PDF or Excel">
                                      <a href="javascript:void(0)" type="button" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                          <i class="fas fa-cloud-download-alt fs-2"></i>
                                      </a>
                                      <ul class="dropdown-menu dropdown-menu-end min-w-auto">
                                        <li class="px-2"><a href="javascript:void(0)"><i class="mdi mdi-pdf-box display-6"></i></a></li>
                                        <li class="px-2"><a href="javascript:void(0)" ><i class="mdi mdi-microsoft-excel display-6"></i></a></li>
                                      </ul>
                                    </div>
                              </div> -->
                          </div>
                          <div class="table-responsive">
                              <table class="table table-striped table_even mb-0 table-borderless border">
                                  <thead class="table-light">
                                      <tr>
                                          <th>#</th>
                                          <th>Name</th>
                                          <th>Email</th>
                                          <th>Department</th>
                                          <th>Sign-In Status</th>
                                          <th>User Role</th>
                                          <th class="text-center">
                                              Product Licenses
                                          </th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr *ngFor="let user of totalAdUser?.response?.licensed_users_data | search: 'displayName': filter.value |  paginate: { id: 'teamsTable', itemsPerPage: 10, currentPage: pageNumber }; let i = index">
                                        <td>{{((pageNumber - 1)*10)+i+1}}</td>
                                        <td>{{user?.displayName}}</td>
                                        <td>{{user?.mail}}</td>
                                        <td>{{user?.department === null ? '--' : user.department}}</td>
                                        <td>{{user?.accountEnabled === true ? 'Allowed' : 'Denied'}}</td>
                                        <td>{{user?.jobTitle === null ? '--' : user.jobTitle}}</td>
                                        <td class="text-center">{{user?.product_licenses.length}} </td>
                                      </tr>
            </tbody>
                              </table>
                          </div>

                          <div class="d-flex">
                            <div class="pagination pagination-rounded mt-3 ms-auto">
                              <pagination-controls id="teamsTable" (pageChange)="pageNumber = $event"
                                (pageBoundsCorrection)="onPageBoundsCorrection($event)" class="">
                              </pagination-controls>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>


      </div>
  </div>
  <app-footer></app-footer>
</div>
</div>
