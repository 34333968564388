<app-header pageName="Administrators"></app-header>
<div id="layout-wrapper">
  <app-side-menu></app-side-menu>
  <app-right-nav-bar></app-right-nav-bar>
    <div class="main-content">
    <div class="page-content">
      <section class="header">
        <div class="row">
          <div class="col-sm-4">
            <app-loader *ngIf="showAdminUsersApiLoader"></app-loader>
            <div class="card border-0 w-100">
              <div class="card-body">
                <ul class="list-group p-0 border-0">
                  <li class="list-group-item d-flex justify-content-between align-items-center p-0 border-0">
                    <div class="d-flex">
                      <span class="badge badge-light badge-pill avatar-sm text-center p-2  me-2">
                        <img class="mr-1" alt="" class="align-self-center" src="assets/images/features/administrators/admin-users.svg">
                      </span>
                      <span class="align-self-center font-weight-bold">Administrators</span>
                    </div>
                    <h3 class="mb-0 font-weight-bold text-blue">{{adminUsers?.length}}</h3>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <app-loader *ngIf="showAdminUsersApiLoader"></app-loader>
            <div class="card border-0 w-100">
              <div class="card-body">
                <ul class="list-group p-0 border-0">
                  <li class="list-group-item d-flex justify-content-between align-items-center p-0 border-0">
                    <div class="d-flex">
                      <span class="badge badge-light badge-pill avatar-sm text-center p-2  me-2">
                        <img class="mr-1" alt="" class="align-self-center" src="assets/images/features/administrators/admin-type.svg">
                      </span>
                      <span class="align-self-center font-weight-bold">Administrator Types</span>
                    </div>
                    <h3 class="mb-0 font-weight-bold text-blue">{{sortedUsersArrayByAdminRoles?.length}}</h3>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <app-loader *ngIf="showAdminUsersApiLoader"></app-loader>
            <div class="card border-0 w-100">
              <div class="card-body">
                <ul class="list-group p-0 border-0">
                  <li class="list-group-item d-flex justify-content-between align-items-center p-0 border-0">
                    <div class="d-flex">
                      <span class="badge badge-light badge-pill avatar-sm text-center p-2  me-2">
                        <img class="mr-1" alt="" class="align-self-center" src="assets/images/features/administrators/admin-global-user.svg">
                      </span>
                      <span class="align-self-center font-weight-bold">Global Administrators</span>
                    </div>
                    <h3 class="mb-0 font-weight-bold text-blue">{{globalAdminUsers?.usersData?.length}}</h3>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 pe-0">
            <app-loader *ngIf="showAdminUsersApiLoader"></app-loader>
            <div class="scrollbar" id="style-3">
              <div class="force-overflow">
              <div class="nav flex-column nav-pills nav-pills-custom mb-0"  id="v-pills-tab" role="tablist"
              aria-orientation="vertical">
              <a class="nav-link mb-2 active d-flex justify-content-between align-items-center" placement="top" container="body"
              triggers="hover" [autoClose]="'outside'" (click)="enableAdminstratorsTab()"
              ngbTooltip='All Administrators and their total users count' id="v-pills-home-tab" data-bs-toggle="pill" href="#v-pills-home"
                role="tab" aria-controls="v-pills-home" aria-selected="true" (click)="pageNumber = 1">
                <div>
                  <button type="button" class="btn bg-blue rounded waves-effect waves-light"><i class="bx bx-folder-minus font-size-22"></i> </button>
                   <span class="ml-2">Administrators </span>
                </div>
                <span class="badge badge-primary badge-pill  me-2">{{adminUsers?.length}}</span>
              </a>
              <ng-container *ngFor="let item of sortedUsersArrayByAdminRoles ; let i = index">
              <a  (click)="disableAdminstratorsTab(i)"  class="nav-link mb-2 d-flex justify-content-between align-items-center" placement="top" container="body"
              triggers="hover" [autoClose]="'outside'"
              ngbTooltip='Administrator Type and its users count' id="{{i}}" data-bs-toggle="pill" href="{{i}}" aria-controls="v-pills-profile"
                role="tab" aria-selected="false" (click)="resetCurrentPageNumber()" >
                <div class="d-flex lh-1 font-size-13">
                    <button type="button" class="btn btn-info rounded waves-effect waves-light"><img class="mr-1" alt="" class="align-self-center" src="assets/images/features/administrators/admin-users.svg"></button>
                    <span class="align-self-center">{{item?.role}}</span>
                </div>
                <span class="badge badge-primary badge-pill  me-2">{{item?.usersData?.length}}</span></a>
              </ng-container>
            </div>
              </div>
            </div>
          </div>


          <div class="col-md-9">
            <app-loader *ngIf="showAdminUsersApiLoader"></app-loader>
            <div class="tab-content" id="v-pills-tabContent">
              <div class="tab-pane fade p-3 rounded bg-white" [ngClass]="isAdministratorTabActive? 'show active':''" id="v-pills-home" role="tabpanel"
                aria-labelledby="v-pills-home-tab">
                <div class="d-flex mb-2">
                  <h4 class="card-title officeboard_heading font-weight-bold my-auto mb-3">
                    <span>Administrators</span>
                  </h4>
                  <form class="app-search d-none d-lg-block ms-auto pt-0 pb-0 table-search">
                    <div class="position-relative">
                      <input type="text" class="form-control" #filterAdmins placeholder="Search User Name..." />
                      <span class="fa fa-search"></span>
                    </div>
                  </form>
                </div>
                <div class="table_scrolbar scrollbar p-0" id="style-3">
                  <div class="force-overflow">
                    <table class="table table-striped table_even mb-0 table-borderless border">
                      <thead class="table-light">
                        <tr>
                          <th>#</th>
                          <th class="w-25 sort" sortable="displayName" (sort)="onSort($event, adminUsers)">User Name</th>
                          <th class="width-225 sort" sortable="email" (sort)="onSort($event, adminUsers)">Email</th>
                          <th class="w-38 sort" sortable="roles" (sort)="onSort($event, adminUsers)">Roles</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="adminUsers?.length>0">
                         <tr *ngFor="let users of adminUsers | search: 'displayName': filterAdmins.value | paginate : { id: 'administratorTab', itemsPerPage: 7, currentPage: pageNumber, totalItems : adminUsers?.length }; let i = index">
                          <td>{{((pageNumber - 1)*7)+i+1}}</td>
                          <td>{{users?.displayName}}</td>

                          <td class="text-break"><div class="w-80 d-flex"><span class="text-truncate" placement="top" triggers="hover" [autoClose]="'outside'"  ngbTooltip='{{users?.email}}'>{{users?.email}}</span></div></td>
                          <td class="w-38">{{users?.roles}}</td>
                        </tr></ng-container>

                        <ng-template #noDataAvailable>
                          <h4 class="card-title mb-3 font-weight-bold">Licenses</h4>
                          <div class="h-100 d-flex">
                            <h5 class="top-heading-col align-self-center w-100 font-weight-semibold text-center">{{messageConstantsService?.noDataMessage}}</h5>
                          </div>
                      </ng-template>
                        </tbody>
                    </table>

                    <div class="d-flex">
                      <div class="pagination pagination-rounded mt-3 ms-auto">
                        <pagination-controls id="administratorTab" (pageChange)="pageNumber = $event" (pageBoundsCorrection)="onPageBoundsCorrection($event)" [autoHide]=" true"
                          class="">
                        </pagination-controls>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
                <div class="tab-pane fade rounded bg-white p-3" id="{{currentlyActiveTabId}}" role="tabpanel" aria-labelledby="v-pills-profile" [ngClass]="isCommonTableActive?'show active': ''">
                <div class="d-flex mb-2">
                  <h4 class="card-title text-blue officeboard_heading font-weight-bold my-auto mb-3">
                    <span>{{sortedUsersArrayByAdminRoles[currentlyActiveTabId]?.role}}</span>
                  </h4>
                  <form class="app-search d-none d-lg-block ms-auto pt-0 pb-0 table-search">
                    <div class="position-relative">
                      <input type="text" class="form-control" #filter placeholder="Search User Name..." />
                      <span class="fa fa-search"></span>
                    </div>
                  </form>
                </div>
                <div class="table_scrolbar scrollbar p-0" id="style-3">
                  <div class="force-overflow">
                    <app-loader *ngIf="showTableApiLoader"></app-loader>
                <table class="table dt-responsive nowrap w-100 azure_ad_table table_collapsible officeboard_table">
                  <thead class="table-light">
                    <tr>
                      <th>#</th>
                      <th #removeSortClass class="w-25 sort" sortable="displayName" (sort)="onSort($event, sortedUsersArrayByAdminRoles[currentlyActiveTabId]?.usersData)" >User Name</th>
                      <th #removeSortClass class="width-225 sort" sortable="email" (sort)="onSort($event, sortedUsersArrayByAdminRoles[currentlyActiveTabId]?.usersData)">Email</th>
                      <th #removeSortClass class="w-38 sort" sortable="roles" (sort)="onSort($event, sortedUsersArrayByAdminRoles[currentlyActiveTabId]?.usersData)">Roles</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="sortedUsersArrayByAdminRoles[currentlyActiveTabId]?.usersData?.length>0 ; else noDataAvailable" >
                      <tr *ngFor="let users of sortedUsersArrayByAdminRoles[currentlyActiveTabId]?.usersData | search: 'displayName': filter.value | paginate : { id: 'tab', itemsPerPage: 5, currentPage: pageNoArray[currentlyActiveTabId][pageNumber-1] }; let i = index">
                        <td>{{((this.pageNoArray[this.currentlyActiveTabId][this.pageNumber-1] - 1)*5)+i+1}}</td>
                        <td>{{users?.displayName}}</td>
                        <td class="text-break"><div class="w-80 d-flex"><span class="text-truncate" placement="top" triggers="hover" [autoClose]="'outside'"  ngbTooltip='{{users?.email}}'>{{users?.email}}</span></div></td>
                        <td>{{users?.roles}}</td>
                      </tr>
                    </ng-container>
                    <ng-template #noDataAvailable>
                      <h4 class="card-title mb-3 font-weight-bold">Licenses</h4>
                      <div class="h-100 d-flex">
                        <h5 class="top-heading-col align-self-center w-100 font-weight-semibold text-center">{{messageConstantsService?.noDataMessage}}</h5>
                      </div>
                  </ng-template>
                  </tbody>
                </table>
                <div class="d-flex">
                  <div class="pagination pagination-rounded ms-auto">
                    <pagination-controls [id]="'tab'" (pageChange)='onPageChange($event)' (pageBoundsCorrection)="onPageBoundsCorrection($event)" [autoHide]="true"
                      class="">
                    </pagination-controls>
                  </div>
                </div>
              </div>
              </div>
            </div>
              </div>
          </div>
        </div>
      </section>
    </div>
    <app-footer></app-footer>
  </div>
  </div>
