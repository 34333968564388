<div class="authentication-bg">
  <div class="login-wrap">
    <div class="container">
      <div class="row justify-content-center ">
        <div class="col-xl-5">
          <div class="card overflow-hidden">
            <div class="login-box">
              <div class="row align-items-center">
                <div class="col-md-12">
                  <div class="card-body bg-white py-5">
                    <div class=" text-center pt-5">
                      <img src="assets/images/global/svg/logo-light-login.svg" width="250" alt="officeboard logo">
                      <div class="mt-5 text-center">
                        <h4 >Create Your Organization...</h4>
                        <p><i class="fa fa-info-circle text-primary"></i> {{messageConstantsService.InfoMsgCreateOrgPage}}</p>
                      </div>
                    </div>
                    <div class="p-2 pb-5">
                      <form class="form-horizontal custom-validation" (submit)="onSubmit()" [formGroup]="createOrgForm">

                      <div class="mb-3 position-relative">
                        <input type="text" class="form-control" id="username" formControlName="orgName" required
                          placeholder="Enter your organization name">
                        <span class="symbol-input"><i class="mdi mdi-office-building"></i></span>
                      </div>
                       <div class="mt-3 d-grid">
                        <button class="btn btn-primary waves-effect waves-light" type="submit" [disabled]="!createOrgForm.valid">
                          <span *ngIf="showLoader" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                          Create</button>
                      </div>
                      <div class="mt-4 text-center">
                        <ul class="list-inline mb-0">
                          <li class="list-inline-item">
                            <a target="_blank" routerLink="/terms&conditions">Terms of use</a>
                          </li>
                          <li class="list-inline-item">|</li>
                          <li class="list-inline-item">
                            <a target="_blank" routerLink="/privacy-policy">
                              Privacy policy
                            </a>
                          </li>
                        </ul>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>