<div id="layout-wrapper">
  <app-header pageName="Executive Summary"></app-header>
  <app-right-nav-bar></app-right-nav-bar>
  <app-side-menu></app-side-menu>
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-6">
            <div class="card border shadow-sm">
              <app-loader *ngIf="showGlobalAdminsApiLoader || showSecurityAlertsApiLoader || showMfaUsersStatusApiLoader || showPasswordExpiresApiLoader || showMailBoxUsageApiLoader">
              </app-loader>
              <div class="card-body">
                <div class="d-sm-flex flex-wrap">
                  <h4 class="card-title mb-1 font-weight-bold">Synopsis</h4>
                </div>
                <!-- products home  -->
                <ul class="list-inline synopsis-text mb-0">
                  <!-- If global Admins are already 3 then we will hide this advice. -->
                  <li *ngIf="globalAdminsData?.total_users!=3" class="list-inline-item list-inline-item d-inline-block text-truncate w-100">
                    <span class="d-inline-block me-2">1</span> <b>{{globalAdminsData?.total_users??"N/A"}}</b> Global
                    admins should be changed to <b>3</b> as
                    per Microsoft best practices.
                    <!-- If global Admins are already 3 then we will hide 1st point and adjust serial numbers accordingly. -->
                  </li>
                  <li class="list-inline-item"><span class="d-inline-block me-2">{{globalAdminsData?.total_users==3?1:2}}</span>MFA for
                    <b>{{mfaUsersStatus?.total_admins_without_mfa ?? "N/A"}}</b> global admins
                    should be enforced at the earliest.</li>
                  <li class="list-inline-item"><span
                      class="d-inline-block me-2">{{globalAdminsData?.total_users==3?2:3}}</span><b>{{(securityAlerts?.status_count?.newAlert ?? 0 ) +
                      (securityAlerts?.status_count?.inProgress ?? 0)}}</b> open severity alerts
                    should be reviewed and resolved.</li>
                  <li class="list-inline-item"><span class="d-inline-block me-2">{{globalAdminsData?.total_users==3?3:4}}</span>MFA should be enabled for
                    <b>{{mfaUsersStatus?.total_not_mfa_registered_users??"N/A"}}</b> users.
                  </li>
                  <li class="list-inline-item"><span
                      class="d-inline-block me-2">{{globalAdminsData?.total_users==3?4:5}}</span><b>{{passwordExpires?.password_expired_users_count ??
                      "N/A"}}</b> users with expired
                    password should be reviewed.</li>
                  <li class="list-inline-item"><span
                      class="d-inline-block me-2">{{globalAdminsData?.total_users==3?5:6}}</span><b>{{mailBoxUsageDetails?.Never_login_user_count ??
                      "N/A"}}</b> mailboxes with "Never
                    Logged In" status should be reviewed.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-xl-6 d-flex">
            <div class="card border shadow-sm w-100">
              <app-loader *ngIf="showLicencesApiLoader"></app-loader>
              <div class="card-body">
                <ng-container *ngIf="licencesData?.licenses_assigned_data?.length>0 ; else noDataAvailable ">
                  <h4 class="card-title mb-3 font-weight-bold">Licenses</h4>
                  <!-- products home  -->
                  <div class="table-responsive executive_licenses">
                    <table class="table table-striped mb-0 table-borderless">
                      <thead class="border-0">
                        <tr>
                          <th>License</th>
                          <th>Assigned</th>
                          <th>Activated</th>
                          <th>Shared</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let licences of licencesData?.licenses_assigned_data | paginate: { id: 'licensesTable', itemsPerPage: 5, currentPage: pageNumber, totalItems : licencesData?.licenses_assigned_data?.length }; let i = index">
                          <td><i class="mdi mdi-square font-size-18 me-1"
                              [ngClass]="iconsColors[i]"></i>{{functionsService?.capitalizeEachWord(licences?.Product_Type ?? '')}}</td>
                          <td><b>{{licences?.Assigned}}</b></td>
                          <td><b>{{licences?.Activated}}</b></td>
                          <td><b>{{licences?.Shared_Computer_Activation}}</b></td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex">
                      <div class="pagination pagination-rounded mt-3 ms-auto">
                        <pagination-controls id="licensesTable" (pageChange)="pageNumber = $event"
                          (pageBoundsCorrection)="onPageBoundsCorrection($event)" [autoHide]="true">
                        </pagination-controls>
                      </div>
                    </div>
                  </div>
                </ng-container>

              </div>
              <ng-template #noDataAvailable>
                  <h4 class="card-title mb-3 font-weight-bold">Licenses</h4>
                  <div class="h-100 d-flex">
                    <h5 class="top-heading-col align-self-center w-100 font-weight-semibold text-center">{{messageConstantsService?.noDataMessage}}</h5>
                  </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title mb-3 font-weight-bold">Account Snapshot</h4>
                <app-loader
                  *ngIf="showSecurityAlertsApiLoader || showSecurityScoreApiLoader || showTeamsCallsApiLoader || showTeamsActivityApiLoader || showMailBoxDataApiLoader">
                </app-loader>
                <div class="row">
                  <div class="col-sm-4 vertical_line">
                    <div class="table-responsive executive_account_snapshot">
                      <table class="table mb-0 table-borderless">
                        <thead class="border-0">
                          <tr>
                            <th>Particulars</th>
                            <th class="text-center">30 Days</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div class="d-flex">
                                <img width="16" class="me-1" alt=""
                                  src="assets/images/features/executive-summary/globe-error.svg">
                                <span class="d-inline-block">Open Security Alerts</span>
                              </div>
                            </td>
                            <td class="text-center"><b>{{openAlertsData30Days?.length}}</b></td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex">
                                <img width="16" class="me-1" alt=""
                                  src="assets/images/features/executive-summary/globe-success.svg">
                                <span class="d-inline-block">Closed Security Alerts</span>
                              </div>
                            </td>
                            <td class="text-center"><b>{{closedAlertsData30Days?.length}}</b></td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex">
                                <img width="16" class="me-1" alt=""
                                  src="assets/images/features/executive-summary/secure-score.svg">
                                <span class="d-inline-block">Secure Score (%)</span>
                              </div>
                            </td>
                            <td class="text-center">
                              <b>{{functionsService.getRoundOffData(functionsService.setPercentage(secureScores?.thirty_days_current_score,secureScores?.thirty_days_max_Score))
                                || "0"}}</b></td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex">
                                <img width="16" class="me-1" alt=""
                                  src="assets/images/features/executive-summary/alerts.svg">
                                <span class="d-inline-block">Security Alerts</span>
                              </div>
                            </td>
                            <td class="text-center"><b>{{securityAlerts?.thirty_days_alert_count ?? "N/A"}}</b></td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex">
                                <img width="16" class="me-1" alt=""
                                  src="assets/images/features/executive-summary/traffic-manager-profiles.svg">
                                <span class="d-inline-block">Team Calls - PSTN</span>
                              </div>
                            </td>
                            <td class="text-center"><b>{{teamsCallsData?.total_pstn_calls_thirty_days ?? "N/A"}}</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex">
                                <img width="16" class="me-1" alt=""
                                  src="assets/images/features/executive-summary/management-groups.svg">
                                <span class="d-inline-block">Team Calls - Direct Routing</span>
                              </div>
                            </td>
                            <td>
                              <div class="d-flex justify-content-center">
                                <b class=>{{teamsCallsData?.total_direct_routing_calls_thirty_days ??
                                  "N/A"}}</b>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="col-sm-8">
                    <div class="executive_account_snapshot">
                      <table class="table mb-0 table-borderless">
                        <thead class="border-0">
                          <tr>
                            <th width="25">Particulars</th>
                            <th class="text-center">30 Days</th>
                            <th class="text-center">90 Days</th>
                            <th class="text-center">180 Days</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div class="d-flex">
                                <img width="18" class="me-1" alt=""
                                  src="assets/images/features/executive-summary/meeting-organized.svg">
                                <span class="d-inline-block text-truncate">Meetings Organized</span>
                              </div>
                            </td>
                            <td class="text-center">
                              <b>{{teamsMeetingsData?.sum_thirty_days_data?.Meetings_Organized_Count ?? "N/A"}}</b></td>
                            <td>
                              <div class="row">
                                <div class="col-6 text-end pe-0">
                                  <b>{{teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count ?? "N/A"}}</b>
                                </div>
                                <div class="col-6 text-strat ps-0">
                                  <span class="font-weight-bold font-size-12"
                                  [ngClass]="(teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count===teamsMeetingsData?.sum_thirty_days_data?.Meetings_Organized_Count)?'text-blue': teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count>teamsMeetingsData?.sum_thirty_days_data?.Meetings_Organized_Count?'text-green':'text-danger'"
                                  *ngIf="teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count && teamsMeetingsData?.sum_thirty_days_data?.Meetings_Organized_Count">
                                  <i class="mdi font-size-13"
                                    [ngClass]="(teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count===teamsMeetingsData?.sum_thirty_days_data?.Meetings_Organized_Count)?'mdi-arrow-up-down':teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count>teamsMeetingsData?.sum_thirty_days_data?.Meetings_Organized_Count?'mdi-arrow-up':'mdi-arrow-down'"></i>
                                  <i class="mdi" [ngClass]="(teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count===teamsMeetingsData?.sum_thirty_days_data?.Meetings_Organized_Count)?'':teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count>teamsMeetingsData?.sum_thirty_days_data?.Meetings_Organized_Count?'mdi-plus':' mdi-minus'"></i>
                                  {{functionsService?.getRoundOffData(
                                    functionsService?.setPercentage(functionsService?.getAbsoluteValue(teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count-teamsMeetingsData?.sum_thirty_days_data?.Meetings_Organized_Count) ?? 0
                                  ,teamsMeetingsData?.sum_thirty_days_data?.Meetings_Organized_Count))}}%
                                </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-6 text-end pe-0">
                                  <b>{{teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Organized_Count ??
                                    "N/A"}}</b>
                                </div>
                                <div class="col-6 text-start ps-0">
                                  <span class="font-weight-bold font-size-12" [ngClass]="(teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Organized_Count===teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count)?'text-blue':teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Organized_Count>teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count?'text-green':'text-danger'"
                                    *ngIf="teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count && teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Organized_Count">
                                    <i class="mdi font-size-13" [ngClass]="(teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Organized_Count===teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count)?'mdi-arrow-up-down':teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Organized_Count>teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count?'mdi-arrow-up':'mdi-arrow-down'"></i>
                                    <i class="mdi" [ngClass]="(teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Organized_Count===teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count)?'':teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Organized_Count>teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count?'mdi-plus':' mdi-minus'"></i>
                                    {{functionsService?.getRoundOffData(functionsService?.setPercentage(functionsService?.getAbsoluteValue(teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Organized_Count-teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count) ?? 0,teamsMeetingsData?.sum_ninety_days_data?.Meetings_Organized_Count))}}
                                    %</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex">
                                <img width="18" class="me-1" alt=""
                                  src="assets/images/features/executive-summary/meetings-attended.svg">
                                <span class="d-inline-block text-truncate">Meetings Attended</span>
                              </div>
                            </td>
                            <td class="text-center">
                              <b>{{teamsMeetingsData?.sum_thirty_days_data?.Meetings_Attended_Count ?? "N/A"}}</b></td>
                            <td>
                              <div class="row">
                                <div class="col-6 text-end pe-0">
                                  <b>{{teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count ?? "N/A"}}</b>
                                </div>
                                <div class="col-6 text-start ps-0">
                                  <span
                                *ngIf="teamsMeetingsData?.sum_thirty_days_data?.Meetings_Attended_Count && teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count"
                                class=" font-weight-bold font-size-12"
                                [ngClass]="(teamsMeetingsData?.sum_thirty_days_data?.Meetings_Attended_Count===teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count)?'text-blue':teamsMeetingsData?.sum_thirty_days_data?.Meetings_Attended_Count<teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count?'text-green':'text-danger'">
                                <i class="mdi font-size-13"
                                  [ngClass]="(teamsMeetingsData?.sum_thirty_days_data?.Meetings_Attended_Count===teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count)?'mdi-arrow-up-down':teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count>teamsMeetingsData?.sum_thirty_days_data?.Meetings_Attended_Count?'mdi-arrow-up':'mdi-arrow-down'"></i>
                                <i class="mdi" [ngClass]="(teamsMeetingsData?.sum_thirty_days_data?.Meetings_Attended_Count===teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count)?'':teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count>teamsMeetingsData?.sum_thirty_days_data?.Meetings_Attended_Count?'mdi-plus':' mdi-minus'"></i>
                                {{functionsService?.getRoundOffData(functionsService?.setPercentage(functionsService?.getAbsoluteValue(teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count-teamsMeetingsData?.sum_thirty_days_data?.Meetings_Attended_Count) ?? 0,teamsMeetingsData?.sum_thirty_days_data?.Meetings_Attended_Count))}}
                                %</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-6 text-end pe-0">
                                  <b>{{teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Attended_Count ??
                                    "N/A"}}</b>
                                </div>
                                <div class="col-6 text-start ps-0">
                                  <span class="font-weight-bold font-size-12"
                                [ngClass]="(teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Attended_Count===teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count)?'text-blue':teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Attended_Count>teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count?'text-green':'text-danger'"
                                *ngIf="teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Attended_Count && teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count">
                                <i class="mdi font-size-13"
                                  [ngClass]="(teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Attended_Count===teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count)?'mdi-arrow-up-down':teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Attended_Count>teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count?'mdi-arrow-up':'mdi-arrow-down'">
                                </i>
                                <i class="mdi" [ngClass]="(teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Attended_Count===teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count)?'':teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Attended_Count>teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count?'mdi-plus':' mdi-minus'"></i>
                                {{functionsService?.getRoundOffData(functionsService?.setPercentage(functionsService?.getAbsoluteValue(teamsMeetingsData?.sum_one_hundred_eighty_days_data?.Meetings_Attended_Count-teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count) ?? 0,teamsMeetingsData?.sum_ninety_days_data?.Meetings_Attended_Count))}}%
                              </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex">
                                <img width="19" class="me-1" alt=""
                                  src="assets/images/features/executive-summary/active-mailboxes.svg">
                                <span class="d-inline-block text-truncate">Active Mailboxes</span>
                              </div>
                            </td>
                            <td class="text-center"><b>{{mailBoxData?.total_active_mailboxes_thirty_days ?? "N/A"}}</b>
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-6 text-end pe-0">
                                  <b>{{mailBoxData?.total_active_mailboxes_ninety_days ?? "N/A"}}</b>
                                </div>
                                <div class="col-6 text-start ps-0">
                                  <span *ngIf="mailBoxData?.total_active_mailboxes_ninety_days  && mailBoxData?.total_active_mailboxes_thirty_days " class="font-weight-bold font-size-12"
                               [ngClass]="(mailBoxData?.total_active_mailboxes_ninety_days===mailBoxData?.total_active_mailboxes_thirty_days)?'text-blue':mailBoxData?.total_active_mailboxes_ninety_days>mailBoxData?.total_active_mailboxes_thirty_days?'text-green':'text-danger'"><i class="mdi font-size-13"
                                  [ngClass]="(mailBoxData?.total_active_mailboxes_ninety_days===mailBoxData?.total_active_mailboxes_thirty_days)?'mdi-arrow-up-down':mailBoxData?.total_active_mailboxes_ninety_days>mailBoxData?.total_active_mailboxes_thirty_days?'mdi-arrow-up':'mdi-arrow-down'"></i>
                                <i
                                  class="mdi" [ngClass]="(mailBoxData?.total_active_mailboxes_ninety_days===mailBoxData?.total_active_mailboxes_thirty_days)?'':mailBoxData?.total_active_mailboxes_ninety_days>mailBoxData?.total_active_mailboxes_thirty_days?'mdi-plus':' mdi-minus'"></i>{{functionsService?.getRoundOffData(functionsService?.setPercentage(functionsService?.getAbsoluteValue(mailBoxData?.total_active_mailboxes_ninety_days-mailBoxData?.total_active_mailboxes_thirty_days) ?? 0,mailBoxData?.total_active_mailboxes_thirty_days))}}
                                %</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-6 text-end pe-0">
                                  <b>{{mailBoxData?.total_active_mailboxes_180_days ?? "N/A"}}</b>
                                </div>
                                <div class="col-6 text-start ps-0">
                                  <span *ngIf="mailBoxData?.total_active_mailboxes_ninety_days  && mailBoxData?.total_active_mailboxes_180_days " class="font-weight-bold font-size-12"
                              [ngClass]="(mailBoxData?.total_active_mailboxes_180_days===mailBoxData?.total_active_mailboxes_ninety_days)?'text-blue':mailBoxData?.total_active_mailboxes_180_days>mailBoxData?.total_active_mailboxes_ninety_days?'text-green':'text-danger'"><i class="mdi font-size-13"
                                  [ngClass]="(mailBoxData?.total_active_mailboxes_180_days===mailBoxData?.total_active_mailboxes_ninety_days)?'mdi-arrow-up-down':mailBoxData?.total_active_mailboxes_180_days>mailBoxData?.total_active_mailboxes_ninety_days?'mdi-arrow-up':'mdi-arrow-down'"></i>
                                <i
                                  class="mdi" [ngClass]="(mailBoxData?.total_active_mailboxes_180_days===mailBoxData?.total_active_mailboxes_ninety_days)?'':mailBoxData?.total_active_mailboxes_180_days>mailBoxData?.total_active_mailboxes_ninety_days?'mdi-plus':' mdi-minus'"></i>{{functionsService?.getRoundOffData(functionsService?.setPercentage(functionsService?.getAbsoluteValue(mailBoxData?.total_active_mailboxes_180_days-mailBoxData?.total_active_mailboxes_ninety_days) ?? 0,mailBoxData?.total_active_mailboxes_ninety_days))}}
                                %</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex">
                                <img width="18" class="me-1" alt=""
                                  src="assets/images/features/executive-summary/inactive-mailboxes.svg">
                                <span class="d-inline-block text-truncate">Inactive Mailboxes</span>
                              </div>
                            </td>
                            <td class="text-center"><b>{{mailBoxData?.total_inactive_mailboxes_thirty_days ??
                                "N/A"}}</b></td>
                            <td>
                              <div class="row">
                                <div class="col-6 text-end pe-0">
                                  <b>{{mailBoxData?.total_inactive_mailboxes_ninety_days ?? "N/A"}}</b>
                                </div>
                                <div class="col-6 text-start ps-0">
                                  <span *ngIf="mailBoxData?.total_inactive_mailboxes_ninety_days && mailBoxData?.total_inactive_mailboxes_thirty_days " class=" font-weight-bold font-size-12"
                                [ngClass]="(mailBoxData?.total_inactive_mailboxes_ninety_daysmailBoxData?.total_inactive_mailboxes_thirty_days)?'text-blue':mailBoxData?.total_inactive_mailboxes_ninety_days>mailBoxData?.total_inactive_mailboxes_thirty_days?'text-green':'text-danger'">
                                  <i
                                  class="mdi font-size-13" [ngClass]="(mailBoxData?.total_inactive_mailboxes_ninety_days===mailBoxData?.total_inactive_mailboxes_thirty_days)?'mdi-arrow-up-down':mailBoxData?.total_inactive_mailboxes_ninety_days>mailBoxData?.total_inactive_mailboxes_thirty_days?'mdi-arrow-up':'mdi-arrow-down'"></i>
                                  <i class="mdi" [ngClass]="(mailBoxData?.total_inactive_mailboxes_ninety_days===mailBoxData?.total_inactive_mailboxes_thirty_days)?'':mailBoxData?.total_inactive_mailboxes_ninety_days>mailBoxData?.total_inactive_mailboxes_thirty_days?'mdi-plus':' mdi-minus'"></i>
                                  {{functionsService?.getRoundOffData(functionsService?.setPercentage(functionsService?.getAbsoluteValue(mailBoxData?.total_inactive_mailboxes_ninety_days-mailBoxData?.total_inactive_mailboxes_thirty_days) ?? 0,mailBoxData?.total_inactive_mailboxes_thirty_days))}}
                                %</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-6 text-end pe-0">
                                  <b>{{mailBoxData?.total_inactive_mailboxes_180_days ?? "N/A"}}</b>
                                </div>
                                <div class="col-6 text-start ps-0">
                                  <span *ngIf="mailBoxData?.total_inactive_mailboxes_ninety_days && mailBoxData?.total_inactive_mailboxes_180_days"  class=" font-weight-bold font-size-12"
                                [ngClass]="(mailBoxData?.total_inactive_mailboxes_ninety_days === mailBoxData?.total_inactive_mailboxes_180_days)?'text-blue':mailBoxData?.total_inactive_mailboxes_180_days>mailBoxData?.total_inactive_mailboxes_ninety_days?'text-green':'text-danger'"><i
                                  class="mdi font-size-13" [ngClass]="(mailBoxData?.total_inactive_mailboxes_ninety_days === mailBoxData?.total_inactive_mailboxes_180_days)?'mdi-arrow-up-down':mailBoxData?.total_inactive_mailboxes_180_days>mailBoxData?.total_inactive_mailboxes_ninety_days?'mdi-arrow-up':'mdi-arrow-down'"></i>
                                  <i class="mdi" [ngClass]="(mailBoxData?.total_inactive_mailboxes_ninety_days === mailBoxData?.total_inactive_mailboxes_180_days)?'':mailBoxData?.total_inactive_mailboxes_180_days>mailBoxData?.total_inactive_mailboxes_ninety_days?'mdi-plus':' mdi-minus'"></i>
                                  {{functionsService?.getRoundOffData(functionsService?.setPercentage(functionsService?.getAbsoluteValue(mailBoxData?.total_inactive_mailboxes_180_days-mailBoxData?.total_inactive_mailboxes_ninety_days) ?? 0,mailBoxData?.total_inactive_mailboxes_ninety_days))}}
                                %</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex">
                                <img width="18" class="me-1" alt=""
                                  src="assets/images/features/executive-summary/user-subscriptions.svg">
                                <span class="d-inline-block text-truncate">Licensed User Count</span>
                              </div>
                            </td>
                            <td class="text-center"><b>{{secureScores?.thirty_days_licensed_user_count ?? "N/A"}}</b>
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-6 text-end pe-0">
                                  <b>{{secureScores?.ninety_days_licensed_user_count ?? "N/A"}}</b>
                                </div>
                                <div class="col-6 ps-0">
                                  <span *ngIf="secureScores?.thirty_days_licensed_user_count && secureScores?.ninety_days_licensed_user_count" class="font-weight-bold font-size-12"
                                [ngClass]="(secureScores?.ninety_days_licensed_user_count===secureScores?.thirty_days_licensed_user_count)?'text-blue':secureScores?.ninety_days_licensed_user_count>secureScores?.thirty_days_licensed_user_count?'text-green':'text-danger'"><i
                                  class="mdi font-size-13" [ngClass]="(secureScores?.ninety_days_licensed_user_count===secureScores?.thirty_days_licensed_user_count)?'mdi-arrow-up-down':secureScores?.ninety_days_licensed_user_count>secureScores?.thirty_days_licensed_user_count?'mdi-arrow-up':'mdi-arrow-down'"></i>
                                   <i class="mdi" [ngClass]="(secureScores?.ninety_days_licensed_user_count===secureScores?.thirty_days_licensed_user_count)?' ':secureScores?.ninety_days_licensed_user_count>secureScores?.thirty_days_licensed_user_count?'mdi-plus':' mdi-minus'"></i>
                                   {{functionsService?.getRoundOffData(functionsService?.setPercentage(functionsService?.getAbsoluteValue(secureScores?.ninety_days_licensed_user_count-secureScores?.thirty_days_licensed_user_count) ?? 0,secureScores?.thirty_days_licensed_user_count))}}
                                 % </span>
                                </div>
                              </div>
                             </td>
                            <td>
                              <div class="row">
                                <div class="col-6 text-end pe-0">
                                  <b>{{functionsService.getRoundOffData(licensedUserCount180Days)}}</b>
                                </div>
                                <div class="col-6 text-start ps-0">
                                  <span *ngIf="licensedUserCount180Days && secureScores?.ninety_days_licensed_user_count"
                                class=" font-weight-bold font-size-12" [ngClass]="(licensedUserCount180Days===secureScores?.ninety_days_licensed_user_count)?'text-blue':licensedUserCount180Days>secureScores?.ninety_days_licensed_user_count?'text-green':'text-danger'"><i
                                  class="mdi font-size-13" [ngClass]="(licensedUserCount180Days===secureScores?.ninety_days_licensed_user_count)?'mdi-arrow-up-down':licensedUserCount180Days>secureScores?.ninety_days_licensed_user_count?'mdi-arrow-up':'mdi-arrow-down'"></i>
                                  <i class="mdi" [ngClass]="(licensedUserCount180Days===secureScores?.ninety_days_licensed_user_count)?' ':licensedUserCount180Days>secureScores?.ninety_days_licensed_user_count?'mdi-plus':' mdi-minus'"></i>
                                  {{functionsService?.getRoundOffData(functionsService?.setPercentage(functionsService?.getAbsoluteValue(licensedUserCount180Days-secureScores?.ninety_days_licensed_user_count) ?? 0,secureScores?.ninety_days_licensed_user_count))}}
                               % </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex">
                                <img width="18" class="me-1" alt=""
                                  src="assets/images/features/executive-summary/active-user-count.svg">
                                <span class="d-inline-block text-truncate">Active User Count</span>
                              </div>
                            </td>
                            <td class="text-center"><b>{{secureScores?.thirty_days_active_user_count ?? "N/A"}}</b></td>
                            <td>
                              <div class="row">
                                <div class="col-6 text-end pe-0">
                                  <b>{{secureScores?.ninety_days_active_user_count ?? "N/A"}}</b>
                                </div>
                                <div class="col-6 text-start ps-0">
                                  <span  *ngIf="secureScores?.thirty_days_active_user_count && secureScores?.ninety_days_active_user_count"
                                class=" font-weight-bold font-size-12" [ngClass]="(secureScores?.ninety_days_active_user_count===secureScores?.thirty_days_active_user_count)?'text-blue':secureScores?.ninety_days_active_user_count>secureScores?.thirty_days_active_user_count?'text-green':'text-danger'"><i
                                  class="mdi font-size-13" [ngClass]="(secureScores?.ninety_days_active_user_count===secureScores?.thirty_days_active_user_count)?'mdi-arrow-up-down':secureScores?.ninety_days_active_user_count>secureScores?.thirty_days_active_user_count?'mdi-arrow-up':'mdi-arrow-down'"></i>
                                  <i class="mdi" [ngClass]="(secureScores?.ninety_days_active_user_count===secureScores?.thirty_days_active_user_count)?' ':secureScores?.ninety_days_active_user_count>secureScores?.thirty_days_active_user_count?'mdi-plus':' mdi-minus'"></i>
                                  {{functionsService?.getRoundOffData(functionsService?.setPercentage(functionsService?.getAbsoluteValue(secureScores?.ninety_days_active_user_count-secureScores?.thirty_days_active_user_count) ?? 0,secureScores?.thirty_days_active_user_count))}}
                               % </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-6 text-end pe-0">
                                  <b>{{functionsService.getRoundOffData(activeUserCount180Days)}}</b>
                                </div>
                                <div class="col-6 text-start ps-0">
                                  <span *ngIf="activeUserCount180Days && secureScores?.ninety_days_active_user_count"
                                class=" font-weight-bold font-size-12" [ngClass]="(activeUserCount180Days===secureScores?.ninety_days_active_user_count)?'text-blue':activeUserCount180Days>secureScores?.ninety_days_active_user_count?'text-green':'text-danger'"><i
                                  class="mdi font-size-13" [ngClass]="(activeUserCount180Days===secureScores?.ninety_days_active_user_count)?'mdi-arrow-up-down':activeUserCount180Days>secureScores?.ninety_days_active_user_count?'mdi-arrow-up':'mdi-arrow-down'"></i>
                                  <i class="mdi" [ngClass]="(activeUserCount180Days===secureScores?.ninety_days_active_user_count)?'':activeUserCount180Days>secureScores?.ninety_days_active_user_count?' mdi-plus':' mdi-minus'"></i>
                                  {{functionsService?.getRoundOffData(functionsService?.setPercentage(functionsService?.getAbsoluteValue(activeUserCount180Days-secureScores?.ninety_days_active_user_count) ?? 0,secureScores?.ninety_days_active_user_count))}}
                               % </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</div>
