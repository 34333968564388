<div class="page-content">
    <div class="content-wrapper">
        <div class="content d-flex justify-content-center align-items-center">
            <div class="flex-fill">
                <div class="text-center mb-3">
                    <h1 class="error-title">404</h1>
                    <h5>Oops, an error has occurred. Page not found!</h5>
                    <div class="row">
                        <div class="col-xl-4 offset-xl-4 col-md-8 offset-md-2">
                            <div class="row">
                                <div class="col-sm-12">
                                    <a href="javascript:void(0)" (click)="backClicked()"
                                        class="btn btn-primary btn-block"><i class="icon-backward2  mr-2"></i> Go
                                        Back</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>