import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ConstantsService } from '../../shared/services/config/constants.service';
import { FunctionsService } from '../../shared/utilities/functions.service';

@Injectable({
  providedIn: 'root'
})
export class SharepointService {

  private org_id = `${this.cookie.get('_tnt_org_id')}`;
  private tnt_id = `${this.cookie.get('_tnt_id')}`;

  constructor(private http: HttpClient, private cookie: CookieService, private constantsService : ConstantsService, private functionsService : FunctionsService) { }

  private baseUrlSharePoint = `${this.constantsService.sharepointPageApiUrl}`;

  private apiUrl_activeSharepoint = this.baseUrlSharePoint + "activesites?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_totalSharepoint = this.baseUrlSharePoint + "totallists?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_sharepointSitesinfo = this.baseUrlSharePoint + "sitesinfo?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_eachSiteDetails   = this.baseUrlSharePoint + "sitesactivity?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;

  getActiveSharepoint() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_activeSharepoint,options);
  }

  getTotalSharepoint() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_totalSharepoint,options)
  }

  getSharepointSitesinfo() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_sharepointSitesinfo,options)
  }

  getEachSiteDetails() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_eachSiteDetails,options)
  }
}
