import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ConstantsService } from '../../shared/services/config/constants.service';
import { FunctionsService } from '../../shared/utilities/functions.service';

@Injectable({
  providedIn: 'root'
})
export class ExchangeService {

  constructor(private http: HttpClient, private cookie: CookieService, private constantsService : ConstantsService, private functionsService : FunctionsService) { }

  private org_id = `${this.cookie.get('_tnt_org_id')}`;
  private tnt_id = `${this.cookie.get('_tnt_id')}`;

  private baseUrlExchange = `${this.constantsService.exchangeApiUrl}`;

  private apiUrl_mailboxDetails = this.baseUrlExchange + "mailboxdetails?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_mailboxData = this.baseUrlExchange +"mailbox?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_mailtraffic = this.baseUrlExchange +"mailtraffic?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_mailBoxUsageQuota = this.baseUrlExchange + "MailboxUsageQuota?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_mailsActivity = this.baseUrlExchange + "mailsactivity?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_mailBoxUsageDetails = this.baseUrlExchange+"MailboxUsageDetails?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_appUsagedetails = this.baseUrlExchange + "appusagedetails?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_appVersionUsagedetails = this.baseUrlExchange + "appusageversioncounts?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_mailBoxUsageCounts = this.baseUrlExchange + "MailboxUsageMailboxCounts?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;

  getMailBoxDetails(){
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_mailboxDetails,options);
  }
  getMailBoxData(){
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_mailboxData,options);
  }
  getMailBoxTraffic(){
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_mailtraffic,options);
  }
  getMailBoxQuotaStatus(){
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_mailBoxUsageQuota,options);
  }
  getMailsActivityData(){
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_mailsActivity,options);
  }
  getMailBoxUsageDetails(){
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_mailBoxUsageDetails,options);
  }
  getEmailAppUsageDetails(){
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_appUsagedetails,options);
  }
  getEmailAppVersionDetails(){
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_appVersionUsagedetails,options);
  }
  getMailBoxUsageCounts(){
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_mailBoxUsageCounts,options);
  }
}
