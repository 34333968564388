import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  constructor() { }

  // Core Components APIs URLs
  loginApiUrl = `${environment.apiHostName}obsa/login/`;
  signupApiUrl = `${environment.apiHostName}obsa/signup/`;
  confirmUserApiUrl = `${environment.apiHostName}obsa/verify_otp/`;
  createorganizationApiUrl = `${environment.apiHostName}obsa/add_new_org/`;
  subscriptionsListApiUrl = `${environment.apiHostName}obsa/active_subs_by_user_id/`;

  // Features Components APIs URLs
  teamsPageApiUrl = `${environment.apiHostName}fetch/o365/data/teams/` ;
  securityPageApiUrl = `${environment.apiHostName}fetch/o365/data/security/`;
  sharepointPageApiUrl = `${environment.apiHostName}fetch/o365/data/sharepoint/`;
  azureAdApiUrl = `${environment.apiHostName}fetch/o365/data/AD/`;
  executiveSummaryApiUrl = `${environment.apiHostName}`;
  exchangeApiUrl = `${environment.apiHostName}fetch/o365/data/outlook/`;
}
