import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import { TeamsService } from '../../services/teams.service';
interface arrayType {
  name: string;
  value: string
  iconName?:string;
}
@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss'],
})
export class TeamsComponent {
  teamsCallsData: any;
  teamsChatData: any;
  teamsData: any;
  teamsChannelData: any;
  teamsDeviceUsageData: any;
  MostCallingUsersArray: Array<arrayType> = [];
  mostCallingDestinationArray: Array<arrayType> = [];
  topThreeMostCallingUsersArray: Array<arrayType> = [];
  chartOptions: any;

  // Loader Variables starts
  showTeamsDataApiLoader: boolean = true;
  showTeamsChannelApiLoader: boolean = true;
  showTeamsCallsApiLoader: boolean = true;
  showTeamsDeviceApiLoader: boolean = true;
  showTeamsActivityApiLoader: boolean = true;
  // Loader Variables Ends

  constructor(private officeboardTeamsService: TeamsService) {
    this.officeboardTeamsService.getTeamsData().subscribe(
      (res: any) => {
        var resMessage= res?.message.replace(/: NaN,/g,": 0,");
        this.teamsData = JSON.parse(resMessage)?.response;
        if (this.teamsData) {
          this.showTeamsDataApiLoader = false;
        }
      },
      (err) => {
        this.showTeamsDataApiLoader = false;
      }
    );
    this.officeboardTeamsService.getTeamsChannel().subscribe(
      (res: any) => {
        this.teamsChannelData = JSON.parse(res?.message).response;
        if (this.teamsChannelData) {
          this.showTeamsChannelApiLoader = false;
        }
      },
      (err) => {
        this.showTeamsChannelApiLoader = false;
      }
    );

    this.officeboardTeamsService.getTeamsCallsData().subscribe(
      (res: any) => {
        this.teamsCallsData = JSON.parse(res?.message).response;
        if (this.teamsCallsData) {
          this.setMostCallingUsersArray(this.teamsCallsData?.calls_details?.total_calls_by_user );
          this.setMostCallingDestinationArray(this.teamsCallsData?.calls_details?.total_calls_by_destination);
          this.showTeamsCallsApiLoader = false;
        }
      },
      (err) => {
        this.showTeamsCallsApiLoader = false;
      }
    );
    this.officeboardTeamsService.getTeamsChatData().subscribe(
      (res: any) => {
        var resMessage= res?.message.replace(/: NaN,/g,": 0,");
        this.teamsChatData = JSON.parse(resMessage)?.response;
        if (this.teamsChatData) {
          this.showTeamsActivityApiLoader = false;
          this.activityChart();
        }
      },
      (err) => {
        this.showTeamsActivityApiLoader = false;
      }
    );
    this.officeboardTeamsService.getTeamsDeviceUsageData().subscribe(
      (res: any) => {
        this.teamsDeviceUsageData = JSON.parse(res?.message).response;
        if (this.teamsDeviceUsageData) {
          this.showTeamsDeviceApiLoader = false;
        }
      },
      (err) => {
        this.showTeamsDeviceApiLoader = false;
      }
    );
  }

  //Setting an array of mostCallingUsersName and their values
  setMostCallingUsersArray(data: object) {
    for (const i in data) {
      const tempData = {
        name: `${i}`,
        value: `${data[i]}`,
      };
      this.MostCallingUsersArray.push(tempData);
    }
    this.MostCallingUsersArray = this.MostCallingUsersArray.sort(
      (a: { value: string }, b: { value: string }) =>
        b.value.localeCompare(a.value, undefined, { numeric: true })
    );
  }

  //Setting an array of mostCallingCountries and their values
  setMostCallingDestinationArray(data: object) {
    for (const i in data) {
      let countryName = i.replace(/\-.*/g,"$'").replace(/ /g,"");  //Here we are skipping any part of string after '-' character if any. And removing whitespace between the country name. Ex: South Africa-Mobile will be converted to SouthAfrica
      const tempData = {
        name: `${i}`,
        value: `${data[i]}`,
        iconName : countryName.toLowerCase()    //Converting country name's characters to lowercase
      };
      this.mostCallingDestinationArray.push(tempData);
    }
    this.mostCallingDestinationArray = this.mostCallingDestinationArray.sort(
      (a: { value: string }, b: { value: string }) =>
        b.value.localeCompare(a.value, undefined, { numeric: true })
    );
  }

  // Activity line chart
  activityChart() {
    this.chartOptions = {
      chart: {
        type: 'line',
        height: 261,
      },
      title: {
        text: null,
      },
      subtitle: {
        text: null,
      },
      xAxis: {
        categories: ['30 Days', '90 Days', '180 Days'],
      },
      colors: ['#38A0BB', '#30D780', '#E5A53C', '#6C9BA5'],
      legend: {
        layout: 'horizontal',
        align: 'right',
        verticalAlign: 'top',
      },
      yAxis: {
        title: {
          text: null,
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
          },
          enableMouseTracking: true,
        },
      },
      series: [
        {
          name: 'Private Messages',
          data: [
            this.teamsChatData?.sum_thirty_days_data?.Private_Chat_Messages,
            this.teamsChatData?.sum_ninety_days_data?.Private_Chat_Messages,
            this.teamsChatData?.sum_one_hundred_eighty_days_data
              ?.Private_Chat_Messages,
          ],
        },
        {
          name: 'Calls',
          data: [
            this.teamsChatData?.sum_thirty_days_data.Calls,
            this.teamsChatData?.sum_ninety_days_data.Calls,
            this.teamsChatData?.sum_one_hundred_eighty_days_data.Calls,
          ],
        },
        {
          name: 'Team Messages',
          data: [
            this.teamsChatData?.sum_thirty_days_data?.Team_Chat_Messages,
            this.teamsChatData?.sum_ninety_days_data?.Team_Chat_Messages,
            this.teamsChatData?.sum_one_hundred_eighty_days_data
              ?.Team_Chat_Messages,
          ],
        },
        {
          name: 'Meetings',
          data: [
            this.teamsChatData?.sum_thirty_days_data?.Meetings,
            this.teamsChatData?.sum_ninety_days_data?.Meetings,
            this.teamsChatData?.sum_one_hundred_eighty_days_data?.Meetings,
          ],
        },
      ],
    };
    var chart = Highcharts.chart('activity', this.chartOptions);
  }
}
