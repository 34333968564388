import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ConstantsService } from './config/constants.service';
import { FunctionsService } from '../utilities/functions.service';

@Injectable({
  providedIn: 'root'
})
export class userAuthService {

  constructor(
      private http: HttpClient,
      private constsvc: ConstantsService,
      private cookie: CookieService,
      private functions: FunctionsService
  ) {
  }

  login(data: any) {
    return this.http.post(`${this.constsvc.loginApiUrl}`, data, this.functions.getHeaders);
  }

  signup(data: any) {
      return this.http.post(`${this.constsvc.signupApiUrl}`, data, this.functions.getHeaders);
  }

  confirmUser(data: any) {
      return this.http.post(`${this.constsvc.confirmUserApiUrl}`, data, this.functions.getHeaders);
  }

  createOrganization(data : any){
      return this.http.post(`${this.constsvc.createorganizationApiUrl}`,data, this.functions.getHeaders);
  }

  logout() {
      this.cookie.deleteAll('/');
      sessionStorage.clear();
      localStorage.clear();
      // window.location.replace('/login');
  }
}

