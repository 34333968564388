import { SecurityService } from './../../services/security.service';
import { FunctionsService } from 'src/app/modules/shared/utilities/functions.service';import { AzureAdService } from './../../services/azureAd.service';
import { ExchangeService } from './../../services/exchange.service';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ExecutiveSummaryService } from '../../services/executive-summary.service';
import moment from 'moment';
import { TeamsService } from '../../services/teams.service';
import { MessageConstantsService } from 'src/app/modules/shared/utilities/messageConstants.service';

@Component({
  selector: 'app-executive-summary',
  templateUrl: './executive-summary.component.html',
  styleUrls: ['./executive-summary.component.scss'],
})

export class ExecutiveSummaryComponent {

  pageNumber: number = 1;
  licencesData: any;
  globalAdminsData;
  securityAlerts;
  mfaUsersStatus;
  passwordExpires;
  mailBoxUsageDetails: any;
  secureScores;
  teamsCallsData: any;
  mailBoxData;
  teamsMeetingsData;
  currentSecureScore180Days = 0;
  maxSecureScore180Days = 0;
  activeUserCount180Days = 0;
  licensedUserCount180Days = 0;
  openAlertsData30Days: any = [];
  openAlertsData90Days: any = [];
  openAlertsData180Days: any = [];
  closedAlertsData30Days: any = [];
  closedAlertsData90Days: any = [];
  closedAlertsData180Days: any = [];
  estimatedMonth = moment().format('YYYY-MM-DD'); //Getting current Date
   iconsColors = [
    'dark_blue', 'light_green', 'light_grey', 'green', 'light_blue'
   ]
  // Loader Variables starts
  showGlobalAdminsApiLoader: boolean = true;
  showSecurityAlertsApiLoader: boolean = true;
  showMfaUsersStatusApiLoader: boolean = true;
  showPasswordExpiresApiLoader: boolean = true;
  showMailBoxUsageApiLoader: boolean = true;
  showSecurityScoreApiLoader: boolean = true;
  showLicencesApiLoader: boolean = true;
  showTeamsCallsApiLoader: boolean = true;
  showMailBoxDataApiLoader: boolean = true;
  showTeamsActivityApiLoader: boolean = true;
  // Loader Variables Ends


  constructor(
    private executiveSummaryService: ExecutiveSummaryService,
    private exchangeService: ExchangeService,
    private azureAdService: AzureAdService,
    public functionsService: FunctionsService,
    private securityService: SecurityService,
    private teamsService: TeamsService,
    public messageConstantsService : MessageConstantsService
  ) {
    this.executiveSummaryService.getGlobalAdminsData().subscribe((res: any) => {
      this.globalAdminsData = JSON.parse(res?.message).response;
      this.showGlobalAdminsApiLoader = false;
    }, (err) => {
      this.showGlobalAdminsApiLoader = false;
    });
    this.securityService.getSecurityAlerts().subscribe((res: any) => {
      this.securityAlerts = JSON.parse(res?.message)?.response;
      if (Object.keys(this.securityAlerts).length > 0 || this.securityAlerts?.length > 0) {

        //Open Alerts = New Alert + In Progress Alerts
        if (this.securityAlerts?.status_details?.newAlert)
          this.securityAlerts?.status_details?.newAlert.forEach((item: any) => {
            if (this.functionsService.getFormattedDate(item?.createdDateTime, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD") > this.functionsService.calculateDifference(this.estimatedMonth, "YYYY-MM-DD", 30, 'days', "YYYY-MM-DD"))
              this.openAlertsData30Days.push(item);
            if (this.functionsService.getFormattedDate(item?.createdDateTime, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD") > this.functionsService.calculateDifference(this.estimatedMonth, "YYYY-MM-DD", 90, 'days', "YYYY-MM-DD"))
              this.openAlertsData90Days.push(item);
            if (this.functionsService.getFormattedDate(item?.createdDateTime, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD") > this.functionsService.calculateDifference(this.estimatedMonth, "YYYY-MM-DD", 180, 'days', "YYYY-MM-DD"))
              this.openAlertsData180Days.push(item);
          });
        if (this.securityAlerts?.status_details?.inProgress)
          this.securityAlerts?.status_details?.inProgress.forEach((item: any) => {
            if (this.functionsService.getFormattedDate(item?.createdDateTime, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD") > this.functionsService.calculateDifference(this.estimatedMonth, "YYYY-MM-DD", 30, 'days', "YYYY-MM-DD"))
              this.openAlertsData30Days.push(item);
            if (this.functionsService.getFormattedDate(item?.createdDateTime, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD") > this.functionsService.calculateDifference(this.estimatedMonth, "YYYY-MM-DD", 90, 'days', "YYYY-MM-DD"))
              this.openAlertsData90Days.push(item);
            if (this.functionsService.getFormattedDate(item?.createdDateTime, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD") > this.functionsService.calculateDifference(this.estimatedMonth, "YYYY-MM-DD", 180, 'days', "YYYY-MM-DD"))
              this.openAlertsData180Days.push(item);
          });

        //Closed Alerts = resolved Alert + dismissed Alerts
        if (this.securityAlerts?.status_details?.resolved)
          this.securityAlerts?.status_details?.resolved.forEach((item: any) => {
            if (this.functionsService.getFormattedDate(item?.createdDateTime, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD") > this.functionsService.calculateDifference(this.estimatedMonth, "YYYY-MM-DD", 30, 'days', "YYYY-MM-DD"))
              this.closedAlertsData30Days.push(item);
            if (this.functionsService.getFormattedDate(item?.createdDateTime, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD") > this.functionsService.calculateDifference(this.estimatedMonth, "YYYY-MM-DD", 90, 'days', "YYYY-MM-DD"))
              this.closedAlertsData90Days.push(item);
            if (this.functionsService.getFormattedDate(item?.createdDateTime, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD") > this.functionsService.calculateDifference(this.estimatedMonth, "YYYY-MM-DD", 180, 'days', "YYYY-MM-DD"))
              this.closedAlertsData180Days.push(item);
          });
        if (this.securityAlerts?.status_details?.dismissed)
          this.securityAlerts?.status_details?.dismissed.forEach((item: any) => {
            if (this.functionsService.getFormattedDate(item?.createdDateTime, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD") > this.functionsService.calculateDifference(this.estimatedMonth, "YYYY-MM-DD", 30, 'days', "YYYY-MM-DD"))
              this.closedAlertsData30Days.push(item);
            if (this.functionsService.getFormattedDate(item?.createdDateTime, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD") > this.functionsService.calculateDifference(this.estimatedMonth, "YYYY-MM-DD", 90, 'days', "YYYY-MM-DD"))
              this.closedAlertsData90Days.push(item);
            if (this.functionsService.getFormattedDate(item?.createdDateTime, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD") > this.functionsService.calculateDifference(this.estimatedMonth, "YYYY-MM-DD", 180, 'days', "YYYY-MM-DD"))
              this.closedAlertsData180Days.push(item);
          });
      }
      this.showSecurityAlertsApiLoader = false;
    }, (err) => {
      this.showSecurityAlertsApiLoader = false;
    });
    this.azureAdService.getMFAEnabledUsers().subscribe((res: any) => {
      this.mfaUsersStatus = JSON.parse(res?.message)?.response;
        this.showMfaUsersStatusApiLoader = false;
    }, (err) => {
      this.showMfaUsersStatusApiLoader = false;
    });
    this.azureAdService.getPasswordExpires().subscribe((res: any) => {
      this.passwordExpires = JSON.parse(res?.message)?.response;
      this.showPasswordExpiresApiLoader = false;
    }, (err) => {
      this.showPasswordExpiresApiLoader = false;
    });
    this.exchangeService.getMailBoxUsageDetails().subscribe(
      (res: any) => {
        //For Never Logged In Data
        this.mailBoxUsageDetails = JSON.parse(res?.message)?.response;
        this.showMailBoxUsageApiLoader = false;
      },
      (err) => {
        this.showMailBoxUsageApiLoader = false;
      }
    );
    //For Licenses Table
    this.executiveSummaryService.getProductsData().subscribe((res: any) => {
      this.licencesData = JSON.parse(res?.message)?.response;
      this.showLicencesApiLoader = false;
    }, (err) => {
      this.showLicencesApiLoader = false;
    });
    this.securityService.getSecurityScores().subscribe((res: any) => {
      this.secureScores = JSON.parse(res?.message)?.response;
      if (this.secureScores) {
        var currentScore = 0;
        var maxScore = 0;
        var licenseUserCount = 0;
        var activeUserCount = 0;
        const secureScoreDetails = this.secureScores?.secure_scores_details.filter((item) => item?.date > this.functionsService.calculateDifference(this.estimatedMonth, "YYYY-MM-DD", 180, 'days', "YYYY-MM-DD")); //Filtering secure score data of 180 Days
        secureScoreDetails.forEach(element => {
          currentScore += element?.current_score;
          maxScore += element?.max_score;
          activeUserCount +=element?.active_user_count;
          licenseUserCount +=element?.licensed_user_count;
        });
        this.currentSecureScore180Days = currentScore / secureScoreDetails?.length;
        this.maxSecureScore180Days = maxScore / secureScoreDetails?.length;
        this.licensedUserCount180Days = licenseUserCount / secureScoreDetails?.length;
        this.activeUserCount180Days = activeUserCount / secureScoreDetails?.length;
      }
      this.showSecurityScoreApiLoader = false;
    }, (err) => {
      this.showSecurityScoreApiLoader = false;
    });
    this.teamsService.getTeamsCallsData().subscribe(
      (res: any) => {
        this.teamsCallsData = JSON.parse(res?.message)?.response?.calls_details;
        this.showTeamsCallsApiLoader = false;
      },
      (err) => {
        this.showTeamsCallsApiLoader = false;
      }
    );
    this.exchangeService.getMailBoxData().subscribe(
      (res: any) => {
        this.mailBoxData = JSON.parse(res?.message)?.response;
        this.showMailBoxDataApiLoader = false;
      },
      (err) => {
        this.showMailBoxDataApiLoader = false;
      }
    );
    this.teamsService.getTeamsChatData().subscribe(
      (res: any) => {
        var resMessage= res?.message.replace(/: NaN,/g,": 0,");
        this.teamsMeetingsData = JSON.parse(resMessage)?.response;
        this.showTeamsActivityApiLoader = false;
      },
      (err) => {
        this.showTeamsActivityApiLoader = false;
      }
    );
  }

  onPageBoundsCorrection(newPageNumber: number) {
    this.pageNumber = newPageNumber;
  }

}
