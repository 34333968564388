import { ExchangeService } from './../../services/exchange.service';
import { FunctionsService } from 'src/app/modules/shared/utilities/functions.service';
import { AzureAdService } from './../../services/azureAd.service';
import { Component} from '@angular/core';
import { SecurityService } from '../../services/security.service';
import * as Highcharts from 'highcharts';
interface tempData {
  name: string,
  value: string,
}
@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent{
  securityAlerts: any;
  securityScores: any;
  passwordExpires: any;
  totalADUsers: any;
  mailBoxUsageDetails : any;
  mostAlertsArray: Array<tempData> = [];
  alertTypesArray: Array<tempData> = [];
  alertTypesCategory: Array<tempData> = [];
  highSecurityPercentage !:number;
  lowSecurityPercentage !:number;
  mediumSecurityPercentage !:number;
  informationalSecurityPercentage !:number;
  chartOptions;
  // Loader Variables starts
  showSecurityScoreApiLoader : boolean = true;
  showPasswordExpiresApiLoader : boolean = true;
  showTotalAdUsersApiLoader : boolean = true;
  showSecurityAlertsApiLoader : boolean = true;
  showMailBoxUsageApiLoader : boolean = true;
  // Loader Variables Ends

  constructor(private securityService: SecurityService, private azureAdService : AzureAdService, private exchangeService : ExchangeService, public functionsService:FunctionsService) {
    this.securityService.getSecurityAlerts().subscribe((res: any) => {
      this.securityAlerts = JSON.parse(res?.message)?.response;
      if(this.securityAlerts){
        this.setTopFiveTypes(this.securityAlerts?.top_five_title);
        this.setTopFiveAlerts(this.securityAlerts?.user_states_count);
        this.setTopFiveCategory(this.securityAlerts?.top_five_categories);
        this.setSeverityCountPercent(this.securityAlerts?.severity_count);
        this.showSecurityAlertsApiLoader = false;
      }
    },(err)=>{
      this.showSecurityAlertsApiLoader = false;
    });
    this.securityService.getSecurityScores().subscribe((res: any) => {
      this.securityScores = JSON.parse(res?.message)?.response;
      if(this.securityScores){
        this.secureScoreChart();
        this.showSecurityScoreApiLoader = false;
      }
    },(err)=>{
      this.showSecurityScoreApiLoader = false;
    });
    this.azureAdService.getPasswordExpires().subscribe((res: any) => {
      this.passwordExpires = JSON.parse(res?.message);
      if(this.passwordExpires){
        this.showPasswordExpiresApiLoader = false;
      }
    },(err)=>{
      this.showPasswordExpiresApiLoader = false;
    });
    this.azureAdService.getTotalAdUsers().subscribe((res: any) => {
      this.totalADUsers = JSON.parse(res?.message)?.response;
      if(this.totalADUsers){
        this.showTotalAdUsersApiLoader = false;
      }
    },(err)=>{
      this.showTotalAdUsersApiLoader = false;
    });
    this.exchangeService.getMailBoxUsageDetails().subscribe(
      (res: any) => {
        //For Never LoggedIn Data
        this.mailBoxUsageDetails = JSON.parse(res?.message)?.response;
        if (this.mailBoxUsageDetails) {
          this.showMailBoxUsageApiLoader = false;
        }
      },
      (err) => {
        this.showMailBoxUsageApiLoader = false;
      }
    );
  }

  //Function to set top five values
  setTopFiveAlerts(alerts: object) {
    let key = Object.keys(alerts);
    let val = Object.values(alerts);
    for (var i = 0; i < key.length; i++) {
      const tempData = {
        name: `${key[i]}`,
        value: `${val[i]}`,
      };
      this.mostAlertsArray.push(tempData);
    }
  }

  //Function to set top five values
  setTopFiveTypes(types: any) {
    let key = Object.keys(types);
    let val = Object.values(types);
    for (var i = 0; i < key.length; i++) {
      const tempData = {
        name: `${key[i]}`,
        value: `${val[i]}`,
      };
      this.alertTypesArray.push(tempData);
    }
  }

  //Function to set top five category
  setTopFiveCategory(types: object) {
    let key = Object.keys(types);
    let val = Object.values(types);
    for (var i = 0; i < key.length; i++) {
      const tempData = {
        name: `${key[i]}`,
        value: `${val[i]}`,
      };
      this.alertTypesCategory.push(tempData);
    }
  }

  //Dynamically set severity percentage
  setSeverityCountPercent(alerts: any){
    let total = alerts.high + alerts.medium + alerts.low + alerts.informational;
    this.highSecurityPercentage = (alerts.high/total) * 100 ;
    this.mediumSecurityPercentage = (alerts.medium/total) * 100 ;
    this.lowSecurityPercentage = (alerts.low/total) * 100 ;
    this.informationalSecurityPercentage = (alerts.informational/total) * 100 ;
  }

  secureScoreChart(){
   this.chartOptions = {
      chart: {
        type: 'column',
        height : 160
      },
      title: {
        text: ' ',
      },
      plotOptions: {
        series: {
          grouping: false,
          borderWidth: 0,
          dataLabels: [{
            enabled: true,
            style: {
              fontSize: '8px'
            }
          }],
        }
      },
      credits:{
      enabled:false
    },
      exporting: {
        enabled: false
      },
      legend: {
        enabled: true,
      },

      tooltip: {
        shared: true,
        headerFormat: '<span style="font-size: 12px">Secure Score</span><br/>',
        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
      },
      xAxis: {
        categories: ['30 Days', '60 Days', '90 Days'],
      },

      yAxis: [{
        title: {
          text: null
        },
        showFirstLabel: true
      }],
      series: [{
        color: '#bbdefb',
        pointPlacement: -0.2,
        dataLabels: [{
            y : -10,
          backgroundColor: '#eeeeee',
          shape : 'callout'
        }],
        data: [this.securityScores?.thirty_days_max_Score, this.securityScores?.sixty_days_max_Score, this.securityScores?.ninety_days_maximum_Score],
        name: 'Max'
      }, {
        name: 'Current',
        color : '#55a8e9',
        dataLabels: [{
          inside: true,
        }],
        data: [this.securityScores?.thirty_days_current_score, this.securityScores?.sixty_days_current_score, this.securityScores?.ninety_days_current_score],
      }],
    }
    var chart = Highcharts.chart('secure_score_chart', this.chartOptions);
  }
}
