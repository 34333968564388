import { MessageConstantsService } from './../../../shared/utilities/messageConstants.service';
import { CookieService } from 'ngx-cookie-service';
import { Component} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { userAuthService } from '../../../shared/services/user-auth.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-confirm-user',
  templateUrl: './confirm-user.component.html',
  styleUrls: ['./confirm-user.component.scss']
})
export class ConfirmUserComponent {

  emailId : string = `${this.cookie.get('email_id')}`;
  showLoader:boolean = false;

  confirmUserForm = new FormGroup({
    otp: new FormControl(null, Validators.required)
  });

  constructor( private userAuthService: userAuthService, private cookie : CookieService,
    public messageConstantsService : MessageConstantsService,
    private router: Router,
    private toastr: ToastrService,)

    {
      if (this.router.getCurrentNavigation()?.extras.state?.emailID)            //For receiving email_id if we are landing to this page from Sign Up Page
      this.emailId=this.router.getCurrentNavigation()?.extras.state?.emailID

      this.toastr.clear();

      if(this.cookie.get('otp_verified')=='false')                           // To check if the user is landing on this page after first time signup OR login
      this.toastr.success(this.messageConstantsService.InfoMsgOtpVerificationPage);
      else
      this.toastr.success(this.messageConstantsService.signUpSuccessMessage);
     }

    confirmUser() {
      this.showLoader=true;
      const reqData = {
        user_id : this.cookie.get('_u_id'),                   //  _u_id is User ID.
        otp : this.confirmUserForm.controls.otp.value
       }

      this.userAuthService.confirmUser(reqData)
        .subscribe((res:any) => {
          this.router.navigate(['/create-organization'], {
              state: {
                confirmUserRoute: true,
              },
            });
        }, err => {
          this.showLoader=false;
          this.toastr.clear();
          this.toastr.error(this.messageConstantsService.otpFailureMessage);
        });
    }
}
