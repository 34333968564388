<header id="page-topbar">
    <div class="navbar-header">
        <div class="d-flex">
            <div class="navbar-brand-box organization">
              <a href="#" class="logo logo-light">
                <span class="logo-sm">
                    <img src="/assets/images/global/svg/logo-light-sm.svg" class="img-fluid" alt="" height="22">
                </span>
                <span class="logo-lg">
                    <img src="/assets/images/global/svg/logo-light.svg" class="img-fluid" alt="">
                </span>
            </a>
            </div>
        </div>
    </div>
  </header>
<div class="main-content subscription_list me-0">
    <div class="page-content">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box d-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0 font-size-18">Terms and Conditions</h4>
                    </div>
                </div>
            </div>
            <div class="row">
              <div class="col-12">

                <div class="card bg-transparent border-0" id="header">

                <div class="card card-body form-group mb-0">
                  <div class="row m-5 text-justify">
                    <h6 class="font-weight-semibold">Curtain Raising Introduction</h6>
                    <p>
                      These Terms of Service (“Terms”) govern your access to and use XCNimbus (“XCNimbus”, “XCNimbusApp”, “we”, “us”, “our”), operated and provided by XContent Business Solutions (Pty) Ltd. By using the Services you agree to be bound by these Terms.
                    </p>

                    <h4 class="mt-4">1) Contract</h4>
                    <p>
                      You agree that by clicking “Join XCNimbus”, “Sign Up” or similar, registering, accessing or using our services (described below), you are agreeing to enter into a legally binding contract with XCNimbus (even if you are using our Services on behalf of a company). If you do not agree to this contract (“Contract” or “User Agreement”), do not click “Sign Up” (or similar) and do not access or otherwise use any of our Services.
                      Your use of our Services is also subject to our Privacy Policy.
                    </p>

                    <h4 class="mt-4">2) Services</h4>
                    <p>
                      This Contract applies to XCNimbus, and other XCNimbus-related sites, apps, communications and other services that state that they are offered under this Contract (“Services”). Registered users of our Services are “Members” and unregistered users are “Visitors”. This Contract applies to both.
                    </p>

                    <h4 class="mt-4">3) XCNimbus</h4>
                    <p>
                      You are entering into this Contract with XCNimbus (also referred to as “we” and “us”). If you reside in the United States, you are entering into this Contract with XContent Business Solutions (Pty) Ltd and your personal data provided to, or collected by or for, our Services is controlled by XContent Business Solutions (Pty) Ltd. If you reside outside India, you are entering into this Contract with XContent Business Solutions (Pty) Ltd. and your personal data provided to, or collected by or for, our Services is controlled by XContent Business Solutions (Pty) Ltd.
                    </p>

                    <h4 class="mt-4">4) Members and Visitors </h4>
                    <p>
                      When you register and join the XCNimbus Service, you become a Member. If you have chosen not to register for our Services, you may access certain features as a visitor.
                    </p>

                    <h4 class="mt-4">5) Change </h4>
                    <p>
                      We may modify this Contract, and our Privacy Policy from time to time. If we make material changes to it, we will provide you notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. If you object to any changes, you may close your account. Your continued use of our Services after we publish or send a notice about our changes to these terms means that you are consenting to the updated terms.
                    </p>

                    <h4 class="mt-4">6) Obligations</h4>
                    <p>
                      The Services are not for use by anyone under the age of 16. To use the Services, you agree that: (1) you must be the “Minimum Age” (described below) or older; (2) you will only have one XCNimbus account, which must be in your real name; and (3) you are not already restricted by XCNimbus from using the Services. <br/> <br/>
                      “Minimum Age” means 16 years old. However, if law requires that you must be older in order for XCNimbus to lawfully provide the Services to you without parental consent (including using of your personal data) then the Minimum Age is such older age.
                    </p>

                    <h4 class="mt-4">7) Your Account</h4>
                    <p>
                      Members are account holders. You agree to: (1) try to choose a strong and secure password; (2) keep your password secure and confidential; (3) not transfer any part of your account and (4) follow the law and our list of Dos and Don’ts. You are responsible for anything that happens through your account unless you close it or report misuse.
                    </p>

                    <h4 class="mt-4">8) Notices and Service Messages</h4>
                    <p>
                      You agree that we will provide notices to you in the following ways: (1) a notice within the Service, or (2) a message sent to the contact information you provided us (e.g., email, mobile number). You agree to keep your contact information up to date. <br/> <br/>
                      <span class="font-weight-semibold">Please review your settings to control and limit the messages you receive from us.</span>
                    </p>


                    <h4 class="mt-4">9) Sharing</h4>
                    <p>
                      Our Services allow messaging and sharing of information in many ways, such as your profile and peer feedback. Information and content that you share or post may be seen by other Members or Visitors. Where we have made settings available, we will honor the choices you make about who can see content or information. <br/> <br/>
                      We are not obligated to publish any information or content on our Service and can remove it in our sole discretion, with or without notice.
                    </p>

                    <h4 class="mt-4">10) Rights and Limits</h4>

                    <p>
                      <span class="font-weight-semibold">Your License to XCNimbus</span> <br/> <br/>
                      As between you and XCNimbus, you own the content and information that you submit or post to the Services and you are only granting XCNimbus and our affiliates the following non-exclusive license: A worldwide, transferable and sublicensable right to use, copy, modify, distribute, publish, and process, information and content that you provide through our Services, without any further consent, notice and/or compensation to you or others. These rights are limited in the following ways: <br/> <br/>

                      You can end this license for specific content by deleting such content from the Services, or generally by closing your account, except (a) to the extent you shared it with others as part of the Service and they copied, re-shared it or stored it and (b) for the reasonable time it takes to remove from backup and other systems. <br/> <br/>

                      We will not include your content in advertisements for the products and services of third parties to others without your separate consent. However, we have the right, without payment to you or others, to serve ads near your content and information, and your social actions on sponsored content and company pages may be visible, as noted in the Privacy Policy. <br/> <br/>

                      While we may edit and make formatting changes to your content (such as translating it, modifying the size, layout or file type or removing metadata), we will not modify the meaning of your expression. <br/> <br/>

                      Because you own your content and information and we only have non-exclusive rights to it, you may choose to make it available to others, including under the terms of a Creative Commons license. <br/> <br/>

                      You agree that we may access, store and use any information that you provide in accordance with the terms of the Privacy Policy and your choices (including settings). <br/> <br/>

                      By submitting suggestions or other feedback regarding our Services to XCNimbus, you agree that XCNimbus can use and share (but does not have to) such feedback for any purpose without compensation to you. <br/> <br/>

                      You agree to only provide content or information that does not violate the law nor anyone’s rights (including intellectual property rights). You also agree that your profile information will be truthful. XCNimbus may be required by law to remove certain information or content in certain countries.

                    </p>

                    <h4 class="mt-4">11) Service Availability</h4>
                    <p>
                      We may change or discontinue any of our Services. We don’t promise to store or keep showing any information and content that you’ve posted.  <br/> <br/>

                      XCNimbus is not a storage service. You agree that we have no obligation to store, maintain or provide you a copy of any content or information that you or others provide, except to the extent required by applicable law and as noted in our Privacy Policy.
                    </p>
                    <h4 class="mt-4">12) Limits </h4>
                    <p>
                      XCNimbus reserves the right to limit your use of the Services, including the number of your connections and your ability to contact other Members. XCNimbus reserves the right to restrict, suspend, or terminate your account if  XCNimbus believes that you may be in breach of this Contract or law or are misusing the Services (e.g. violating any Do and Don’ts).
                    </p>

                    <h4 class="mt-4">13) Intellectual Property Rights </h4>
                    <p>
                      XCNimbus reserves all of its intellectual property rights in the Services. Using the Services does not give you any ownership in our Services or the content or information made available through our Services. Trademarks and logos used in connection with the Services are the trademarks of their respective owners. XCNimbus, logos and other XCNimbus trademarks, service marks, graphics, and logos used for our Services are trademarks or registered trademarks of XContent Business Solutions (Pty) Ltd.
                    </p>

                    <h4 class="mt-4">14) Disclaimer and Limit of Liability </h4>
                    <p>
                      <span class="font-weight-semibold">Your License to XCNimbus</span> <br/> <br/>
                      No Warranty  <br/> <br/>
                      TO THE EXTENT ALLOWED UNDER LAW, XCNimbus AND ITS AFFILIATES (AND THOSE THAT XCNimbus WORKS WITH TO PROVIDE THE SERVICES) (A) DISCLAIM ALL IMPLIED WARRANTIES AND REPRESENTATIONS (E.G. WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY OF DATA, AND NONINFRINGEMENT); (B) DO NOT GUARANTEE THAT THE SERVICES WILL FUNCTION WITHOUT INTERRUPTION OR ERRORS, AND (C) PROVIDE THE SERVICE (INCLUDING CONTENT AND INFORMATION) ON AN “AS IS” AND “AS AVAILABLE” BASIS.

                      <br/> <br/>

                      SOME LAWS DO NOT ALLOW CERTAIN DISCLAIMERS, SO SOME OR ALL OF THESE DISCLAIMERS MAY NOT APPLY TO YOU.
                    </p>

                    <h4 class="mt-4">15) Exclusion of Liability </h4>
                    <p>
                      TO THE EXTENT PERMITTED UNDER LAW (AND UNLESS XCNimbus HAS ENTERED INTO A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THIS CONTRACT), XCNimbus AND ITS AFFILIATES (AND THOSE THAT XCNimbus WORKS WITH TO PROVIDE THE SERVICES) SHALL NOT BE LIABLE TO YOU OR OTHERS FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF DATA, OPPORTUNITIES, REPUTATION, PROFITS OR REVENUES, RELATED TO THE SERVICES (E.G. OFFENSIVE OR DEFAMATORY STATEMENTS, DOWN TIME OR LOSS, USE OF, OR CHANGES TO, YOUR INFORMATION OR CONTENT).

                      <br/> <br/>

                      THIS LIMITATION OF LIABILITY IS PART OF THE BASIS OF THE BARGAIN BETWEEN YOU AND XCNimbus AND SHALL APPLY TO ALL CLAIMS OF LIABILITY (E.G. WARRANTY, TORT, NEGLIGENCE, CONTRACT, LAW) AND EVEN IF XCNimbus OR ITS AFFILIATES HAS BEEN TOLD OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF THESE REMEDIES FAIL THEIR ESSENTIAL PURPOSE.

                      <br/><br/>

                      SOME LAWS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY, SO THESE LIMITS MAY NOT APPLY TO YOU.

                    </p>

                    <h4 class="mt-4">16) Termination </h4>
                    <p>
                      Both you and XCNimbus may terminate this Contract at any time with notice to the other. On termination, you lose the right to access or use the Services. The following shall survive termination: <br/><br/>
                      Our rights to use and disclose your feedback;
                    </p>

                    <h4 class="mt-4">17) Dispute Resolution </h4>
                    <p>
                      You agree that the laws of the Government. of India ., excluding its conflict of laws rules, shall exclusively govern any dispute relating to this Contract and/or the Services. We both agree that all of these claims can only be litigated in the federal or state courts in Delhi, India, and we each agree to personal jurisdiction in those courts.
                    </p>
                    <h4 class="mt-4">18) General Terms </h4>
                    <p>
                      If a court with authority over this Contract finds any part of it not enforceable, you and us agree that the court should modify the terms to make that part enforceable while still achieving its intent. If the court cannot do that, you and us agree to ask the court to remove that unenforceable part and still enforce the rest of this Contract. To the extent allowed by law, the English language version of this Contract is binding and other translations are for convenience only. This Contract (including additional terms that may be provided by us when you engage with a feature of the Services) is the only agreement between us regarding the Services and supersedes all prior agreements for the Services.
                      <br/> <br/>
                      If we do not act to enforce a breach of this Contract, that does not mean that XCNimbus has waived its right to enforce this Contract. You may not assign or transfer this Contract to anyone without our consent. However, you agree that XCNimbus may assign this Contract to its affiliates or a party that buys it without your consent. There are no third party beneficiaries to this Contract.
                      <br/> <br/>
                      We reserve the right to change the terms of this Contract and will provide you notice if we do and we agree that changes cannot be retroactive. If you do not agree to these changes, you must stop using the Services.
                    </p>

                    <h4 class="mt-4">19) XCNimbus “Dos” and “Don’ts” </h4>

                    <ul class="list list-unstyled mb-0">
                      <li><span class="font-weight-semibold">Dos - You agree that you will:</span></li>
                      <li><i class="icon-checkmark-circle text-success mr-2"></i>comply with all User Terms of Service, including the terms of this Policy;</li>
                      <li><i class="icon-checkmark-circle text-success mr-2"></i>comply with all applicable laws and governmental regulations, including, but not limited to, all intellectual property, data, privacy, and export control laws, and regulations promulgated by any government agencies, including, but not limited to, the India Securities and Exchange Commission, and any rules of any national and other securities exchanges;</li>
                      <li><i class="icon-checkmark-circle text-success mr-2"></i>upload and disseminate only Customer Data to which Customer owns all required rights under law and under contractual and fiduciary relationships (such as proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements) and do so only consistent with applicable law;</li>
                      <li><i class="icon-checkmark-circle text-success mr-2"></i>use commercially reasonable efforts to prevent unauthorized access to or use of the Services;</li>
                      <li><i class="icon-checkmark-circle text-success mr-2"></i>keep passwords and all other login information confidential;</li>
                      <li><i class="icon-checkmark-circle text-success mr-2"></i>monitor and control all activity conducted through your account in connection with the Services;</li>
                      <li><i class="icon-checkmark-circle text-success mr-2"></i>promptly notify us if you become aware of or reasonably suspect any illegal or unauthorized activity or a security breach involving your accounts or teams, including any loss, theft, or unauthorized disclosure or use of a username, password, or account; and</li>
                      <li><i class="icon-checkmark-circle text-success mr-2"></i>comply in all respects with all applicable terms of the third party applications, including any that Customer elects to integrate with the Services that you access or subscribe to in connection with the Services. <br/> <br/> <br/> </li>


                      <li class="font-weight-semibold">Don’ts - You agree that you will not:</li>
                      <li><span class="badge badge-mark border-danger mr-2"></span> permit any third party that is not an Authorized User to access the Services;</li>
                      <li><span class="badge badge-mark border-danger mr-2"></span> share, transfer or otherwise provide access to an account designated for you to another person;</li>
                      <li><span class="badge badge-mark border-danger mr-2"></span> use the Services to store or transmit any Customer Data that may infringe upon or misappropriate someone else’s trademark, copyright, or other intellectual property, or that may be tortious or unlawful;</li>
                      <li><span class="badge badge-mark border-danger mr-2"></span> upload to, or transmit from, the Services any data, file, software, or link that contains or redirects to a virus, Trojan horse, worm, or other harmful component or a technology that unlawfully accesses or downloads content or information stored within the Services or on the hardware of XCNimbus or any third party;</li>
                      <li><span class="badge badge-mark border-danger mr-2"></span> attempt to reverse engineer, decompile, hack, disable, interfere with, disassemble, modify, copy, translate, or disrupt the features, functionality, integrity, or performance of the Services (including any mechanism used to restrict or control the functionality of the Services), any third party use of the Services, or any third party data contained therein (except to the extent such restrictions are prohibited by applicable law);</li>
                      <li><span class="badge badge-mark border-danger mr-2"></span> attempt to gain unauthorized access to the Services or related systems or networks or to defeat, avoid, bypass, remove, deactivate, or otherwise circumvent any software protection or monitoring mechanisms of the Services;</li>
                      <li><span class="badge badge-mark border-danger mr-2"></span> access the Services in order to build a similar or competitive product or service or copy any ideas, features, functions, or graphics of the Services;</li>
                      <li><span class="badge badge-mark border-danger mr-2"></span> use the Services in any manner that may harm minors or that interacts with or targets people under the age of thirteen;</li>
                      <li><span class="badge badge-mark border-danger mr-2"></span> impersonate any person or entity, including, but not limited to, an employee of ours, an “Administrator”, an “Owner”, or any other Authorized User, or falsely state or otherwise misrepresent your affiliation with a person, organization or entity;</li>
                      <li><span class="badge badge-mark border-danger mr-2"></span> use the Services to provide material support or resources (or to conceal or disguise the nature, location, source, or ownership of material support or resources) to any organization(s) designated by the United States government as a foreign terrorist organization pursuant to section 219 of the Immigration and Nationality Act or other laws and regulations concerning national security, defense or terrorism;</li>
                      <li><span class="badge badge-mark border-danger mr-2"></span> access, search, or create accounts for the Services by any means other than our publicly supported interfaces (for example, scraping or creating accounts in bulk);</li>
                      <li><span class="badge badge-mark border-danger mr-2"></span> send unsolicited communications, promotions or advertisements, or spam;</li>
                      <li><span class="badge badge-mark border-danger mr-2"></span> send altered, deceptive or false source-identifying information, including spoofing or phishing;</li>
                      <li><span class="badge badge-mark border-danger mr-2"></span> sublicense, resell, time share or similarly exploit the Services;</li>
                      <li><span class="badge badge-mark border-danger mr-2"></span> access or use the Services on behalf of, or for the benefit of, any Patent Assertion Entity (as defined in the Customer-Specific Supplement);</li>
                      <li><span class="badge badge-mark border-danger mr-2"></span> use contact or other user information obtained from the Services (including email addresses) to contact Authorized Users outside of the Services without their express permission or authority or to create or distribute mailing lists or other collections of contact or user profile information for Authorized Users for use outside of the Services; or</li>
                      <li><span class="badge badge-mark border-danger mr-2"></span> authorize, permit, enable, induce or encourage any third party to do any of the above.</li>

                    </ul>

                    <h4 class="font-weight-semibold mt-4">Complaints Regarding Content</h4>
                    <p>
                      We respect the intellectual property rights of others. We require that information posted by Members be accurate and not in violation of the intellectual property rights or other rights of third parties. <br/><br/>
                    </p>

                    <h4 class="font-weight-semibold">How To Contact Us</h4>

                    <h4 class="mt-5">If you want to send us a note, please contact us at <a href="mailto:portal@xcnimbus.com">portal@xcnimbus.com</a>.</h4>
                  </div>

                  </div>
                </div>

              </div>
            </div>

        </div>
    </div>
</div>
<app-footer></app-footer>
