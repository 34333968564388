import {Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ExchangeService } from '../../services/exchange.service';
interface arrayType {
  name: string;
  value: number;
  iconName: string;
}
@Component({
  selector: 'app-exchange',
  templateUrl: './exchange.component.html',
  styleUrls: ['./exchange.component.scss'],
})
export class ExchangeComponent{
  mailBoxData: any;
  mailBoxTraffic: any;
  mailBoxDetails: any;
  chartOptions: any;
  mailBoxUsageQuota: any;
  mailsActivityData: any;
  mailBoxUsageDetails: any;
  emailAppUsageDetails: any;
  emailAppVersionDetails: any;
  licenseCountArray: Array<{ name; value }> = [];
  sortedEmailAppUsageArray: Array<arrayType> = [];
  sortedEmailAppVersionArray: Array<arrayType> = [];

  // Loader Variables starts
  showMailBoxQuotaStatusApiLoader: boolean = true;
  showMailBoxDataApiLoader: boolean = true;
  showMailBoxTrafficApiLoader: boolean = true;
  showMailBoxDetailsApiLoader: boolean = true;
  showMailsActivityApiLoader: boolean = true;
  showMailBoxUsageApiLoader: boolean = true;
  showEmailAppUsageApiLoader: boolean = true;
  showEmailAppVersionApiLoader: boolean = true;
  // Loader Variables Ends

  emailAppIcons = {
    'Mail For Mac': 'laptop.svg',
    'IMAP4 App': 'server-outline.svg',
    'Other For Mobile': 'phone.svg',
    'Outlook For Mac': 'apple.svg',
    'Outlook For Mobile': 'android.svg',
    'Outlook For Web': 'laptop.svg',
    'Outlook For Windows': 'windows.svg',
    'POP3 App': 'chrome.svg',
    'SMTP App': 'server.svg',
  };

  emailAppVersionIcons = {
    'Outlook' : 'outlook.svg',
    'Undetermined' : 'email.svg'
  }

  constructor(private exchangeService: ExchangeService) {
    this.exchangeService.getMailBoxData().subscribe(
      (res: any) => {
        this.mailBoxData = JSON.parse(res?.message)?.response;
        if (this.mailBoxData) {
          this.showMailBoxDataApiLoader = false;
          if(this.showMailBoxTrafficApiLoader == false){      // The chart will be created only if we have data from both API's mailBoxTraffic and mailBoxData
            this.activityTrendChart();
          }
        }
      },
      (err) => {
        this.showMailBoxDataApiLoader = false;
      }
    );
    this.exchangeService.getMailBoxDetails().subscribe(
      (res: any) => {
        //For License count
        this.mailBoxDetails = JSON.parse(res?.message)?.response;
        if (this.mailBoxDetails) {
          this.setMostLicenseCountArray(this.mailBoxDetails?.license_count);
        }
        this.showMailBoxDetailsApiLoader = false;
      },
      (err) => {
        this.showMailBoxDetailsApiLoader = false;
      }
    );
    this.exchangeService.getMailBoxTraffic().subscribe(
      (res: any) => {
        this.mailBoxTraffic = JSON.parse(res?.message)?.response;
        if (this.mailBoxTraffic) {
          this.showMailBoxTrafficApiLoader = false;
          if(this.showMailBoxDataApiLoader == false){  // The chart will be created only if we have data from both API's mailBoxTraffic and mailBoxData
            this.activityTrendChart();
          }
        }
      },
      (err) => {
        this.showMailBoxTrafficApiLoader = false;
      }
    );
    this.exchangeService.getMailBoxQuotaStatus().subscribe(
      (res: any) => {
        this.mailBoxUsageQuota = JSON.parse(res?.message)?.response;
        if (this.mailBoxUsageQuota) {
          this.showMailBoxQuotaStatusApiLoader = false;
        }
      },
      (err) => {
        this.showMailBoxQuotaStatusApiLoader = false;
      }
    );
    this.exchangeService.getMailsActivityData().subscribe(
      (res: any) => {
        //For Mail Traffic Data (Most Emails Sent and Recieved User Data)
        this.mailsActivityData = JSON.parse(res?.message)?.response;
        if (this.mailsActivityData) {
          this.showMailsActivityApiLoader = false;
        }
      },
      (err) => {
        this.showMailsActivityApiLoader = false;
      }
    );
    this.exchangeService.getMailBoxUsageDetails().subscribe(
      (res: any) => {
        //For Most Inactive Users Data
        this.mailBoxUsageDetails = JSON.parse(res?.message)?.response;
        if (this.mailBoxUsageDetails) {
          this.showMailBoxUsageApiLoader = false;
        }
      },
      (err) => {
        this.showMailBoxUsageApiLoader = false;
      }
    );
    this.exchangeService.getEmailAppUsageDetails().subscribe(
      (res: any) => {
        //For EmailAppUsageDataTable
        this.emailAppUsageDetails = JSON.parse(res?.message)?.response;
        if (this.emailAppUsageDetails) {
          this.setEmailAppUsageArray(
            this.emailAppUsageDetails?.EmailUsage_Counts[0]
          );
          this.showEmailAppUsageApiLoader = false;
        }
      },
      (err) => {
        this.showEmailAppUsageApiLoader = false;
      }
    );
    this.exchangeService.getEmailAppVersionDetails().subscribe(
      (res: any) => {
        //For EmailAppVersionDataTable
        this.emailAppVersionDetails = JSON.parse(res?.message).response;
        if (this.emailAppVersionDetails) {
          this.setEmailAppVersionUsageArray(this.emailAppVersionDetails?.EmailAPPUsage_Version_Details[0])
          this.showEmailAppVersionApiLoader = false;
        }
      },
      (err) => {
        this.showEmailAppVersionApiLoader = false;
      }
    );

  }

  setMostLicenseCountArray(licenseCountData: object) {
    for (const i in licenseCountData) {
      const tempData = {
        name: i,
        value: licenseCountData[i],
      };
      this.licenseCountArray.push(tempData);
    }
  }

  setEmailAppUsageArray(data: object) {
    var emailAppUsageArray:Array<arrayType>= [];
    for (const i in data) {
      if (i == 'Report_Period' || i == 'Report_Refresh_Date') continue;       // Skipping Report_period and Report_Refresh_Date values
      let modifiedName = i.replace(/_/g, ' ');                                // Replaced '_' with ' ' e.g, POP3_APP to POP3 APP
      let temp = {
        name: modifiedName,
        value: data[i],
        iconName: this.emailAppIcons[modifiedName],
      };
      emailAppUsageArray.push(temp);
    }
    this.sortedEmailAppUsageArray = emailAppUsageArray.sort((a,b)=> b.value-a.value);         //Sorting in Descending order
  }
  setEmailAppVersionUsageArray(data: object) {
    var emailAppVersionUsageArray:Array<arrayType>= [];
    for (const i in data) {
      if (i == 'Report_Period' || i == 'Report_Refresh_Date') continue;       // Skipping Report_period and Report_Refresh_Date values
      let modifiedName = i.replace(/_/g, ' ');                                // Replaced '_' with ' ' e.g, OUTLOOK_2019 to OUTLOOK 2019
      let temp = {
        name: modifiedName,
        value: data[i],
        iconName: modifiedName=='Undetermined'?this.emailAppVersionIcons['Undetermined']:this.emailAppVersionIcons['Outlook'],
      };
      emailAppVersionUsageArray.push(temp);
    }
    this.sortedEmailAppVersionArray = emailAppVersionUsageArray.sort((a,b)=> b.value-a.value);  //Sorting in Descending order
  }

  activityTrendChart() {
    this.chartOptions = {
      chart: {
        type: 'line',
        height: 261,
      },
      title: {
        text: null,
      },
      subtitle: {
        text: null,
      },
      xAxis: {
        categories: ['30 Days', '90 Days', '180 Days'],
      },
      colors: ['#38A0BB', '#30D780', '#E5A53C'],
      legend: {
        layout: 'horizontal',
        align: 'right',
        verticalAlign: 'top',
      },
      yAxis: {
        title: {
          text: null,
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
          },
          enableMouseTracking: true,
        },
      },
      series: [
        {
          name: 'Mailbox',
          data: [
            this.mailBoxData?.total_mailboxes_thirty_days,
            this.mailBoxData?.total_mailboxes_ninety_days,
            this.mailBoxData?.total_mailboxes_180_days,
          ],
        },
        {
          name: 'Emails Sent',
          data: [
            this.mailBoxTraffic?.total_email_sent_thirty_days,
            this.mailBoxTraffic?.total_email_sent_ninety_days,
            this.mailBoxTraffic?.total_email_sent_one_eighty_days,
          ],
        },
        {
          name: 'Emails Recieved',
          data: [
            this.mailBoxTraffic?.total_email_received_thirty_days,
            this.mailBoxTraffic?.total_email_received_ninety_days,
            this.mailBoxTraffic?.total_email_received_one_eighty_days,
          ],
        },
      ],
    };
    var chart = Highcharts.chart('trend', this.chartOptions);
  }
}
