//const paymentUrl = 'https://rzp.io/l/';

export const environment = {
  production: false,
  environemntVersion : 'd_1.0.0',
  loginExpiryTimeInDays: 1,
  apiHostName: 'https://zv9p0wac6g.execute-api.ap-south-1.amazonaws.com/dev/api/v2/',

   // OnBoarding Subscription Pipeline URL
   onboardingPipelineApiUrl : "https://dev.azure.com/azuredevopswork-1/DevOps-Work/_build?definitionId=93",
   onboardingPipelineAuthorizationToken : "Basic YWF5dXNoOnlpeTVyamg3ZG1kZ3l0YXBuNm4yYXY0Z2pvN29lYW1ibGszdndkYTZ3aGIzcjdkZHZiZmE="

};
