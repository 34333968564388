<div id="layout-wrapper">
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <div class="navbar-brand-box">
          <a href="/dashboard" class="logo logo-light">
            <span class="logo-sm">
              <img
                src="/assets/images/global/svg/logo-light-sm.svg"
                alt=""
                height="22"
              />
            </span>
            <span class="logo-lg">
              <img
                src="/assets/images/global/svg/logo-light.svg"
                class="img-fluid"
                alt=""
              />
            </span>
          </a>
        </div>
        <button
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item waves-effect"
          (click)="toggleLeftNavBar($event)"
          id="vertical-menu-btn"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
        <div class="align-self-center">
          <span class="fw-semibold font-size-16">{{ pageName }}</span>
      </div>
      </div>
      <div class="d-flex">
        <div class="align-self-center" >
          <!-- <i class="mdi mdi-microsoft-azure text-primary  d-none d-xl-inline-block rounded-circle subscription-name"></i> -->
          <span class="d-none d-xl-inline-block me-3 font-size-15" key="t-henry"> <b>Tenant Name :</b> {{ tenantName }}</span>
      </div>
      </div>
   </div>
  </header>
  </div>
