import { LicensesComponent } from './modules/features/components/licenses/licenses.component';
import { AzureAdComponent } from './modules/features/components/azure-ad/azure-ad.component';
import { ExchangeComponent } from './modules/features/components/exchange/exchange.component';
import { TeamsComponent } from './modules/features/components/teams/teams.component';
import { SecurityComponent } from './modules/features/components/security/security.component';
import { ExecutiveSummaryComponent } from './modules/features/components/executive-summary/executive-summary.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/core/components/login/login.component';
import { SignupComponent } from './modules/core/components/signup/signup.component';
import { SubscriptionListComponent } from './modules/core/components/subscription-list/subscription-list.component';
import { DashboardComponent } from './modules/features/components/dashboard/dashboard.component';
import { ErrorpageComponent } from './modules/shared/components/errorPage/errorPage.component';
import { PrivacyPolicyComponent } from './modules/shared/components/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './modules/shared/components/terms&conditions/terms&conditions.component';
import { SharePointComponent } from './modules/features/components/sharepoint/sharepoint.component';
import { ConfirmUserComponent } from './modules/core/components/confirm-user/confirm-user.component';
import { CreateOrganizationComponent } from './modules/core/components/create-organization/create-organization.component';
import { ReportComponent } from './modules/features/components/report/report.component';
import { SecurityAlertDetailsComponent } from './modules/features/components/security-alert-details/security-alert-details.component';
import { AdministratorsComponent } from './modules/features/components/administrators/administrators.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent},
  { path: 'signup', component: SignupComponent},
  { path: 'confirm-user',component:ConfirmUserComponent},
  { path: 'create-organization',component:CreateOrganizationComponent},
  { path: 'privacy-policy', component: PrivacyPolicyComponent},
  { path: 'terms&conditions', component: TermsConditionsComponent},
  { path: 'subscription-list', component: SubscriptionListComponent},
  { path: 'dashboard', component:DashboardComponent},
  { path: 'executiveSummary' , component : ExecutiveSummaryComponent},
  { path: 'security' , component : SecurityComponent },
  { path: 'teams'  , component : TeamsComponent},
  { path: 'exchange' , component : ExchangeComponent},
  { path: 'azureAD' , component : AzureAdComponent},
  { path: 'sharePoint'  , component : SharePointComponent},
  { path: 'report', component: ReportComponent },
  { path: 'security-alert-details' , component : SecurityAlertDetailsComponent},
  { path: 'licenses' , component : LicensesComponent},
  { path: 'administrators', component: AdministratorsComponent, data: { breadcrumb: 'Administrators' } },
  { path: '**', component: ErrorpageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
