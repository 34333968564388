import { MessageConstantsService } from './../../../shared/utilities/messageConstants.service';
import {
  NgbModal,
  NgbModalOptions,
  ModalDismissReasons,
} from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';
import { userAuthService } from 'src/app/modules/shared/services/user-auth.service';
import { FunctionsService } from 'src/app/modules/shared/utilities/functions.service';
import { SubscriptionListService } from '../../services/subscription-list.service';

interface arrayType{
  tenantId,
  tenantName,
  tenantOrganizationId ,
  tenantOrganizationName
}
@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.scss'],
})
export class SubscriptionListComponent implements OnInit {
  closeResult: any;
  subscriptionsDetails: Array<arrayType> = [];
  modalOptions: NgbModalOptions;
  li_user_name!: string;
  showLoader : boolean = true;
  org_name : string = this.cookie.get('_o_name');

  constructor(
    private userAuthService: userAuthService,
    private modalService: NgbModal,
    private cookie: CookieService,
    private subsService: SubscriptionListService,
    private utilFunctionService: FunctionsService,
    public messageConstantsService : MessageConstantsService
  ) {
      this.subsService
      .getSubscriptionDetailsData({
        user_id: this.cookie.get('_u_id'),
      })
      .subscribe((res: any) => {
        this.showLoader = false;
        const subscriptionsList = JSON.parse(res?.message);
        for (const index in subscriptionsList) {
          const subs = {
            tenantId: subscriptionsList[index]?.TenantId,
            tenantName: subscriptionsList[index]?.TenantName,
            tenantOrganizationId: subscriptionsList[index]?.OrganizationId,
            tenantOrganizationName: subscriptionsList[index]?.OrganizationName,
          };
          this.subscriptionsDetails.push(subs);
        }
      },(err)=>{
        this.showLoader = false;
      }
      );

    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      centered: true,
      size: 'lg',
    };
  }

  setTokens(tenantId: string, tenantName: string, tenantOrgId:number, tenantOrgName:string) {
    // _tnt_id cookie : Tenant ID
    // _tnt_name cookie : Tenant Name
    // _tnt_org_id cookie : Tenant's Organization Id
    // _tnt_org_name cookie : Tenant's Organization Name

    this.utilFunctionService.setNewCookies([
      { name: '_tnt_id', value: tenantId },
      { name: '_tnt_name', value: tenantName },
      { name: '_tnt_org_id', value: tenantOrgId },
      { name: '_tnt_org_name', value: tenantOrgName },

    ]);
    window.location.replace('/dashboard');
  }

  onBoardNewSubscription(content: any) {
    this.modalService.open(content, this.modalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    if (this.cookie.get('full_name') == null)
      this.li_user_name = this.cookie.get('_u_name');
    else this.li_user_name = this.cookie.get('full_name');
  }

  logout() {
    this.userAuthService.logout();
    window.location.replace('/');
  }

  refresh(){
    window.location.reload();
  }
}
