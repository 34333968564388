<app-header pageName="Teams"></app-header>
<app-right-nav-bar></app-right-nav-bar>
<div id="layout-wrapper">
  <app-side-menu ></app-side-menu>
  <div class="main-content">
    <div class="page-content organization">
      <div class="container-fluid">
        <div class="row">
                <div class="col-xl-6">
                  <div class="card border-0">
                    <app-loader *ngIf="showTeamsDataApiLoader || showTeamsChannelApiLoader || showTeamsCallsApiLoader"></app-loader>
                    <div class="card-body">
                      <h5 class="card-title2 officeboard_heading mb-4"><span>Insights</span></h5>
                      <div class="row">
                        <div class="col-12 col-sm-6 vertical_line pr-3">
                          <div class="teams_insights">
                            <p class=" mb-0 fw-bold mb-1">Teams -  {{teamsData?.total_teams_count}}</p>
                            <div class="position-relative mb-2 d-flex ">
                              <div class="position-absolute z_index w-100 d-flex">
                                <span class="mb-0">Public - {{teamsData?.total_public_teams}}</span>
                                <span class="mb-0 ms-auto">Private - {{teamsData?.total_private_teams}}</span>
                              </div>
                            </div>
                            <div id="insights" class="mx-n2 charts_h" *ngIf="teamsData">
                              <app-progressive-bar-chart class="display-inh" [data1]="teamsData?.total_public_teams"
                              [data2]="teamsData?.total_private_teams" [data1Title]="'Public'" [data2Title]="'Private'"></app-progressive-bar-chart>
                            </div>
                          </div>
                          <div class="teams_insights mt-4">
                            <p class=" mb-0 fw-bold mb-1">Channels - {{teamsChannelData?.total_channels}}</p>
                            <div class="position-relative mb-2 d-flex ">
                              <div class="position-absolute z_index w-100 d-flex">
                                <span class="mb-0">Standard - {{teamsChannelData?.total_public_channels}}</span>
                                <span class="mb-0 ms-auto">Private - {{teamsChannelData?.total_private_channels}}</span>
                              </div>
                            </div>
                            <div id="insights_1" class="mx-n2 charts_h" *ngIf="teamsChannelData">
                              <app-progressive-bar-chart class="display-inh"  [data1]="teamsChannelData?.total_public_channels"
                              [data2]="teamsChannelData?.total_private_channels" [data1Title]="'Standard'" [data2Title]="'Private'"></app-progressive-bar-chart>
                            </div>
                          </div>
                          <div class="teams_insights mt-4">
                            <p class=" mb-0 fw-bold mb-1">Calls - {{teamsCallsData?.calls_details?.total_pstn_calls_thirty_days+teamsCallsData?.calls_details?.total_direct_routing_calls_thirty_days}}</p>
                            <div class="position-relative mb-2 d-flex ">
                              <div class="position-absolute z_index w-100 d-flex">
                                <span class="mb-0">PSTN - {{teamsCallsData?.calls_details?.total_pstn_calls_thirty_days}}</span>
                                <span class="mb-0 ms-auto">Direct Routing - {{teamsCallsData?.calls_details?.total_direct_routing_calls_thirty_days}}</span>
                              </div>
                            </div>
                            <div id="insights_2" class="charts_h" *ngIf="teamsCallsData">
                              <app-progressive-bar-chart class="mx-n2 display-inh"[data1]="teamsCallsData?.calls_details?.total_pstn_calls_thirty_days"
                              [data2]="teamsCallsData?.calls_details?.total_direct_routing_calls_thirty_days"  [data1Title]="'PSTN'" [data2Title]="'Direct Routing'"></app-progressive-bar-chart>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 pl-3">
                          <div class="flex-wrap pt-2">
                            <div class="d-flex mb-3">
                              <div class="avatar-xs_2 me-3">
                                <span class="avatar-title rounded-circle bg-white">
                                  <i class="mdi mdi-clock-outline display-6 text-primary"></i>
                                </span>
                              </div>
                              <div class="align-self-center">
                                <p class=" mb-0 fw-bold">{{teamsCallsData?.calls_details?.total_duration}}</p>
                                <p class="mb-0">Duration of Calls</p>
                              </div>
                            </div>
                          </div>
                          <div class="flex-wrap my-2">
                            <div class="d-flex mb-3">
                              <div class="avatar-xs_2 me-3">
                                <span class="avatar-title rounded-circle bg-white">
                                  <i class="mdi mdi-google-maps display-6 text_green"></i>
                                </span>
                              </div>
                              <div class="align-self-center">
                                <h5 class=" mb-0 font-weight-semibold">{{mostCallingDestinationArray?.length || '0'}}</h5>
                                <p class="mb-0">Countries Called</p>
                              </div>
                            </div>
                          </div>
                          <div class="flex-wrap my-2">
                            <div class="d-flex mb-3">
                              <div class="avatar-xs_2 me-3">
                                <span class="avatar-title rounded-circle bg-white">
                                  <i class="mdi mdi-account display-6 text-muted"></i>
                                </span>
                              </div>
                              <div class="align-self-center">
                                <p class=" mb-0 fw-bold">{{MostCallingUsersArray[0]?.value || '0'}}</p>
                                <p class="mb-0 d-flex"><b class="text-truncate w-38" placement="top" container="body" triggers="hover" [autoClose]="'outside'"  ngbTooltip='{{MostCallingUsersArray[0]?.name}}' class="text-truncate w-38">{{MostCallingUsersArray[0]?.name || 'N/A'}}</b> - Top Caller</p>
                              </div>
                            </div>
                          </div>
                          <div class="flex-wrap">
                            <div class="d-flex">
                              <div class="avatar-xs_2 me-3">
					             <span class="avatar-title rounded-circle bg-white">
                                  <i class="mdi mdi-phone-in-talk display-6 text_dark_green"></i>
                                </span>
                              </div>
                              <div class="align-self-center">
                                <p class=" mb-0 fw-bold">{{teamsCallsData?.calls_details?.longest_direct_routing_call}} <i
                                    class="fas fa-info-circle text_dark_green" data-toggle="tooltip" data-placement="top"
                                    title="Tooltip on top"></i></p>
                                <p class="mb-0">Longest Call</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xl-3 d-flex">
                  <div class="card border-0 w-100">
                    <app-loader *ngIf="showTeamsCallsApiLoader"></app-loader>
                    <div class="card-body">
                      <h5 class="card-title2 officeboard_heading mb-4"><span>Countries</span> - Most Calls</h5>
                      <div class="row">
                        <div class="col-12" *ngFor="let item of mostCallingDestinationArray | slice:0:5">
                          <div class="d-flex align-items-center border-bottom pb-3">
                            <div class="avatar-xs mx-1 d-flex align-self-center">
                              <img width="22" alt="" class="img-fluid" src="./assets/images/features/teams/{{item?.iconName}}.svg">
                            </div>
                            <p class="mb-0">{{item?.name}}</p>
                            <div class="ms-auto ">
                              <p class="mb-0 fw-bold me-2 text-primary">{{item?.value}}</p>
                            </div>
                          </div>
                        </div>
                     </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xl-3 d-flex">
                  <div class="card border-0 w-100">
                    <app-loader *ngIf="showTeamsCallsApiLoader"></app-loader>
                    <div class="card-body ">
                      <h5 class="card-title2 officeboard_heading mb-4"><span>Users</span> - Most Calls</h5>
                      <div class="users_most_alerts">
                        <div class="d-flex pb-3 align-self-center" *ngFor="let users of MostCallingUsersArray | slice:0:6" >
                          <p class="fw-bold mb-0 me-2 text-primary w_1_7">{{users?.value}} </p>
                          <i class="mdi mdi-minus font-size-lg my-auto text-muted"></i>
                          <p class="mb-0 ms-2 text-truncate" placement="top" container="body" triggers="hover" [autoClose]="'outside'"  ngbTooltip='{{users?.name}}'> {{users?.name}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="row">
              <div class="col-xl-6 d-flex">
                <div class="card border-0 w-100">
                  <app-loader *ngIf="showTeamsDeviceApiLoader"></app-loader>
                  <div class="card-body ">
                    <h5 class="card-title2 officeboard_heading mb-4"><span>Device</span> Usage</h5>
                    <div class="table-responsive device_usage">
                      <table class="table table-striped mb-0 border-0">
                        <thead class="table-light border-0">
                          <tr>
                            <th>Device</th>
                            <th>Avg</th>
                            <th>Min</th>
                            <th>Max</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr >
                            <td><span class="width-30 d-inline-block text-center"><img
                                  src="./assets/images/features/teams/windows.svg"></span>Windows</td>
                            <td>{{teamsDeviceUsageData?.devices_details_data?.average?.Windows}}</td>
                            <td>{{teamsDeviceUsageData?.devices_details_data?.min?.Windows}}</td>
                            <td>{{teamsDeviceUsageData?.devices_details_data?.max?.Windows}}</td>
                          </tr>
                          <tr>
                            <td><span class="width-30 d-inline-block text-center"><img
                              src="./assets/images/features/teams/android.svg"></span>Android Phone</td>
                              <td>{{teamsDeviceUsageData?.devices_details_data?.average?.Android_Phone}}</td>
                              <td>{{teamsDeviceUsageData?.devices_details_data?.min?.Android_Phone}}</td>
                              <td>{{teamsDeviceUsageData?.devices_details_data?.max?.Android_Phone}}</td>
                          </tr>
                          <tr>
                            <td><span class="width-30 d-inline-block text-center"><img
                              src="./assets/images/features/teams/apple.svg"></span>iOS</td>
                              <td>{{teamsDeviceUsageData?.devices_details_data?.average?.iOS}}</td>
                              <td>{{teamsDeviceUsageData?.devices_details_data?.min?.iOS}}</td>
                              <td>{{teamsDeviceUsageData?.devices_details_data?.max?.iOS}}</td>
                          </tr>
                          <tr>
                            <td><span class="width-30 d-inline-block text-center"><img
                              src="./assets/images/features/teams/chrome.svg"></span>Web</td>
                              <td>{{teamsDeviceUsageData?.devices_details_data?.average?.Web}}</td>
                              <td>{{teamsDeviceUsageData?.devices_details_data?.min?.Web}}</td>
                              <td>{{teamsDeviceUsageData?.devices_details_data?.max?.Web}}</td>
                          </tr>
                          <tr>
                            <td><span class="width-30 d-inline-block text-center"><img
                              src="./assets/images/features/teams/laptop.svg"></span>Mac</td>
                              <td>{{teamsDeviceUsageData?.devices_details_data?.average?.Mac}}</td>
                              <td>{{teamsDeviceUsageData?.devices_details_data?.max?.Mac}}</td>
                              <td>{{teamsDeviceUsageData?.devices_details_data?.min?.Mac}}</td>
                          </tr>
                          <tr>
                            <td><span class="width-30 d-inline-block text-center"><img
                              src="./assets/images/features/teams/phone.svg"></span> Windows Phone</td>
                              <td>{{teamsDeviceUsageData?.devices_details_data?.average?.Windows_Phone}}</td>
                              <td>{{teamsDeviceUsageData?.devices_details_data?.max?.Windows_Phone}}</td>
                              <td>{{teamsDeviceUsageData?.devices_details_data?.min?.Windows_Phone}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 d-flex">
                <div class="card border-0 w-100">
                  <app-loader *ngIf="showTeamsActivityApiLoader"></app-loader>
                  <div class="card-body">
                    <div class="d-flex flex-wrap align-items-start">
                      <h5 class="card-title2 officeboard_heading mb-4"><span>Activity </span></h5>
                      <div class="ms-auto">
                        <ul class="nav nav-tabs activity_tabs" id="myTab" role="tablist">
                          <li class="nav-item" role="presentation">
                            <a class="nav-link active" id="activity-tab" data-bs-toggle="tab" href="#activity_graph" role="tab"
                              aria-controls="activity" aria-selected="true">Graph</a>
                          </li>
                          <li class="nav-item" role="presentation">
                            <a class="nav-link" id="table-tab" data-bs-toggle="tab" href="#activity_table" role="tab"
                              aria-controls="profile" aria-selected="false">Table</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="activity_graph" role="tabpanel" aria-labelledby="activity-tab">
                        <div id="activity"></div>
                      </div>
                      <div class="tab-pane fade" id="activity_table" role="tabpanel" aria-labelledby="table-tab">
                        <div class="table-responsive device_usage">
                          <table class="table table-striped mb-0 border-0">

                            <thead class="table-light border-0">
                              <tr>
                                <th>Activity</th>
                                <th>30 Days</th>
                                <th>90 Days</th>
                                <th>180 Days</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Private Messages</td>
                                <td>{{teamsChatData?.sum_thirty_days_data?.Private_Chat_Messages}}</td>
                                <td>{{teamsChatData?.sum_ninety_days_data?.Private_Chat_Messages}}</td>
                                <td>{{teamsChatData?.sum_one_hundred_eighty_days_data?.Private_Chat_Messages}}</td>
                              </tr>
                              <tr>
                                <td>Calls</td>
                                <td>{{teamsChatData?.sum_thirty_days_data?.Calls}}</td>
                                <td>{{teamsChatData?.sum_ninety_days_data?.Calls}}</td>
                                <td>{{teamsChatData?.sum_one_hundred_eighty_days_data?.Calls}}</td>
                              </tr>
                              <tr>
                                <td>Team Message</td>
                                <td>{{teamsChatData?.sum_thirty_days_data?.Team_Chat_Messages}}</td>
                                <td>{{teamsChatData?.sum_ninety_days_data?.Team_Chat_Messages}}</td>
                                <td>{{teamsChatData?.sum_one_hundred_eighty_days_data?.Team_Chat_Messages}}</td>
                              </tr>
                              <tr>
                                <td>Meetings</td>
                                <td>{{teamsChatData?.sum_thirty_days_data?.Meetings}}</td>
                                <td>{{teamsChatData?.sum_ninety_days_data?.Meetings}}</td>
                                <td>{{teamsChatData?.sum_one_hundred_eighty_days_data?.Meetings}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <app-footer></app-footer>
          </div>
    </div>
</div>
</div>
