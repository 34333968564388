 <header id="page-topbar">
    <div class="navbar-header">
        <div class="d-flex">
            <div class="navbar-brand-box organization">
              <a href="#" class="logo logo-light">
                <span class="logo-sm">
                    <img src="/assets/images/global/svg/logo-light-sm.svg" class="img-fluid" alt="" height="22">
                </span>
                <span class="logo-lg">
                    <img src="/assets/images/global/svg/logo-light.svg" class="img-fluid" alt="">
                </span>
            </a>
            </div>
        </div>
    </div>
  </header>
    <div class="main-content subscription_list me-0">
        <div class="page-content">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0 font-size-18">Privacy Policies</h4>
                        </div>
                    </div>
                </div>
                <div class="card card-body form-group">
                    <div class="row m-5 text-justify">
                        <div class="col-md-12">
                            <h6 class="text-muted">Updated November 11, 2019</h6>
                        </div>
                        <div class="col-md-12">
                            <h4 class="font-weight-semibold">Your Privacy XCNimbus</h4>
                        </div>
                        <div class="col-md-12">
                            <h6 class="font-weight-semibold">By using our Services, you consent to our use of your data
                                under this Privacy Policy.</h6>
                        </div>

                        <div class="col-md-12">
                            <h4 class="mt-4">1) Introduction</h4>
                            <p>
                                Our registered users (“Members”) share their professional identities, share peer
                                feedback knowledge and professional insights, and view relevant content. Content on some
                                of our services is available to non-members (“Visitors”).
                            </p>

                            <h4 class="mt-4">2) Data Controllers</h4>
                            <p>
                                You are entering into the User Agreement with XContent Business Solutions (Pty) Ltd.,
                                who will be responsible for your personal data provided to, or collected by or for, our
                                Services.
                            </p>

                            <h4 class="mt-4">3) Services</h4>
                            <p>
                                This Privacy Policy applies to XCNimbus, XCNimbus-related sites, apps, communications
                                and services (“Services”), but excluding services that state that they are offered under
                                a different privacy policy.
                            </p>

                            <h4 class="mt-4">4) Consent </h4>
                            <p>
                                If you use our Services, you consent to the collection, use and sharing of your personal
                                data under this Privacy Policy and agree to the User Agreement.
                            </p>

                            <h4 class="mt-4">5) Change </h4>
                            <p>
                                We may modify this Privacy Policy, and if we make material changes to it, we will
                                provide notice through our Services, or by other means, to provide you the opportunity
                                to review the changes before they become effective. Your continued use of our Services
                                after we publish or send a notice about our changes to this Privacy Policy means that
                                you are consenting to the updated Privacy Policy.
                            </p>

                            <h3 class="mt-4">Information We Collect </h3>
                            <h4 class="mt-4">Information You Provide To Us </h4>
                            <h4 class="mt-4">Registration</h4>
                            <p>
                                To create an account you provide data including your name, mobile number and/or email
                                address, cloud platform details and optionally a password or passcode.
                            </p>

                            <h4 class="mt-4">Profile</h4>
                            <p>
                                You have choices about the information on your profile. It’s your choice whether to
                                include sensitive information on your profile. Please do not post or add personal data
                                to your profile that you would not want to be publicly available.
                            </p>

                            <h4 class="mt-4">Service Use</h4>
                            <p>
                                We log usage data when you visit or otherwise use our Services, including our sites and
                                app, such as when you view or click on content. We use log-ins, cookies, device
                                information and internet protocol (“IP”) addresses to identify you and log your use.
                            </p>

                            <h4 class="mt-4">Cookies, Web Beacons and Other Similar Technologies</h4>
                            <p>
                                We use cookies and similar technologies (e.g., web beacons, pixels, ad tags and device
                                identifiers) to recognize you and/or your device(s) on, off and across different
                                Services and devices. We also allow some others to use cookies. You can control cookies
                                through your browser settings and other tools.
                            </p>

                            <h4 class="mt-4">Your Device and Location</h4>
                            <p>
                                When you visit or leave our Services, we receive the URL of both the site you came from
                                and the one you go to next. We also get information about your IP address, proxy server,
                                operating system, web browser and add-ons, device identifier and features, and/or ISP or
                                your mobile carrier. If you use our Services from a mobile device, that device will send
                                us data about your location. Most devices allow you to prevent location data from being
                                sent to us and we honor your settings.
                            </p>

                            <h4 class="mt-4">Other</h4>
                            <p>
                                Our Services are dynamic and we often introduce new features, which may require the
                                collection of new information. If we collect materially different personal data or
                                materially change how we use your data, we will notify you and may also modify this
                                Privacy Policy.
                            </p>
                            <h4 class="mt-4">How We Use Your Data</h4>
                            <p>
                                We use the data that we have about you to provide, support, personalize and make our
                                Services more relevant and useful to you and others.
                            </p>
                            <h4 class="mt-4">Services</h4>
                            <p>
                                We use your data to authenticate you and authorize access to our Services.
                            </p>

                            <h4 class="mt-4">Communications</h4>
                            <p>
                                We will contact you through mobile, email, notices posted on our websites or apps, and
                                other ways through our Services, including text messages and push notifications. We will
                                send you messages about the availability of our Services, security, or other
                                service-related issues. We also send messages about how to use the Services, network
                                updates, reminders, suggestions and promotional messages from us. You may change your
                                communication preferences at any time. Please be aware that you cannot opt out of
                                receiving service messages from us, including security and legal notices.
                            </p>

                            <h4 class="mt-4">Marketing</h4>
                            <p>
                                We use data and content about Members for invitations and communications promoting
                                membership and network growth, engagement and our Services.
                            </p>

                            <h4 class="mt-4">Developing Services and Research </h4>
                            <h4 class="mt-4">Service Development</h4>
                            <p>
                                We use data, including public feedback, to conduct research and development for the
                                further development of our Services in order to provide you and others with a better,
                                more intuitive and personalized experience, drive membership growth and engagement on
                                our Services, and help connect professionals to each other and to economic opportunity.
                            </p>
                            <h4 class="mt-4">Surveys</h4>
                            <p>
                                Polls and surveys are conducted by us and others through our Services. You are not
                                obligated to respond to polls or surveys and you have choices about the information you
                                provide.
                            </p>
                            <h4 class="mt-4">Customer Support</h4>
                            <p>
                                We use the data (which can include your communications) needed to investigate, respond
                                to and resolve complaints and Service issues (e.g., bugs, feature requests).
                            </p>
                            <h4 class="mt-4">Aggregate Insights</h4>
                            <p>
                                We use your information to produce aggregate insights that do not identify you. For
                                example we may use your data to generate statistics about our users, their profession or
                                industry, the number of ad impressions served or clicked on, or the demographic
                                distribution of visitors to a site.
                            </p>
                            <h4 class="mt-4">Security and Investigations</h4>
                            <p>
                                We use your data (including your communications) if we think it’s necessary for security
                                purposes or to investigate possible fraud or other violations of our User Agreement or
                                this Privacy Policy and/or attempts to harm our Members or Visitors.
                            </p>
                            <h4 class="mt-4">How We Share Information</h4>
                            <h4 class="mt-4">Legal Disclosures</h4>
                            <p>
                                It is possible that we will need to disclose information about you when required by law,
                                subpoena, or other legal process or if we have a good faith belief that disclosure is
                                reasonably necessary to (1) investigate, prevent, or take action regarding suspected or
                                actual illegal activities or to assist government enforcement agencies; (2) enforce our
                                agreements with you, (3) investigate and defend ourselves against any third-party claims
                                or allegations, (4) protect the security or integrity of our Service (such as by sharing
                                with companies facing similar threats); or (5) exercise or protect the rights and safety
                                of XCNimbus, our Members, personnel, or others. We attempt to notify Members about legal
                                demands for their personal data when appropriate in our judgment, unless prohibited by
                                law or court order or when the request is an emergency. We may dispute such demands when
                                we believe, in our discretion, that the requests are overbroad, vague or lack proper
                                authority, but we do not promise to challenge every demand.
                            </p>
                            <h4 class="mt-4">Change in Control or Sale</h4>
                            <p>
                                We can also share your personal data as part of a sale, merger or change in control, or
                                in preparation for any of these events. Any other entity which buys us or part of our
                                business will have the right to continue to use your data, but only in the manner set
                                out in this Privacy Policy unless you agree otherwise.
                            </p>
                            <h4 class="mt-4">Other Important Information</h4>
                            <h4 class="mt-4">Security</h4>
                            <p>
                                We implement security safeguards designed to protect your data, such as HTTPS. We
                                regularly monitor our systems for possible vulnerabilities and attacks. However, we
                                cannot warrant the security of any information that you send us. There is no guarantee
                                that data may not be accessed, disclosed, altered, or destroyed by breach of any of our
                                physical, technical, or managerial safeguards.
                            </p>
                            <h4 class="font-weight-semibold">How To Contact Us</h4>
                            <h4 class="mt-2">If you want to send us a note, please contact us at <a
                                    href="mailto:portal@xcnimbus.com">portal@xcnimbus.com</a>.</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>
