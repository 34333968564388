import { FunctionsService } from './../../../shared/utilities/functions.service';
import { ExecutiveSummaryService } from './../../services/executive-summary.service';
import { Component } from '@angular/core';
import { AzureAdService } from '../../services/azureAd.service';
import { SecurityService } from '../../services/security.service';
import { SharepointService } from '../../services/sharepoint.service';
import { TeamsService } from '../../services/teams.service';
import * as Highcharts from 'highcharts';
import { ExchangeService } from '../../services/exchange.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  securityAlerts;
  securityScores;
  teamsData;
  teamsChannelData;
  teamsCallsData;
  eachSiteDetails;
  activeSharepoint;
  totalSharepoint;
  sharepointSitesinfo;
  totalAdUser;
  totalGroups;
  passwordExpires;
  totalMembers:any;
  MFAEnabledUsers;
  globalAdminsData;
  deletedUsers;
  adminUsers;
  exchangeActiveInactive;
  secureScorePercentage;
  highSeverityPercentage;
  totalSeverity = 0;
  highSeverity = 0;
  activeMailboxPercentage;
  authMethodArray:any=[];
  pageNumber=1;

  // Loader Variables starts
  showSecurityScoreApiLoader : boolean = true;
  showTotalAdUsersApiLoader : boolean = true;
  showSecurityAlertsApiLoader : boolean = true;
  showTotalMembersApiLoader : boolean = true;
  showTotalGroupsApiLoader : boolean = true;
  showGlobalAdminsApiLoader : boolean = true;
  showMfaEnabledUsersApiLoader : boolean = true;
  showDeletedUsersApiLoader : boolean = true;
  showTeamsDataApiLoader : boolean = true;
  showTeamsChannelApiLoader : boolean=true;
  showTeamsCallsApiLoader  : boolean=true;
  showEachSiteApiLoader : boolean=true;
  showActiveSharePointApiLoader : boolean=true;
  showTotalSharePointApiLoader : boolean=true;
  showSharePointSitesInfoApiLoader : boolean=true;
  showMailBoxDataApiLoader : boolean=true;
  showAuthMethodApiLoader : boolean = true;
  // Loader Variables Ends

  authMethodsDetails = {
    mobilePhone : {
      desc :  'A phone can be used by a user to authenticate using SMS or voice calls as allowed by policy.',
      icon : 'mdi-cellphone-android'
    },
    softwareOneTimePasscode : {
     desc : 'Allow users to perform multifactor authentication using an application that supporters the OATH TOTP specification and provides a one-time code.',
     icon : 'mdi-onepassword'
    },
    microsoftAuthenticatorPush : {
    desc : 'Microsoft Authenticator can be used by a user to sign-in or perform multi-factor authentication to Azure AD',
    icon : 'mdi-shield-lock-outline' },
    windowsHelloForBusiness : {
    desc : 'Windows Hello for Business is a passwordless sign-in method on Windows devices.',
    icon : 'mdi-microsoft-windows'},
    email : {
    desc : 'An email address can be used by a user as part of the Self-Service Password Reset (SSPR) process.',
    icon : 'mdi-email'},
    alternateMobilePhone : {
    desc : 'A phone can be used by a user to authenticate using SMS or voice calls as allowed by policy.',
    icon : 'mdi-deskphone'},
    password :{
    desc :  'A password is currently the default primary authentication method in Azure AD.',
    icon : 'mdi-account-lock'},
    officePhone :{
    desc : 'A phone can be used by a user to authenticate using SMS or voice calls as allowed by policy.',
    icon : 'mdi-phone-classic'},
    hardwareOneTimePasscode : {
    desc : 'Allow users to perform multifactor authentication using a physical device that provides a one-time code.',
    icon : 'mdi-onepassword'},
    microsoftAuthenticatorPasswordless : {
    desc :'',
    icon : 'mdi-account-lock'},
    fido2SecurityKey : {
    desc : 'A FIDO2 Security Key can be used by a user to sign-in to Azure AD.',
    icon : 'mdi-shield-key-outline'},
    temporaryAccessPass : {
    desc : 'A time-limited passcode that serves as a strong credential and allows onboarding of passwordless credentials.',
    icon : 'mdi-microsoft-access'},
    securityQuestion :{
    desc : 'Allow users to validate their identity when performing a self-service password reset.',
    icon : 'mdi-lock-question'}
  }

  constructor(private securityService: SecurityService,
    private officeboardTeamsService: TeamsService,
    private sharepointService: SharepointService,
    private azureAdService: AzureAdService,
    private exchangeService: ExchangeService,
    private executiveSummaryService : ExecutiveSummaryService,
    public functionsService : FunctionsService) {
      this.executiveSummaryService.getGlobalAdminsData().subscribe((res: any) => {
        this.globalAdminsData = JSON.parse(res?.message).response;
        if(this.globalAdminsData){
         this.showGlobalAdminsApiLoader = false;
        }
      },(err)=>{
        this.showGlobalAdminsApiLoader = false;
      });
    this.securityService.getSecurityAlerts().subscribe((res: any) => {
      this.securityAlerts = JSON.parse(res?.message).response;
      if(Object.keys(this.securityAlerts).length>0){
        for (const i in this.securityAlerts?.severity_count){
            this.totalSeverity+=this.securityAlerts?.severity_count[i]           //Calculating total severity count
        }
        this.highSeverity =  this.securityAlerts?.severity_count?.high ?? 0;   //If there is no high severity count we will consider it 0.
        this.highSeverityPercentage = this.functionsService.setPercentage(this.highSeverity,this.totalSeverity).toFixed(0)+'%' ;
        this.showSecurityAlertsApiLoader = false;
      }
      else
      {
        this.showSecurityAlertsApiLoader = false;
      }

    },(err)=>{
      this.showSecurityAlertsApiLoader = false;
    });
    this.securityService.getSecurityScores().subscribe((res: any) => {
      this.securityScores = JSON.parse(res?.message)?.response;
      if(Object.keys(this.securityScores).length>0){
        this.secureScorePercentage = this.functionsService.setPercentage(this.securityScores?.thirty_days_current_score , this.securityScores?.thirty_days_max_Score).toFixed(0)+'%';
        this.showSecurityScoreApiLoader = false;
      }else{
        this.showSecurityScoreApiLoader = false;
      }
    },(err)=>{
      this.showSecurityScoreApiLoader = false;
    });

    this.securityService.getAuthMethods().subscribe((res:any)=>{
      let tempArray:any=[];
      const response = JSON.parse(res?.message)?.response;
      response?.auth_methods_data.forEach(element => {
        if(element?.userCount!=0) //We do not want to display auth methods with count = 0.
        {
          const temp = {
          methodName : this.functionsService.formatTitle(this.functionsService.makeFirstLetterCapital(element?.authenticationMethod)), //Capitalizing first word and formatting the whole text.
          description : this.authMethodsDetails[element?.authenticationMethod].desc,
          userCount : element?.userCount,
          icon : this.authMethodsDetails[element?.authenticationMethod].icon
        }
        tempArray.push(temp);
      }
      });
      this.authMethodArray = tempArray.sort((a,b)=>b.userCount-a.userCount);    //Sorting array in descending order.

      this.showAuthMethodApiLoader = false;
    },(err)=>{
      this.showAuthMethodApiLoader = false;
    })

    this.officeboardTeamsService.getTeamsData().subscribe((res: any) => {
      var resMessage= res?.message.replace(/: NaN,/g,": 0,");
      this.teamsData = JSON.parse(resMessage)?.response;
      if(this.teamsData){
        this.showTeamsDataApiLoader = false;
      }
    },(err)=>{
      this.showTeamsDataApiLoader = false;

    });
    this.officeboardTeamsService.getTeamsChannel().subscribe((res: any) => {
      this.teamsChannelData = JSON.parse(res?.message)?.response;
       if(this.teamsChannelData){
        this.showTeamsChannelApiLoader = false;
      }
    },(err)=>{
      this.showTeamsChannelApiLoader = false;
    });
    this.officeboardTeamsService.getTeamsCallsData().subscribe((res: any) => {
      this.teamsCallsData = JSON.parse(res?.message)?.response;
      if(this.teamsCallsData){
        this.showTeamsCallsApiLoader = false;
      }
    },(err)=>{
      this.showTeamsCallsApiLoader = false;
    });
    this.sharepointService.getEachSiteDetails().subscribe((res: any) => {
      this.eachSiteDetails = JSON.parse(res?.message);
      if(this.eachSiteDetails){
        this.showEachSiteApiLoader = false;
      }
    },(err)=>{
      this.showEachSiteApiLoader = false;
    });
    this.sharepointService.getActiveSharepoint().subscribe((res: any) => {
      this.activeSharepoint = JSON.parse(res?.message)?.response;
      if(this.activeSharepoint){
        this.showActiveSharePointApiLoader = false;
      }
    },(err)=>{
      this.showActiveSharePointApiLoader = false;
    });
    this.sharepointService.getTotalSharepoint().subscribe((res: any) => {
      this.totalSharepoint = JSON.parse(res?.message);
      if(this.totalSharepoint){
        this.showTotalSharePointApiLoader = false;
      }
    },(err)=>{
      this.showTotalSharePointApiLoader = false;
    });
    this.sharepointService.getSharepointSitesinfo().subscribe((res: any) => {
      this.sharepointSitesinfo = JSON.parse(res?.message);
      if(this.sharepointSitesinfo){
        this.showSharePointSitesInfoApiLoader = false;
      }
    },(err)=>{
      this.showSharePointSitesInfoApiLoader = false;
    });
    this.azureAdService.getTotalAdUsers().subscribe((res: any) => {
      this.totalAdUser = JSON.parse(res?.message)?.response;
      if(this.totalAdUser){
        this.showTotalAdUsersApiLoader = false;
      }
    },(err)=>{
      this.showTotalAdUsersApiLoader = false;
    });

    this.azureAdService.getTotalGroups().subscribe((res: any) => {
      this.totalGroups = JSON.parse(res?.message)?.response;
       if(this.totalGroups){
         this.showTotalGroupsApiLoader = false;
      }
    },(err)=>{
      this.showTotalGroupsApiLoader = false;
    });
    this.azureAdService.getTotalMembers().subscribe((res: any) => {
      this.totalMembers = JSON.parse(res?.message)?.response;
      if(this.totalMembers){
        this.showTotalMembersApiLoader = false;
      }
    },(err)=>{
      this.showTotalMembersApiLoader = false;
    });
    this.azureAdService.getMFAEnabledUsers().subscribe((res: any) => {
      this.MFAEnabledUsers = JSON.parse(res?.message)?.response;
      if(this.MFAEnabledUsers){
         this.showMfaEnabledUsersApiLoader = false;
      }
    },(err)=>{
      this.showMfaEnabledUsersApiLoader = false;
    });
    this.azureAdService.getDeletedUsers().subscribe((res: any) => {
      this.deletedUsers = JSON.parse(res?.message)?.response;
      if(this.deletedUsers){
        this.showDeletedUsersApiLoader = false;
      }
    },(err)=>{
      this.showDeletedUsersApiLoader = false;
    });
    this.exchangeService.getMailBoxData().subscribe((res: any) => {
      this.exchangeActiveInactive = JSON.parse(res?.message)?.response;
      if(this.exchangeActiveInactive){
        this.activeMailboxPercentage = (this.exchangeActiveInactive?.total_active_mailboxes_thirty_days && this.exchangeActiveInactive?.total_mailboxes_thirty_days) ?this.functionsService.setPercentage(this.exchangeActiveInactive?.total_active_mailboxes_thirty_days,this.exchangeActiveInactive?.total_mailboxes_thirty_days).toFixed(0)+'%':'N/A';
        this.showMailBoxDataApiLoader=false;
      }
    },(err)=>{
      this.showMailBoxDataApiLoader=false;
    });
  }

  onPageChange(event){
    this.pageNumber = event;
  }
  onPageBoundsCorrection(newPageNumber: number){
    this.pageNumber = newPageNumber;
  }


}
