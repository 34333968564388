import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantsService } from '../../shared/services/config/constants.service';
import { FunctionsService } from '../../shared/utilities/functions.service';

@Injectable({
  providedIn: 'root'
})

export class SubscriptionListService {
  constructor(
    private constsvc: ConstantsService,
    private http: HttpClient,
    private functions: FunctionsService) { }

  getSubscriptionDetailsData(userId: any) {
      return this.http.post(`${this.constsvc.subscriptionsListApiUrl}`,userId, this.functions.getHeaders);
  }

}
