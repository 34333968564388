<div class="authentication-bg">
  <div class="login-wrap">
    <div class="container">
      <div class="row justify-content-center ">
        <div class="col-xl-9">
          <div class="card overflow-hidden">
            <div class="login-box">
              <div class="row align-items-center">
                <div class="col-md-6 d-none d-md-block">
                  <div class="text-center">
                    <h2>Microsoft 365  <br /> Management Platform</h2>
                    <img src="assets/images/global/png/microsoft-365-login.png" class="img-fluid" alt="office-365">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-body bg-white">
                    <div class="pt-5 text-center">
                      <a class="">
                        <img src="assets/images/global/svg/logo-light-login.svg" width="250" alt="officeboard logo">
                      </a>
                      <div class="mt-5 text-center">
                        <h2 class="h2">Sign In</h2>
                      </div>
                    </div>
                    <div class="p-2">
                      <form  [formGroup]="loginForm"
                      (ngSubmit)="onSubmit()" class="form-horizontal custom-validation">
                        <div class="mb-3 position-relative">
                          <input type="text" formControlName="email_id" id="email_id" class="form-control"
                            placeholder="Enter your email" required>
                          <span class="symbol-input"><i class="mdi mdi-at"></i></span>
                          <ng-container >
                            <p
                              class="form-text text-danger"
                              *ngIf="
                              loginForm.get('email_id').hasError('pattern') &&
                                loginForm.get('email_id').touched
                              "
                            >
                            {{errorMessages.email[1].message}}
                            </p>
                          </ng-container>
                        </div>

                        <div class="mb-3">
                          <div class="input-group auth-pass-inputgroup">
                            <input [type]="showPassword ? 'text' : 'password'" class="form-control" formControlName="password" id="password" placeholder="Enter password"
                              aria-label="Password" aria-describedby="password-addon" required >
                            <span class="symbol-input"><i class="mdi mdi-lock"></i></span>
                            <button class="btn btn-light " type="button" id="password-addon" (click)="hideAndShowPassword()"><i
                                class="mdi mdi-eye-outline"></i></button>
                          </div>
                          <input type="password" required hidden />
                        </div>

                        <!-- <div class="form-check ms-2">
                          <input class="form-check-input" type="checkbox" id="remember-check">
                          <label class="form-check-label" for="remember-check">
                            Remember me
                          </label>
                        </div> -->

                        <div class="mt-3 d-grid">
                          <button class="btn btn-primary waves-effect waves-light" type="submit" [disabled]="!loginForm.valid">
                            <span *ngIf="showLoader" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            Sign In</button>
                        </div>
                        <div class="mt-4 text-center fw-bold">
                          <p>New user? <a [routerLink]="['/signup']" class="fw-medium text-primary"> Create an account </a> </p>
                          <a class="text-muted"><i class="mdi mdi-lock me-1"></i> Forgot Password?</a>
                        </div>
                        <div class="mt-4 text-center">
                          <ul class="list-inline">
                            <li class="list-inline-item">
                              <a target="_blank" [routerLink]="['/terms&conditions']">
                                Terms of use
                              </a>
                            </li>
                            <li class="list-inline-item">|</li>
                            <li class="list-inline-item">
                              <a target="_blank" [routerLink]="['/privacy-policy']">
                                Privacy policy
                              </a>
                            </li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
