import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import moment from 'moment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})

export class FunctionsService {

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  constructor(private cookie: CookieService) {}

  public featuresHeaders =  new HttpHeaders({
    'Content-Type': 'application/json, charset=utf-8, access-control-allow-origin=*, access-control-allow-headers=*',
  });

  public  emailValidator = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public errorMessages = {
    email: [
      { type: 'required', message: 'Email Address is required.' },
      { type: 'pattern', message: 'Please enter a valid email address.' }
    ],
    phonenumber: [
      { type: 'required', message: 'Phone number is required.' },
      { type: 'pattern', message: 'Please enter a valid mobile number. ex. 9876543210' },
    ],
    fullname: [
      { type: 'required', message: 'Fullname is required.' },
      { type: 'minlength', message: 'Fullname must be minimum 4 character.' }
    ],
    password: [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Must be at least 8 characters!' },
      { type: 'hasCapitalCase', message: 'Must contain at least 1 in capital case!' },
      { type: 'hasNumber', message: 'Must contain at least 1 number!' },
      { type: 'hasSpecialCharacters', message: 'Must contain at least 1 special character!' }
    ]
  };
    // Function to set new cookies
  setNewCookies(cookiesData:Array<{name:string,value}>){
    for (const i in cookiesData){
      this.cookie.set(cookiesData[i].name, cookiesData[i].value, environment.loginExpiryTimeInDays, '/');
    }
  }

    // Function to delete cookies
  deleteCookie(cookieArray:Array<string>) {
    for (const cookieName in cookieArray){
      this.cookie.delete(cookieName, '/');
    }
  }

  // Function to get headers
  get getHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      })
    };
  }

  getFormErrors(error: any) {
    const errors :any = {};
    if (error) {
        // Phone Error
        if (error.phonenumber) {
            errors['phonenumber'] = error['phonenumber'];
        }
        // Email Error
        if (error['email']) {
            errors['email'] = error['email'];
        }
        // Full Name Error
        if (error['fullname']) {
            errors['fullname'] = error['fullname'];
        }
        // Password Error
        if (error['password']) {
            errors['password'] = error['password'];
        }
        return errors;
    }
    return null;
}

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): any => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error, else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  public makeFirstLetterCapital(str:string) {
    if (typeof str !== 'string') { return ''; }
      return str[0].toUpperCase() + str.slice(1);
  }

  capitalizeEachWord(str:string){
    let arr:any = [];
    arr = str.split(' ').map((item)=>{
      return this.makeFirstLetterCapital(item.toLowerCase())
    });
      return arr.join(' ');
  }

  //Function to set two decimal places
  public setTwoDecimal(wholeNumber) {
    return parseInt(wholeNumber).toFixed(2);
  }

  //Function to set two days
  static setDays(day){
    return parseInt(day).toFixed();
  }

  //Function to calculate percentage
  setPercentage(numerator:number,denominator:number){
    return (numerator/denominator)*100;
  }

  //Function to get date in different format
  getFormattedDate(date,formatOfDate,requiredFormat) {
  return moment(date, formatOfDate).format(requiredFormat);
  }

  //Function to get absolue Value
  getAbsoluteValue(data:any){
    return Math.abs(data);
  }

  //Function to get difference from given date i.e, if given date is "2022-10-10", diff is 30, requiredDifferenceType is days then it will return date (givenDate - 30 days)
  calculateDifference(date,formatOfDate, diff,requiredDifferenceType,requredFormatOfDate) {
  return moment(date, formatOfDate).subtract(diff, requiredDifferenceType).format(requredFormatOfDate);
  }

  //Function to get time in different format
  getFormattedTime(date,formatOfDate,requiredFormatTime){
    return moment(date,formatOfDate).format(requiredFormatTime);
  }

  public static rowSelected(id: number, data: any) {
    if (data === -1) {
       return data = id;
    } else {
       if (data === id) {
          return data = -1;
       } else {
          return data = id;
       }
    }
  }

  getRoundOffData(data){
    return Math.round(data);
  }

  formatTitle(title:string){
    if(title.search(/_/gi)!=-1){            //Checking if string contains '_' then we will replace '_' with space. Ex: Thread_Manage to Thread Manage
      return title.split('_').join(' ');
    }
    else
    return title.replace(/([A-Z])/g, ' $1').trim()       //Checking if string doesn't contains '_' then we will add space before any capital letter. Ex: ThreadManage to Thread Manage
  }

  exportExcel(data: Array<any>,fileName): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = { Sheets: { 'Details': ws }, SheetNames: ['Details'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const finalData: Blob = new Blob([excelBuffer], { type: this.fileType });
    FileSaver.saveAs(finalData, fileName + '.xlsx');
  }

}
