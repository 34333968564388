<app-header [pageName]="'Exchange'"></app-header>
    <div id="layout-wrapper">
      <app-side-menu class="sidebar sidebar-dark sidebar-main sidebar-expand-md"></app-side-menu>
      <app-right-nav-bar></app-right-nav-bar>
        <div class="main-content">
            <div class="page-content organization">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-4 col-md-6 d-flex">
                            <div class="card border-0">
                              <app-loader *ngIf="showMailBoxDataApiLoader"></app-loader>
                                <div class="card-body">
                                    <h5 class="card-title2 officeboard_heading mb-4">
                                        <span>Mailboxes </span>
                                    </h5>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="alert bg-light" role="alert">
                                                <div class="flex-wrap">
                                                    <div class="d-flex">
                                                        <div class="avatar-xs me-3 alertmail_icon">
                                                            <span class="avatar-title rounded_5">
                                                                <img class="img-fluid" src="assets/images/features/exchange/email-solid-alerted.svg"
                                                                    alt="" />
                                                            </span>
                                                        </div>
                                                        <h3 class="mb-0 fw-bold text-secondary align-self-center">
                                                            {{mailBoxData?.total_mailboxes_thirty_days ?? 'N/A'}}
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="light_blue d-inline-block p-3 text-center rounded_5 w-100">
                                                <h3 class="mb-0 fw-bold text_green">
                                                  {{mailBoxData?.total_active_mailboxes_thirty_days ?? 'N/A'}}

                                                </h3>
                                                <p class="mb-0">Active Mailboxes</p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="d-inline-block light_grey_bg p-3 text-center rounded_5 w-100">
                                                <h3 class="fontsize2Em mb-0 fw-bold">
                                                {{mailBoxData?.total_inactive_mailboxes_thirty_days ?? 'N/A'}}
                                                </h3>
                                                <p class="mb-0">Inactive Mailboxes</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 d-flex">
                            <div class="card border-0">
                              <app-loader *ngIf="showMailBoxDataApiLoader"></app-loader>
                                <div class="card-body">
                                    <h5 class="card-title2 officeboard_heading mb-4 position-relative fixed_tooltip">
                                        <span>Inactive </span>Mailboxes
                                        <ng-template #tipContent>A mailbox becomes inactive when a Litigation Hold, retention policy, or other type of hold is applied to it, and then the corresponding Office 365 user account is deleted. The contents of an inactive mailbox are retained for the duration of the hold or retention policy that was placed on the mailbox before it was made inactive. <br/><a target="_blank" class="text-white mt-1 d-inline-block" href="https://compliance.microsoft.com/informationgovernance/retention/inactivemailbox">https://compliance.microsoft.com/informationgovernance/retention/inactivemailbox</a></ng-template>
                  <i class="mdi mdi-information-outline text-blue pointer-event fades" [ngbTooltip]="tipContent" placement="bottom" triggers="click" [ngbTooltip]="tipContent" [autoClose]="'outside'" ></i>
                                    </h5>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="d-flex flex-wrap pb-2">
                                                <div class="align-self-center d-flex">
                                                    <div class="me-2">
                                                        <i class="fa fs-4 fa-calendar-alt text_dark_green"></i>
                                                    </div>
                                                    <p class="mb-0">7 Days</p>
                                                </div>
                                                <div class="ms-auto">
                                                    <p class="mb-0 me-3 fw-bold text_dark_green">
                                                        {{mailBoxData?.total_inactive_mailboxes_seven_days ?? 'N/A'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex flex-wrap py-2">
                                                <div class="align-self-center d-flex">
                                                    <div class="me-2">
                                                        <i class="fa fa-calendar-alt fs-4 text_dark_green"></i>
                                                    </div>
                                                    <p class="mb-0">30 Days</p>
                                                </div>
                                                <div class="ms-auto">
                                                    <p class="mb-0 fw-bold me-3 text_dark_green">
                                                      {{mailBoxData?.total_inactive_mailboxes_thirty_days ?? 'N/A'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex flex-wrap py-2">
                                                <div class="align-self-center d-flex">
                                                    <div class="me-2">
                                                        <i class="fa fs-4 fa-calendar-alt text_dark_green"></i>
                                                    </div>
                                                    <p class="mb-0">90 Days</p>
                                                </div>
                                                <div class="ms-auto">
                                                    <p class="mb-0 fw-bold me-3 text_dark_green">
                                                      {{mailBoxUsageDetails?.Inactive_Email_ninety_days ?? 'N/A'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex flex-wrap py-2">
                                                <div class="align-self-center d-flex">
                                                    <div class="me-2">
                                                        <i class="fa fs-4 fa-calendar-alt text_dark_green"></i>
                                                    </div>
                                                    <p class="mb-0">180 Days</p>
                                                </div>
                                                <div class="ms-auto">
                                                    <p class="mb-0 me-3 fw-bold text_dark_green">
                                                      {{mailBoxData?.total_inactive_mailboxes_180_days ?? 'N/A'}}

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex flex-wrap pt-2">
                                                <div class="align-self-center d-flex">
                                                    <div class="me-2">
                                                        <i class="fas fs-4 fa-minus-circle text_dark_green"></i>
                                                    </div>
                                                    <p class="mb-0">Never Logged In</p>
                                                </div>
                                                <div class="ms-auto">
                                                    <p class="mb-0 me-3 fw-bold text_dark_green">
                                                      {{mailBoxUsageDetails?.Never_login_user_count ?? 'N/A'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-sm-6 d-flex">
                            <div class="card border-0">
                              <app-loader *ngIf="showMailBoxQuotaStatusApiLoader"></app-loader>
                                <div class="card-body">
                                    <h5 class="card-title2 officeboard_heading mb-4">
                                        <span>Mailbox </span>Quota Status
                                    </h5>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="d-flex flex-wrap pb-2">
                                                <div class="d-flex">
                                                    <div class="me-2 ms-2">
                                                        <img width="20"
                                                            src="assets/images/features/exchange/limit.svg"
                                                            alt="" />
                                                    </div>
                                                    <p class="mb-0 align-self-center">Under Limit</p>
                                                </div>
                                                <div class="ms-auto align-self-center">
                                                    <p class="mb-0 me-3 text_dark_green fw-bold">
                                                        {{mailBoxUsageQuota?.Under_Limit_Count ?? 'N/A'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex flex-wrap py-2">
                                                <div class="d-flex">
                                                    <div class="me-2 ms-2">
                                                        <img width="20"
                                                            src="assets/images/features/exchange/warning.svg"
                                                            alt="" />
                                                    </div>
                                                    <p class="mb-0 align-self-center">Warning Issued</p>
                                                </div>
                                                <div class="ms-auto align-self-center">
                                                    <p class="mb-0 me-3 text_dark_green fw-bold">
                                                      {{mailBoxUsageQuota?.Warning_Issued_Count ?? 'N/A'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex flex-wrap py-2">
                                                <div class="d-flex">
                                                    <div class="me-2 ms-2">
                                                        <img width="20"
                                                            src="assets/images/features/exchange/not-sent.svg"
                                                            alt="" />
                                                    </div>
                                                    <p class="mb-0 align-self-center">Send Prohibited</p>
                                                </div>
                                                <div class="ms-auto align-self-center">
                                                    <p class="mb-0 me-3 text_dark_green fw-bold">
                                                      {{mailBoxUsageQuota?.Send_Prohibited?? 'N/A'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex flex-wrap py-2">
                                                <div class="d-flex">
                                                    <div class="me-2 ms-2">
                                                        <img width="20"
                                                            src="assets/images/features/exchange/bx-notification-off.svg"
                                                            alt="" />
                                                    </div>
                                                    <p class="mb-0 align-self-center">Send/Receive Prohibited</p>
                                                </div>
                                                <div class="ms-auto align-self-center">
                                                    <p class="mb-0 me-3 text_dark_green fw-bold">
                                                      {{mailBoxUsageQuota?.Send_Receive_Prohibited_Count ?? 'N/A'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex flex-wrap pt-2">
                                                <div class="d-flex">
                                                    <div class="me-2 ms-2">
                                                        <img width="20"
                                                            src="assets/images/features/exchange/checkbox-indeterminate.svg"
                                                            alt="" />
                                                    </div>
                                                    <p class="mb-0 align-self-center">Indeterminate</p>
                                                </div>
                                                <div class="ms-auto align-self-center">
                                                    <p class="mb-0 me-3 text_dark_green fw-bold">
                                                      {{mailBoxUsageQuota?.Indeterminate ?? 'N/A'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 d-flex">
                            <div class="card border-0 w-100">
                              <app-loader *ngIf="showMailBoxUsageApiLoader"></app-loader>
                                <div class="card-body">
                                    <h5 class="card-title2 officeboard_heading mb-4">
                                        <span>Top 5 </span>Inactive Mailboxes
                                    </h5>
                                    <div class="row">
                                        <div class="col-12" *ngFor="let inactiveUser of mailBoxUsageDetails?.Inactive_Users_most_days">
                                            <div class="d-flex flex-wrap pb-3 mb-1">
                                                <div class="align-self-center d-flex w-90">
                                                    <div class="me-2">
                                                        <i class="fa fs-5 far fa-envelope text_dark_green"></i>
                                                    </div>
                                                    <p class="mb-0 text-truncate pe-3">{{inactiveUser?.User_Principal_Name ?? 'N/A'}}</p>
                                                </div>
                                                <div class="ms-4 ms-sm-auto">
                                                    <p class="mb-0 fw-bold text_dark_green">
                                                      {{+inactiveUser?.Days_Inactive}} Days
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 d-flex">
                            <div class="card border-0 w-100">
                              <app-loader *ngIf="showMailBoxTrafficApiLoader || showMailBoxDetailsApiLoader"></app-loader>
                                <div class="card-body">
                                    <h5 class="card-title2 officeboard_heading mb-4">
                                        <span>Mail</span> Traffic
                                    </h5>
                                    <div class="row">
                                        <div class="col-sm-5">
                                            <div class="d-flex">
                                                <div class="avatar-xs me-2">
                                                    <span class="avatar-title rounded_5 light_grey_bg">
                                                        <i class="fas fa-paper-plane text_dark_green"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <p class="mb-0 fw-bold line-height_15">
                                                        {{mailBoxTraffic?.total_email_sent_thirty_days ?? 'N/A'}}
                                                    </p>
                                                    <h6 class="mb-3 text-muted fw-normal">E-mails Sent</h6>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <div class="avatar-xs me-2">
                                                    <span class="avatar-title rounded_5 light_grey_bg">
                                                        <i class="mdi mdi-email-receive text_green"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <p class="mb-0 fw-bold line-height_15">
                                                      {{mailBoxTraffic?.total_email_received_thirty_days ?? 'N/A'}}
                                                    </p>
                                                    <h6 class="mb-3 mb-sm-0 text-muted fw-normal">E-mails Received</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-7">
                                            <div class="d-flex">
                                                <div class="avatar-xs me-2">
                                                    <span class="avatar-title rounded_5 light_grey_bg">
                                                        <i class="fas font-size-18 fa-paper-plane text_dark_green"></i>
                                                    </span>
                                                </div>
                                                <div class="w-75">
                                                    <p class="mb-0 fw-bold line-height_15 text-truncate">
                                                        {{mailsActivityData?.max_mail_sent?.userPrincipalName ?? 'N/A'}}
                                                        <i class="font-size-13 fas fa-info-circle text-primary"></i>
                                                    </p>
                                                    <h6 class="mb-3 fw-normal">{{mailsActivityData?.max_mail_sent?.sent_count ?? 'N/A'}} E-mails Sent</h6>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <div class="avatar-xs me-2">
                                                    <span class="avatar-title rounded_5 light_grey_bg">
                                                        <i class="mdi mdi-email-receive font-size-18 text_green"></i>
                                                    </span>
                                                </div>
                                                <div class="w-75">
                                                    <p class="mb-0 fw-bold line-height_15 text-truncate">
                                                      {{mailsActivityData?.max_mail_received?.userPrincipalName ?? 'N/A'}}
                                                        <i class="font-size-14 fas fa-info-circle text-primary"></i>
                                                    </p>
                                                    <h6 class="mb-0 fw-normal">{{mailsActivityData?.max_mail_received?.receive_count ?? 'N/A' }} E-mails Received</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="spam_malware_phishing">Licenses Count</div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="d-flex">
                                                <div class="avatar-xs me-2">
                                                    <span class="avatar-title rounded_5 light_grey_bg">
                                                        <img width="18"
                                                            src="assets/images/features/exchange/spam.svg" alt="" />
                                                    </span>
                                                </div>
                                                <div>
                                                    <h5 class="mb-0 fw-bold line-height_15 text-rose">
                                                      {{licenseCountArray[0]?.value ?? 'N/A'}}
                                                        <i class="fas fa-info-circle text-primary"></i>
                                                    </h5>
                                                    <p class="mb-3" placement="top" container="body" triggers="hover" [autoClose]="'outside'"  ngbTooltip='{{licenseCountArray[0]?.name ?? "N/A"}}'>{{licenseCountArray[0]?.name ?? 'N/A'}}</p>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <div class="avatar-xs me-2">
                                                    <span class="avatar-title rounded_5 light_grey_bg">
                                                        <img width="18" src="assets/images/features/exchange/at.svg"
                                                            alt="" />
                                                    </span>
                                                </div>
                                                <div>
                                                    <h5 class="mb-0 fw-bold line-height_15 text-rose">
                                                      {{licenseCountArray[1]?.value ?? 'N/A'}}
                                                        <i class="font-size-14 fas fa-info-circle text-primary"></i>
                                                    </h5>
                                                    <p class="mb-3 mb-sm-0" placement="top" container="body" triggers="hover" [autoClose]="'outside'"  ngbTooltip='{{licenseCountArray[1]?.name ?? "N/A" }}'>{{licenseCountArray[1]?.name ?? 'N/A'}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="d-flex">
                                                <div class="avatar-xs me-2">
                                                    <span class="avatar-title rounded_5 light_grey_bg">
                                                        <img width="18"
                                                            src="assets/images/features/exchange/1-square.svg"
                                                            alt="" />
                                                    </span>
                                                </div>
                                                <div class="w-75">
                                                    <h5 class="mb-0 fw-bold line-height_15 text-rose">
                                                      {{licenseCountArray[2]?.value ?? 'N/A'}}
                                                        <i class="font-size-14 fas fa-info-circle text-primary"></i>
                                                    </h5>
                                                    <p class="mb-3 text-truncate" placement="top" container="body" triggers="hover" [autoClose]="'outside'"  ngbTooltip='{{licenseCountArray[2]?.name ?? "N/A" }}'>{{licenseCountArray[2]?.name ?? 'N/A'}}</p>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <div class="avatar-xs me-2">
                                                    <span class="avatar-title rounded_5 light_grey_bg">
                                                        <img width="18"
                                                            src="assets/images/features/exchange/2-square.svg"
                                                            alt="" />
                                                    </span>
                                                </div>
                                                <div class="w-75">
                                                    <h5 class="mb-0 fw-bold line-height_15 text-rose">
                                                      {{licenseCountArray[3]?.value ?? 'N/A'}}
                                                        <i class="font-size-14 fas fa-info-circle text-primary"></i>
                                                    </h5>
                                                    <p class="mb-3 mb-sm-0 text-truncate" placement="top" container="body" triggers="hover" [autoClose]="'outside'"  ngbTooltip='{{licenseCountArray[3]?.name ?? "N/A" }}'>{{licenseCountArray[3]?.name ?? 'N/A'}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5">
                            <div class="card border-0 w-100">
                              <app-loader *ngIf="showEmailAppUsageApiLoader || showEmailAppVersionApiLoader"></app-loader>
                                <div class="card-body">
                                    <div class="d-flex flex-wrap align-items-start">
                                        <div class="mx-auto">
                                            <ul class="nav nav-tabs activity_tabs mb-2" id="myTab" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link active" id="email-app-tab" data-bs-toggle="tab"
                                                        href="#email_app" role="tab" aria-controls="activity"
                                                        aria-selected="true">E-mails App</a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="email-App-version-tab" data-bs-toggle="tab"
                                                        href="#email_App_version" role="tab" aria-controls="profile"
                                                        aria-selected="false">E-mails App Version</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="email_app" role="tabpanel"
                                            aria-labelledby="email-app-tab">
                                            <div class="table-responsive email_app">
                                                <table class="table table-striped mb-0 border-0">
                                                    <tbody>
                                                        <tr *ngFor="let emailApp of sortedEmailAppUsageArray">
                                                            <td>
                                                                <span class="d-inline-block text-center me-1"><img
                                                                        width="15"
                                                                        src="assets/images/features/exchange/{{emailApp.iconName}}" /></span>
                                                                {{emailApp.name}}
                                                            </td>
                                                            <td class="text-right fw-bold text-primary">{{emailApp.value}}</td>
                                                        </tr>
                                                      </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="email_App_version" role="tabpanel"
                                            aria-labelledby="email-App-version-tab">
                                            <div class="table-responsive email_app_version">
                                                <table class="table table-striped mb-0 border-0">
                                                    <tbody>
                                                        <tr *ngFor="let emailAppVersion of sortedEmailAppVersionArray">
                                                            <td>
                                                                <span
                                                                    class="width-30 d-inline-block text-center me-1"><img
                                                                        src="assets/images/features/exchange/{{emailAppVersion.iconName}}" /></span>
                                                                {{emailAppVersion.name}}
                                                            </td>
                                                            <td class="text-right fw-bold text-primary">{{emailAppVersion.value}}</td>
                                                        </tr>
                                                        </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-7 d-flex">
                            <div class="card border-0 w-100">
                              <app-loader *ngIf="showMailBoxDataApiLoader|| showMailBoxTrafficApiLoader"></app-loader>
                                <div class="card-body" >
                                    <div class="d-flex flex-wrap align-items-start">
                                        <h5 class="card-title2 officeboard_heading mb-4">
                                            <span>Activity</span> Trend
                                        </h5>
                                        <div class="ms-auto">
                                            <ul class="nav nav-tabs activity_tabs" id="myTab" role="tablist">
                                                <li class="nav-item" role="presentation" >
                                                    <a class="nav-link active" id="activity-tab" data-bs-toggle="tab"
                                                        href="#activity_graph" role="tab" aria-controls="activity"
                                                        aria-selected="true">Graph</a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="table-tab" data-bs-toggle="tab"
                                                        href="#activity_table" role="tab" aria-controls="profile"
                                                        aria-selected="false">Table</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="tab-content" id="myTabContent" >
                                        <div class="tab-pane fade show active" id="activity_graph" role="tabpanel"
                                            aria-labelledby="activity-tab">
                                            <div id="trend"></div>
                                        </div>
                                        <div class="tab-pane fade" id="activity_table" role="tabpanel"
                                            aria-labelledby="table-tab">
                                            <div class="table-responsive device_usage">
                                                <table class="table table-striped mb-0 border-0">
                                                    <thead class="table-light border-0">
                                                        <tr>
                                                            <th>Activity</th>
                                                            <th>30 Days</th>
                                                            <th>90 Days</th>
                                                            <th>180 Days</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Mailbox</td>
                                                            <td>{{mailBoxData?.total_mailboxes_thirty_days ?? 'N/A'}}</td>
                                                            <td>{{mailBoxData?.total_mailboxes_ninety_days ?? 'N/A'}}</td>
                                                            <td>{{mailBoxData?.total_mailboxes_180_days ?? 'N/A'}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>E-mails Sent</td>
                                                            <td>{{mailBoxTraffic?.total_email_sent_thirty_days ?? 'N/A'}}</td>
                                                            <td>{{mailBoxTraffic?.total_email_sent_ninety_days ?? 'N/A'}}</td>
                                                            <td>{{mailBoxTraffic?.total_email_sent_one_eighty_days ?? 'N/A'}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>E-mails Received</td>
                                                            <td>{{mailBoxTraffic?.total_email_received_thirty_days ?? 'N/A'}}</td>
                                                            <td>{{mailBoxTraffic?.total_email_received_ninety_days ?? 'N/A'}}</td>
                                                            <td>{{mailBoxTraffic?.total_email_received_one_eighty_days ?? 'N/A'}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-footer></app-footer>
                </div>
            </div>
        </div>
    </div>
