import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {

  constructor() { }

@Input() titleValue!: string;
@Input() data1!: number;
@Input() data2!: number;
@Input() colors : Array<string> = [];
@Input() data1Title !: string;
@Input() data2Title !: string;

  highcharts = Highcharts;
  chartOptions: any;
  ngOnInit(): void {
    this.chartOptions =  {
      chart: {
        plotBorderWidth: null,
        plotShadow: false,
        style: {
          fontFamily: 'Roboto, sans-serif',
        },
        height:120
      },
      title: {
        text:  this.titleValue,
        align: 'center',
        style: {
          fontSize: '16px',
          fontWeight: 'bold'
        },
        verticalAlign: 'middle',
        y: 20
      },
      credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '{point.y}'
      },
      plotOptions: {
        pie: {
          slicedOffset: 0,
          enableMouseTracking : true,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: false
        }
      },
      colors:this.colors,
      series: [{
        type: 'pie',
        name: 'Total',
        innerSize: '70%',
        point: {
          events: {
            legendItemClick: function () {
              return false;
            }
          }
        },
        data: [
          [this.data1Title, this.data1],
          [this.data2Title, this.data2]

        ]
      }]
    }
  }

}
