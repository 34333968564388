<div id="layout-wrapper">
<header id="page-topbar">
  <div class="navbar-header">
      <div class="d-flex">
          <div class="navbar-brand-box organization">
            <a (click)="refresh()" class="logo logo-light cursor">
              <span class="logo-sm">
                  <img src="/assets/images/global/svg/logo-light-sm.svg" alt="" height="22">
              </span>
              <span class="logo-lg">
                  <img src="/assets/images/global/svg/logo-light.svg" class="img-fluid" alt="">
              </span>
          </a>
          </div>
      </div>

      <div class="d-flex">
          <div class="dropdown d-inline-block">
              <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img class="rounded-circle header-profile-user" src="assets/images/global/avatar-1.jpg"
                      alt="Header Avatar">
                  <span class="d-none d-xl-inline-block ms-1" key="t-henry">{{li_user_name}} (<b>{{org_name}}</b>)</span>
                  <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                  <a class="dropdown-item text-danger cursor" (click)="logout()">
                      <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                      <span>Logout</span>
                  </a>
              </div>
          </div>

      </div>
  </div>
</header>
<div class="main-content subscription_list me-0">
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box d-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0 font-size-18">Tenants List ({{subscriptionsDetails.length}})</h4>
                        <div class="page-title-right">
                            <a class="btn btn-primary waves-effect waves-light btn-sm fw-bold"
                                (click)="onBoardNewSubscription(createNewCloud)"><i class="mdi fw-bold mdi-plus ms-1"></i> Add New Tenant</a>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row position-relative min-height">
              <app-loader class="position-absolute h-100" *ngIf="showLoader"  [loadingMessage]="messageConstantsService.loadingMsgTenantListPage"></app-loader>
              <div class="col-12">
                <div *ngIf="!showLoader" class="row">
                  <ng-container *ngIf="subscriptionsDetails.length>0; else noCloud">
                      <div class="col-xl-4 col-sm-6" *ngFor="let subscription of subscriptionsDetails; let i = index">
                          <div class="card">
                              <div class="card-body p-2 p-sm-3">
                                  <div class="row align-items-center">
                                      <div class="col-2">
                                          <div class="text-lg-center">
                                              <div class="avatar-xs mx-lg-auto float-start float-lg-none">
                                                  <span
                                                      class="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-16">
                                                      <i class="mdi mdi-microsoft-azure display-6"></i>
                                                  </span>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-8 px-0" *ngIf="subscription?.tenantName !== 'NA'; else elseTpid">
                                          <div>
                                              <h5 class="text-truncate mb-0" placement="top" ngbTooltip="{{subscription?.tenantId}}">{{
                                                subscription?.tenantName || "NA" }}</h5>
                                          </div>
                                      </div>
                                      <ng-template #elseTpid>
                                          <div class="col-8 px-0">
                                              <div>
                                                  <h5 class="text-truncate mb-0">{{ subscription?.tenantId }}</h5>
                                              </div>
                                          </div>
                                      </ng-template>

                                      <div class="col-2" >
                                          <button type="submit" (click)="setTokens(subscription?.tenantId, subscription?.tenantName, subscription?.tenantOrganizationId, subscription?.tenantOrganizationName)"
                                              class="btn btn-primary btn-sm chat-send w-md waves-effect waves-light float-end"> <i
                                                  class="bx bx-paper-plane font-size-22"></i></button>
                                      </div>
                                      <ng-template #disbaled>
                                        <div class="col-2">
                                          <button type="submit"
                                              class="btn btn-primary btn-sm chat-send w-md waves-effect waves-light"> <i
                                                  class="bx bx-paper-plane font-size-22"></i></button>
                                                </div>
                                      </ng-template>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </ng-container>
                  <ng-template #noCloud>
                      <div class="card">
                          <div class="p-5">
                              <div class="card-block align-content-center text-center">
                                  <h5 class="m-3 font-weight-semibold">
                                      <i class="icon-cloud fs35 mr-2"></i> You have not yet added any cloud account to
                                      your dashboard.
                                  </h5>
                              </div>
                          </div>
                      </div>
                  </ng-template>

              </div>
              </div>
            </div>
            <ng-template #createNewCloud let-modal>
                <div class="modal-header header-elements-inline">
                    <h6 class="font-weight-semibold modal-title text-white">OnBoarding New Tenant</h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
                </div>
                <app-onboard-new-subscription></app-onboard-new-subscription>
            </ng-template>
        </div>
        <app-footer></app-footer>
    </div>
</div>
</div>
