import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ConstantsService } from '../../shared/services/config/constants.service';
import { FunctionsService } from '../../shared/utilities/functions.service';

@Injectable({
  providedIn: 'root'
})

export class ExecutiveSummaryService {

  constructor(private http: HttpClient, private cookie: CookieService, private constantsService : ConstantsService, private functionsService : FunctionsService) { }

  private org_id = `${this.cookie.get('_tnt_org_id')}`;
  private tnt_id = `${this.cookie.get('_tnt_id')}`;

  private baseUrlExecutiveSummary =  `${this.constantsService.executiveSummaryApiUrl}`;
  private baseUrlAzureAd = `${this.constantsService.azureAdApiUrl}`;

  private apiUrl_productsData = this.baseUrlExecutiveSummary + "fetch/o365/data/licensesactivated/licenses?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_activeInactiveUsersData = this.baseUrlExecutiveSummary + "fetch/o365/data/licensesactivated/activeusers?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_globalAdmin = this.baseUrlAzureAd + "globaladmins?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_guestAzureAd = this.baseUrlAzureAd + "guestusers?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_totalActiveSharePoints = this.baseUrlExecutiveSummary + "fetch/o365/data/sharepoint/activesites?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_totalActiveOneDriveAcc = this.baseUrlExecutiveSummary + "fetch/o365/data/onedrive/activeaccounts?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_monthlyActiveUsage = this.baseUrlExecutiveSummary + "fetch/o365/data/licensesactivated/monthlyactiveusers?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_PAU = this.baseUrlExecutiveSummary + "fetch/obsa/paid_available_units?tenant_id="+this.tnt_id;
  private apiUrl_addNewPAU = this.baseUrlExecutiveSummary+ "update/obsa/paid_available_units";

  getProductsData() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_productsData,options)
  }

  getActiveInactiveUsersData(){
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_activeInactiveUsersData,options)
  }

  getGlobalAdminsData(){
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_globalAdmin,options)
  }

  getGuestUsersData(){
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_guestAzureAd,options)
  }

  getSharePointSiteData(){
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_totalActiveSharePoints,options)
  }

  getOneDriveData(){
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_totalActiveOneDriveAcc,options)
  }

  getMAU() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_monthlyActiveUsage,options)
  }

  getPAU() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_PAU,options)
  }

  registerPAUService(userValues: any){
    let options = ({headers:this.functionsService.featuresHeaders});
    let data = {
      'Exchange': userValues.exchange,
      'Office 365': userValues.office_365,
      'One Drive': userValues.oneDrive,
      'SharePoint': userValues.sharepoint,
      'Skype For Business': userValues.skypeForBusiness,
      'Teams': userValues.teams,
      'Yammer': userValues.yammer
    }
    let body = {
      organization_id: this.org_id,
      tenant_id: this.tnt_id,
      pau_data: data,
      user_id: `${this.cookie.get('_u_id')}`,
    };
    console.log(body)
    return this.http.post(this.apiUrl_addNewPAU, JSON.stringify(body),options);
  }

}
