import { ConstantsService } from './../../shared/services/config/constants.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { FunctionsService } from '../../shared/utilities/functions.service';
@Injectable({
  providedIn: 'root'
})

export class TeamsService {

  constructor(private http: HttpClient, private cookie: CookieService, private constantsService : ConstantsService, private functionsService : FunctionsService) { }

  private org_id = `${this.cookie.get('_tnt_org_id')}`;
  private tnt_id = `${this.cookie.get('_tnt_id')}`;

  //Teams API URLs
  private baseUrlTeams = `${this.constantsService.teamsPageApiUrl}`
  private apiUrl_TeamsCallData = this.baseUrlTeams + "teamscalls?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_DeviceUsageData = this.baseUrlTeams+"teamsdeviceusage?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_teamsChatDetails = this.baseUrlTeams+"teamsuseractivity?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_teamsData = this.baseUrlTeams+"teamsdetails?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;
  private apiUrl_teamsChannel = this.baseUrlTeams +"channels?org_id="+this.org_id + "&az_tenant_id="+this.tnt_id;

  // Methods
  getTeamsCallsData() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_TeamsCallData,options);
  }

  getTeamsDeviceUsageData(){
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_DeviceUsageData,options);
  }

  getTeamsChatData(){
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_teamsChatDetails,options);
  }
  getTeamsData() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_teamsData,options)
  }

  getTeamsChannel() {
    let options = ({headers:this.functionsService.featuresHeaders});
    return this.http.get(this.apiUrl_teamsChannel,options)
  }
}
