import { CookieService } from 'ngx-cookie-service';
import { SecurityService } from './../../services/security.service';
import { Component, QueryList, ViewChildren} from '@angular/core';
import { FunctionsService } from 'src/app/modules/shared/utilities/functions.service';
import { compare, SortableDirective, SortEvent } from 'src/app/modules/shared/utilities/sortable.directive';
import moment from 'moment';
import { SecurityAlertReportService } from '../../services/security-alert-report.service';

enum statusIcons{
  resolved = 'mdi-checkbox-marked-circle-outline text-green',
  newAlert = 'mdi-alert text-danger',
  dismissed = 'mdi-close-circle-outline'
} //Status Icons Classes

enum statusTextColors{
 'newAlert' = 'text_alerts',
 'resolved' = 'text-green',
 'inProgress' = 'text-blue-light',
}

enum statusBarColors{
  'newAlert' = 'alerts_bg',
  'resolved' = 'bg_green',
  'dismissed' = 'bg-grey',
  'inProgress' = 'bg-blue',
 }
@Component({
  selector: 'app-security-alert-details',
  templateUrl: './security-alert-details.component.html',
  styleUrls: ['./security-alert-details.component.scss']
})

export class SecurityAlertDetailsComponent {
  pageNumber=1;
  selectedColumn;
  selectedTag;
  securityAlerts;
  secureScores;
  highSeverity;
  totalSeverity = 0;
  totalStatusCount = 0;
  securityAlertsDetailsTable:Array<any> =[];
  statusArray:any=[];
  highSeverityPercentage;
  secureScorePercentage;

  // Loader Variables starts
  showSecurityAlertsDetailsTableLoader : boolean = true;
  showSecurityScoreApiLoader: boolean = true;
  showSecurityAlertsApiLoader: boolean = true;
  // Loader Variables Ends

  @ViewChildren(SortableDirective) headers!: QueryList<SortableDirective>;

  constructor(private securityService: SecurityService, public functionsService: FunctionsService, private cookie : CookieService, private securityReportService : SecurityAlertReportService) {
    this.securityService.getSecurityAlerts().subscribe((res: any) => {
      this.securityAlerts = JSON.parse(res?.message)?.response;
      if (this.securityAlerts?.length > 0 || Object.keys(this.securityAlerts).length>0) {
        for (const i in this.securityAlerts?.severity_count) {
          this.totalSeverity += this.securityAlerts?.severity_count[i]           //Calculating total severity count
        }
        this.highSeverity = this.securityAlerts?.severity_count?.high ?? 0;   //If there is no high severity count we will consider it 0.
        this.highSeverityPercentage = this.functionsService.setPercentage(this.highSeverity, this.totalSeverity).toFixed(0) + '%';

        for (const i in this.securityAlerts?.status_count) {
          this.totalStatusCount += this.securityAlerts?.status_count[i];         //Calculating total status count
          const temp = {
            status : this.functionsService.formatTitle(i),
            value : this.securityAlerts?.status_count[i],
            textColor : i=='dismissed'?'':statusTextColors[i],
            barColor : statusBarColors[i],
          }
          this.statusArray.push(temp);
        }
        this.showSecurityAlertsApiLoader = false;
        this.securityReportService.setSecurityAlertDetailsData(this.securityAlerts?.status_details);
        this.getSecurityAlertDetailsData();      }
      else{
        this.showSecurityAlertsApiLoader = false;
        this.showSecurityAlertsDetailsTableLoader = false;
      }
    }, (err) => {
      this.showSecurityAlertsApiLoader = false;
      this.showSecurityAlertsDetailsTableLoader = false;
    });

    this.securityService.getSecurityScores().subscribe((res: any) => {
      this.secureScores = JSON.parse(res?.message)?.response;
      if (Object.keys(this.secureScores).length > 0) {
        this.secureScorePercentage = this.functionsService.setPercentage(this.secureScores?.thirty_days_current_score, this.secureScores?.thirty_days_max_Score).toFixed(0) + '%';
      }
      this.showSecurityScoreApiLoader = false;
    }, (err) => {
      this.showSecurityScoreApiLoader = false;
    });
  }

  //Function to set securityAlertDetail Table Data
  getSecurityAlertDetailsData(){
    this.securityReportService.sharedData.subscribe((res:any)=>{
      this.securityAlertsDetailsTable = res;
    this.showSecurityAlertsDetailsTableLoader = false;
  });

  }

  openCollapse(id: number) {
    this.selectedTag = FunctionsService.rowSelected(id, this.selectedTag);
  }

  formatTitle(title:string){
    const re = '/_/gi';
    if(title.search(/_/gi)!=-1){            //Checking if string contains '_' then we will replace '_' with space. Ex: Thread_Manage to Thread Manage
      return title.split('_').join(' ');
    }
    else
    return title.replace(/([A-Z])/g, ' $1').trim()       //Checking if string doesn't contains '_' then we will add space before any capital letter. Ex: ThreadManage to Thread Manage
  }

  onPageChange(event){
    this.pageNumber = event;
    this.selectedTag=null;
  }
  onPageBoundsCorrection(newPageNumber: number){
    this.pageNumber = newPageNumber;
  }

  onSort({ column, direction }: SortEvent,data) {
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
      if (direction === '') {
        this.selectedColumn = data;
      } else {
        this.selectedColumn = data.sort((a, b) => {
          const res = compare(a[column], b[column]);
          return direction === 'asc' ? res : -res;
        });
      }
    });
  }

  exportExcelSheet(){
    const estimatedMonth = moment().format('DD-MMMM-YYYY'); //Getting current Date
    let pdfName = 'Security-Details-Report '+this.cookie.get('_tnt_name')+`(${estimatedMonth})`;
    let tableData = JSON.parse(JSON.stringify(this.securityAlertsDetailsTable));
    tableData.forEach(element => {
     delete(element.severityColor);
     delete(element.statusIcon);
     element.vendorInfo = JSON.stringify(element.vendorInfo);
     element.fileStatesInfo = JSON.stringify(element.fileStatesInfo);
     element.hostStatesInfo = JSON.stringify(element.hostStatesInfo);
     element.securityResources = JSON.stringify(element.securityResources);
    });
    this.functionsService.exportExcel(tableData, pdfName)
  }

}
