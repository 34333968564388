import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss']
})
export class AreaChartComponent implements OnInit {
  highcharts = Highcharts;
  @Input() data1: any;
  @Input() data2: any;
  @Input() colors: Array<string>=[];
  @Input() data1Title !: string;
  @Input() data2Title !: string;

  chartOptions: any;
  constructor() { }

  ngOnInit(): void {
    this.chartOptions = {
      chart: {
        type: 'area',
        height:'290'
      },
      title: {
        text: ''
      },
      subtitle: {
        style: {
          position: 'absolute',
          right: '0px',
          bottom: '10px'
        }
      },
      colors: this.colors,
      xAxis: {
        categories: ['30 Days', '60 Days', '90 Days']
      },
      yAxis: {
        title: {
          text: ''
        },
        min: 0
      },
      plotOptions: {
        area: {
          dataLabels: {
            enabled: true,
        },
          lineColor: '#666666',
          lineWidth: 2,

          marker: {
             lineWidth: 2,
             lineColor: '#666666'
          },
       }
      },
      credits: {
        enabled: false
      },
      point: {
        events: {
          legendItemClick: function () {
            return false;
          }
        }
      },
      series: [

        {
          name: 'Max Secure Score',
          data: [this.data1.thirty_days_max_Score, this.data1.sixty_days_max_Score, this.data1.ninety_days_maximum_Score],
          color: '#ede7f6',
          lineColor: '#7e57c2'
        },
        {
          name: 'Secure Score',
          data: [this.data1.thirty_days_current_score, this.data1.sixty_days_current_score, this.data1.ninety_days_current_score],
          color: '#DEEBF7',
          lineColor: '#0070C0',
        },  {
          name: 'Security Alerts',
          data: [this.data2.thirty_days_alert_count, this.data2.sixty_days_alert_count, this.data2.ninety_days_alert_count],
          color: '#E2F0D9',
          lineColor: '#0DB358'
        },
      ]
    };

  }
}
