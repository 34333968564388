import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-progressive-bar-chart',
  templateUrl: './progressive-bar-chart.component.html',
  styleUrls: ['./progressive-bar-chart.component.scss']
})
export class ProgressiveBarChartComponent {
  constructor() {}

  @Input() data1!: number;
  @Input() data2!: number;
  @Input() data1Title!: string;
  @Input() data2Title!: string;
  highcharts = Highcharts;
  chartOptions: any;
  ngOnInit() {
    this.chartOptions = {
      chart: {
        type: 'bar',
        height:50,
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          stacking: 'percent',
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        },
      },
      tooltip: {
        enabled: true,
        pointFormat: '{point.y}',
        style:{
          padding:1
      }
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
      colors: ['#30D780', '#38A0BB'],
      series: [
        {
          data: [[this.data2Title, this.data2]],
        },
        {
          data: [[this.data1Title, this.data1]],
        },
      ],
    };
  }
}