import { FunctionsService } from './../../../../shared/utilities/functions.service';
import { MessageConstantsService } from './../../../../shared/utilities/messageConstants.service';
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-onboard-new-subscription',
  templateUrl: './onboard-new-subscription.component.html',
  styleUrls: ['./onboard-new-subscription.component.scss']
})
export class OnboardNewSubscriptionComponent implements OnInit {

  formValue!: FormGroup;
  password!: string;
  showPassword : boolean = false;
  showSubmitLoader: boolean = false;

  public showDiv = {
    onBoardingForm: true,
    thankYouDiv: false,
    warningDiv: false,
  };

  constructor(
    private formbuilder: FormBuilder,
    private cookie: CookieService,
    public messageConstantsService : MessageConstantsService,
    public functionsService : FunctionsService
  ) {}

  ngOnInit() {
    this.password = 'password';
    this.formValue = this.formbuilder.group({
      userName: ["", Validators.required],
      userPassword: ["", Validators.required],
      cloudProviderlicensetype: ["", Validators.required],
      businessEmail: ['', [Validators.required, Validators.email,Validators.pattern(this.functionsService.emailValidator)]]
    });
  }

  ShowAndHidePassword() {
    if (this.password === 'password') {
      this.password = 'text';
      this.showPassword = true;
    } else {
      this.password = 'password';
      this.showPassword = false;
    }
  }

  submitData() {
    this.showSubmitLoader = true;
    var xhr = new XMLHttpRequest();
    xhr.open("POST", environment.onboardingPipelineApiUrl);

    xhr.setRequestHeader(
      "Authorization",
      environment.onboardingPipelineAuthorizationToken
    );
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {                   //readyState=4 indicates request is finished and response is ready
      }
    };

    var data = JSON.stringify({
      resources: {
        repositories: {
          self: {
            refName: "refs/heads/main",
          },
        },
      },
      templateparameters: {
        username: this.formValue.value.userName.trim(),
        password: this.formValue.value.userPassword.trim(),
        cloudproviderlicensetype: this.formValue.value.cloudProviderlicensetype,
        email: this.formValue.value.businessEmail,
        o_name :`${this.cookie.get('_o_name')}`,
        o_id  : `${this.cookie.get('_o_id')}`,
        u_id  : `${this.cookie.get('_u_id')}`,
      },
    });

    xhr.send(data);
    setTimeout(()=>{
      if (xhr.status==200) {
        this.showDiv.onBoardingForm = false; //Hide OnBoardingForm
        this.showDiv.thankYouDiv = true; //Show ThankYouDiv
        this.showDiv.warningDiv = false; //Hide Warning DIV
      } else {
        this.showDiv.onBoardingForm = true; //Show OnBoardingForm
        this.showDiv.thankYouDiv = false; //Hide ThankYouDiv
        this.showDiv.warningDiv = true; //Show WarningDiv
    }
    },3000)
  }
}
