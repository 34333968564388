import { AzureAdService } from './../../services/azureAd.service';
import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { compare, SortableDirective, SortEvent } from 'src/app/modules/shared/utilities/sortable.directive';
import { MessageConstantsService } from 'src/app/modules/shared/utilities/messageConstants.service';
import { FunctionsService } from 'src/app/modules/shared/utilities/functions.service';

@Component({
  selector: 'app-administrators',
  templateUrl: './administrators.component.html',
  styleUrls: ['./administrators.component.scss']
})
export class AdministratorsComponent{

  adminUsers : any = [];
  globalAdminUsers : any = [];
  pageNumber:number = 1;
  pageNoArray: any = [];
  sortedUsersArrayByAdminRoles : Array<any> = [];
  currentlyActiveTabId:number = 0;
  isAdministratorTabActive : boolean = true;
  isCommonTableActive: boolean = false;
  showAdminUsersApiLoader : boolean = true;
  showTableApiLoader : boolean = false;
  selectedColumn;

  @ViewChildren(SortableDirective) headers!: QueryList<SortableDirective>;
  @ViewChild('removeSortClass', { read: ElementRef, static:false }) removeSortClass!: ElementRef;

  constructor(private azureAdService : AzureAdService, public messageConstantsService : MessageConstantsService, public functionsService : FunctionsService) {

    this.azureAdService.getAdminUsers().subscribe((res:any) => {

      const adminResponse = JSON.parse(res?.message)?.response?.total_users_with_admin_roles_data;

      if(adminResponse){

      // Declaring local variables
        let arrayByAdminRoles = {};
        let totalRoles:string[]= [];
        let usersArrayByAdminRoles : Array<any> = [];
        let totalDisctinctRoles:any = [];

        this.adminUsers = adminResponse.map((item)=> { //Formatting roles data i.e, adding a space in the string after comma. Ex: ABC,DEF => ABC, DEF
          return {
            displayName : this.functionsService?.makeFirstLetterCapital(item?.displayName),
            roles : item?.roles.split(',').join(', '),
            email : item?.userPrincipalName
          }
        });

        adminResponse.forEach(element => {
          const rolesArray = element.roles.split(',') //Splitting multiple roles stored as a string separated by a comma to individual array items.
          rolesArray.forEach(role => {
            totalRoles.push(role)
          });
        }); //Storing all types of Admins Roles

        totalDisctinctRoles = [
          ...new Map(
            totalRoles.map((item) => [JSON.stringify(item), item])
          ).values(),
        ] //Storing total distinct admin roles

        totalDisctinctRoles.forEach(element => { //Initializing pageNoArray for each tabs and storing default page no = 1 for each of them.
          const temp = [1];
          this.pageNoArray.push(temp)
        });

        totalDisctinctRoles.forEach(adminRoles => {
          let usersArray:any = [];
          adminResponse.forEach(users => {
            const rolesArray = users.roles.split(','); //Splitting multiple roles stored as a string separated by a comma to individual array items.
            if(!(adminRoles in arrayByAdminRoles)){
              arrayByAdminRoles[adminRoles] = [];   //Creating empty array as value for the key adminRoles
            }
            rolesArray.forEach(element => {
              if(element === adminRoles){
               const temp ={
                displayName : this.functionsService?.makeFirstLetterCapital(users?.displayName),
                roles : users?.roles.split(',').join(', '), //Formatting roles data i.e, adding a space in the string after comma. Ex: ABC,DEF => ABC, DEF
                email : users?.userPrincipalName
               }
                usersArray.push(temp);     //Storing all the users by roles
              }
            });
          });
          const temp ={
            role : adminRoles,
            usersData : usersArray
          }
          if(adminRoles === 'Global Administrator')
            this.globalAdminUsers = temp;
          usersArrayByAdminRoles.push(temp);
        });

        this.sortedUsersArrayByAdminRoles = usersArrayByAdminRoles.sort((a,b)=>b.usersData.length - a.usersData.length); //Sorting the array in decreasing order by count of users data
        this.showAdminUsersApiLoader = false;
      }
    },(err)=>{
      this.showAdminUsersApiLoader = false;
    });
   }

   disableAdminstratorsTab(i){ //This function will disable Total Adminstrators table and enable table for other types of Administrators.
    this.showTableApiLoader = true;
    if(this.removeSortClass.nativeElement.classList.contains('asc')){   //Removing earlier applied asc or desc sort class while navigating to another tab.
      this.removeSortClass.nativeElement.classList.remove('asc');
    }
    else if(this.removeSortClass.nativeElement.classList.contains('desc')){
      this.removeSortClass.nativeElement.classList.remove('desc');
    }
    this.isAdministratorTabActive = false;
    this.isCommonTableActive = true;
    this.currentlyActiveTabId = i; //Storing current active tab ID.
    setTimeout(()=>{          //For showing a quick loader before displaying the table
      this.showTableApiLoader = false;
    },700)
   }

   enableAdminstratorsTab(){ //This function will enable Total Adminstrators table and disable table for other types of Administrators.
    this.isAdministratorTabActive = true;
    this.isCommonTableActive = false;
   }

   resetCurrentPageNumber(){    //Resetting current page number to 1.
    this.pageNumber = 1;
  }

  onPageBoundsCorrection(newPageNumber: number){
    this.pageNumber = newPageNumber;
  }

  onPageChange(event){
    this.pageNumber =event;
    if(!(this.pageNoArray[this.currentlyActiveTabId].includes(this.pageNumber)))    //Checking if selectedPageNumber is already exist in our array. If not then we will store it in our array.
    {
    this.pageNoArray[this.currentlyActiveTabId].push(this.pageNumber);
    }
  }

  onSort({ column, direction }: SortEvent,data) {
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
      if (direction === '') {
        this.selectedColumn = data;
      } else {
        this.selectedColumn = data.sort((a, b) => {
          const res = compare(a[column], b[column]);
          return direction === 'asc' ? res : -res;
        });
      }
    });
  }

}
